export class LocalStorageHelper<T> {
  constructor(private readonly key: string) {}

  getValue(defaultValue: T): T;
  getValue(defaultValue: T | null = null): T | null {
    try {
      const stringValue = window.localStorage.getItem(this.key);
      return stringValue ? JSON.parse(stringValue) as T : defaultValue;
    } catch (error) {
      return defaultValue;
    }
  }

  setValue(value: T): void {
    try {
      const stringValue: string = JSON.stringify(value);
      window.localStorage.setItem(this.key, stringValue);
    } catch (error) { /* empty */ }
  }
}
