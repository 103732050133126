import React from 'react';
import { useT } from 'hooks';
import { SCOPE_REQUEST_LABEL } from 'i18n/_single/RequestLabel';
import { CustomTooltip } from 'components/#CustomComponents';
import './style.sass';

export enum TypesLabel {
  request = 'request',
  offer = 'offer',
  trade = 'trade',
}

interface Params {
  type: keyof typeof TypesLabel;
  showPublic?: boolean;
  isPublic?: boolean;
  isMy?: boolean | null;
}


const RequestLabel: React.FC<Params> = ({ type, showPublic, isPublic, isMy }) => {
  const _t = useT(SCOPE_REQUEST_LABEL);

  return (
    <div className="RequestLabel-root">
      {showPublic &&
        <CustomTooltip arrow enterDelay={600} placement="top" title={_t(`${isPublic}`, { subScope: 'public.tooltips' })}>
          <div className={`type type-public type-public-${isPublic}`}>
            {_t(`${isPublic}`, { subScope: 'public' })}
          </div>
        </CustomTooltip>
      }
      {isMy &&
        <div className="type type-isMy">
          {_t.common('my')}
        </div>
      }
      <div className={`type type-${type}`}>
        {_t(type)}
      </div>
    </div>
  );
};

export default RequestLabel;
