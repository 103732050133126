import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store.types';
import { API, API_ROUTES } from 'modules/Api';
import { FactoryBuilder, factoryInitState, jsonToFormData } from './helpers';
import { IAdditionalApiStatuses } from './interfaces';
import { IFileField } from 'components/_forms/FilesWraperField';
import { SHOW_CONTACT_VALUES } from 'constants/general';
import { IImage } from 'hooks';
import { ICompanyHandshake } from 'api/companies/details.api';

export { useTsSelector, useTsDispatch } from 'store/store.types';

interface ICompanyReview {
  id: number;
  company_id: number;
  score: number;
  count: number;
  data: {
    count: number;
    rating: number;
    review_category_id: number;
  }[];
  updated_at: string;
}

export interface ICompanyInfo {
  id: number;
  name: string;
  local_name?: string | null;
  country_id: number;
  address: string;
  // owner: string;
  logo?: string | null;
  site?: string | null;
  description?: string | null;
  company_types: number[];
  ports: number[];
  images: IImage[];
  files: IFileField[];
  company_review: ICompanyReview | null;
  categories?: number[];
  handshake: ICompanyHandshake;
  show_cabinet: boolean;
  show_contacts: typeof SHOW_CONTACT_VALUES[number];
  can_show_cabinet: boolean;
  created_date: string;
  amount_of_workers: string;
  annual_turnover: string;
  is_organic: boolean;
}

const emptyCompanyReview: ICompanyReview = {
  id: 0,
  company_id: 0,
  score: 0,
  count: 0,
  data: [],
  updated_at: '',
};


const initialData: ICompanyInfo = {
  id: 0,
  name: '',
  local_name: '',
  country_id: 0,
  address: '',
  logo: '',
  site: '',
  description: '',
  // owner: string,
  company_types: [],
  ports: [],
  images: [],
  files: [],
  company_review: emptyCompanyReview,
  handshake: {
    handshake: null,
    target_handshake: null,
  },
  show_contacts: 'for_two_hands',
  show_cabinet: true,
  can_show_cabinet: false,
  created_date: '',
  amount_of_workers: '',
  annual_turnover: '',
  is_organic: false,
};

const initialState: ICompanyInfo & IAdditionalApiStatuses = factoryInitState(initialData);


export const NAME = 'myCompany';
const parent = 'global';
const scope = `${parent}/${NAME}`;
const apiUrlBase: string = API_ROUTES.myCompany.root;

export const getMyCompany = createAsyncThunk<ICompanyInfo | null, { force?: boolean }>(
  `${scope}/getMyCompany`,
  async (payload = {}, thunkApi) => {
    const { loaded } = (thunkApi.getState() as RootState).myCompany;
    if (!payload.force && loaded) {
      return null;
    }
    return API.get<ICompanyInfo>(apiUrlBase).then(res => res.data);
  },
);


export const updateMyCompany = createAsyncThunk<Partial<ICompanyInfo>, Partial<ICompanyInfo>>(
  `${scope}/updateMyCompany`,
  async (payload, { rejectWithValue }) => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const ignoreList = ['internalUrl'];
      const { data } = await API.patch<Partial<ICompanyInfo>>(apiUrlBase, jsonToFormData({ payload, ignoreList }), { headers });
      return data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);


export const {
  reducer,
  // actions: {
  // setCompanyContact,
  // resetCompanyContact,
  // },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
    // setCompanyContact: (state, payload) => factorySetAction<ICompanyContact>(state, payload),
    // resetCompanyContact: () => ({ ...initialState }),
  },
  extraReducers: builder => new FactoryBuilder(builder)
    .buildAction(getMyCompany)
    .buildAction(updateMyCompany)
  ,
});

export const myCompanyReviewSelector = (state: RootState): ICompanyReview => state[NAME].company_review || emptyCompanyReview;

export default reducer;
