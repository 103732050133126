import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthSelector, useTsSelector } from 'reducers/profile/user.slice';
import Preloader from 'components/_router/Preloader';
import { ROUTES } from 'constants/routes';

export default function PrivateRoute({ component: Component, ...rest }) {
  const isAuth = useTsSelector(isAuthSelector);


  const needRedirect = !isAuth;

  if (needRedirect) return <Redirect to={ROUTES.market} />;

  return (
    <Route {...rest}
      render={props =>(
        <Suspense fallback={<Preloader />}>
          <Component {...props} />
        </Suspense>
      )}
    />
  );
}
