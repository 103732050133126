export const SCOPE_MY_COMPANY_REVIEWS = 'MyCompanyReviews';

export const uk = {
  title: 'Відгуки',
  tabs: {
    my: 'Мої відгуки',
    company: 'Отримані відгуки',
  },
  Fields: {
    fotos: {
      title: 'Фото',
      info: 'Завантажте фото виробничих потужностей, складів, ліній, силосів',
    },
    files: {
      title: 'Документи',
      info: 'Розділ для завантаження сертифікатів (ISO, HACCP, HALAL, Global GAP, BRC тощо)',
    },
  },
};

export const en: typeof uk = {
  title: 'Reviews',
  tabs: {
    my: 'My Reviews',
    company: 'Received Reviews',
  },
  Fields: {
    fotos: {
      title: 'Photo',
      info: 'Download photos of production facilities, warehouses, lines, silos',
    },
    files: {
      title: 'Documents',
      info: 'Section for downloading certificates (ISO, HACCP, HALAL, Global GAP, BRC, etc.)',
    },
  },
};

export const ru: typeof uk = {
  title: 'Отзывы',
  tabs: {
    my: 'Мои отзывы',
    company: 'Полученные отзывы',
  },
  Fields: {
    fotos: {
      title: 'Фото',
      info: 'Загрузите фото производственных мощностей, складов, линий, силосов',
    },
    files: {
      title: 'Документы',
      info: 'Раздел для загрузки сертификатов (ISO, HACCP, HALAL, Global GAP, BRC и т.д.)',
    },
  },
};

export const bg: typeof uk = {
  title: 'Отзиви',
  tabs: {
    my: 'Моите отзиви',
    company: 'Получена обратна връзка',
  },
  Fields: {
    fotos: {
      title: 'Снимка',
      info: 'Изтегляне на снимки на производствени съоръжения, складове, линии, силози',
    },
    files: {
      title: 'Документи',
      info: 'Секция за изтегляне на сертификати (ISO, HACCP, HALAL, Global GAP, BRC и др.)',
    },
  },
};
