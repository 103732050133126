import { AnyTODO } from 'core/interfaces/AnyTODO.type';
import { ICountry, ICulture } from 'reducers/allReducer';

export const GET_CULTURES_LIST_START = 'GET_CULTURES_LIST_START';
export const GET_CULTURES_LIST_SUCCESS = 'GET_CULTURES_LIST_SUCCESS';
export const UPDATE_DETAILS_CULTURE = 'UPDATE_DETAILS_CULTURES';
export const UPDATE_FULL_COUNTRY = 'UPDATE_FULL_COUNTRY';

export interface IResponseOverallsList<T> {
  count: number;
  next: string | null;
  results: T[];
  overalls: AnyTODO;
}

export const getListCulturesStarted = (append: boolean) => ({
  type: GET_CULTURES_LIST_START,
  append,
});

export const getListCulturesSuccess = (page: number, { count, next, results, overalls }: IResponseOverallsList<ICulture>, append = false) => {
  return {
    type: GET_CULTURES_LIST_SUCCESS,
    payload: {
      page,
      count,
      next,
      overalls,
      list: results,
    },
    append,
  };
};

export const updateCultureDetailsAction = (culture: ICulture) => ({
  type: UPDATE_DETAILS_CULTURE,
  payload: culture,
});
export const updateFullCountryAction = (country: ICountry) => ({
  type: UPDATE_FULL_COUNTRY,
  payload: country,
});
