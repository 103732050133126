export const SCOPE_SIDE_BAR = 'SideBar';

export const uk = {
  menu: {
    dashboard: 'Дашборд',
    cabinet: 'Кабінет',
    market: 'Ринок',
    requests: 'Заявки',
    companies: 'Компанії',
    // myProposals: 'Мої заявки',
    // trades: 'Мої торги',
    help: 'Допомога',
  },
  PersonalManager: {
    position: 'Особистий менеджер',
  },
};

export const en: typeof uk = {
  menu: {
    dashboard: 'Dashboard',
    cabinet: 'Cabinet',
    market: 'Market',
    requests: 'Requests',
    companies: 'Companies',
    // myProposals: 'My requests',
    // trades: 'My trades',
    help: 'Help',
  },
  PersonalManager: {
    position: 'Personal manager',
  },
};

export const ru: typeof uk = {
  menu: {
    dashboard: 'Дашборд',
    cabinet: 'Кабинет',
    market: 'Рынок',
    requests: 'Заявки',
    companies: 'Компании',
    // myProposals: 'Мои заявки',
    // trades: 'Мои торги',
    help: 'Помощь',
  },
  PersonalManager: {
    position: 'Личный менеджер',
  },
};

export const bg: typeof uk = {
  menu: {
    dashboard: 'Табло',
    cabinet: 'Кабинет',
    market: 'Пазар',
    requests: 'Заявки',
    companies: 'Компании',
    // myProposals: 'Моите заявки',
    // trades: 'Моите торги',
    help: 'Помощ',
  },
  PersonalManager: {
    position: 'Личен мениджър',
  },
};
