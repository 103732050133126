export const formatWithThousands = (number?: number | null, defaultValue: string | number = ''): string => {
  const value = number ?? defaultValue;
  if (typeof value === 'number') {
    return format(value);
  }
  const parsedValue = parseFloat(value);
  return parsedValue ? format(parsedValue) : value;
};

function format(value: number | string): string {
  const valueWithThousands = value.toLocaleString('en-US');
  const endOfDot = valueWithThousands.indexOf('.') < 0 ? valueWithThousands.length : valueWithThousands.indexOf('.');

  return valueWithThousands.substring(0, endOfDot + 3);
}
