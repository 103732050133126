import React from 'react';
import { Box } from '@material-ui/core';
import { Route, Switch } from 'react-router';
import { Helmet } from 'react-helmet';
import NotFound from '../../modules/NotFound';

const NotFoundLayout = () => {
  return (
    <Box>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <Switch>
        <Route exact component={NotFound} />
      </Switch>
    </Box>
  );
};

export default NotFoundLayout;
