import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import store from './store/store';

// import { TagManagerService } from 'services/dataLayer.service';
import App from './modules/App/App';
import './i18n';

import * as serviceWorker from './serviceWorker';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

if (TRACKING_ID) ReactGA.initialize(TRACKING_ID);
// TagManagerService.init();

const rootElement = document.getElementById('root');

render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement,
);

serviceWorker.unregister();
