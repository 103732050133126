import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgDashboardIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 3V16H16" stroke="var(--gray800)" strokeWidth="2"/>
        <path d="M4 11.625L8.27586 7.375L10.931 10L15 6" stroke="var(--gray800)" strokeWidth="2"/>
      </svg>
    </SvgIconContainer>
  );
};
