import { API } from 'modules/Api';
import Logger from 'modules/Logger';
import { resetSubscription, setSubscription } from 'reducers/profile/subscription.slice';
import { setUser } from 'reducers/profile/user.slice';
import { resetLoadedFlag } from 'reducers/slices/addresses.slice';
import { infoPopupActions } from './infoPopupAction';

export const SUBMIT_COMPANY = 'GET_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const VERIFIED_PENDING = 'VERIFIED_PENDING';
export const GET_COMPANY_MEMBERS = 'GET_COMPANY_MEMBERS';
export const GET_COMPANY_INVITES = 'GET_COMPANY_INVITES';
export const UPDATE_COMPANY_INVITE = 'UPDATE_COMPANY_INVITE';
export const DELETE_COMPANY_MEMBER = 'DELETE_COMPANY_MEMBER';
export const ADD_COMPANY_INVITE = 'ADD_COMPANY_INVITE';
export const RESET_COMPANY = 'RESET_COMPANY';

export const submitCompany = data => (dispatch, getState) => {
  const company_id = getState().profile.company.id;
  const config = company_id ? {
    method: 'patch',
    url: `/companies/${company_id}/`,
    message: 'saved',
  } : {
    method: 'post',
    url: '/companies/',
    message: 'created',
  };

  const { method, url, message } = config;
  return API({ method, url, data }).then(res => {
    dispatch(infoPopupActions.success(message));
    if(res?.data?.id) {
      dispatch({ type: GET_COMPANY, payload: res.data });
      dispatch(setUser({ company_id: res.data.id, company: res.data?.name }));
    }
  }).catch(error => {
    dispatch(infoPopupActions.apiErrors(error));
    throw error;
  });
};

export const getCompany = id => dispatch => {
  if (!id) return;
  return API.get(`/companies/${id}/`)
    .then((res) => {
      dispatch({ type: GET_COMPANY, payload: res.data });
      dispatch(setUser({
        company: res.data.name,
        company_id: res.data.id,
        is_company_verified: res.data.is_verified,
      }));
      dispatch(res.data.subscription
        ? setSubscription(res.data.subscription)
        : resetSubscription(),
      );
      dispatch(resetLoadedFlag());
    })
    .catch(err => {
      Logger.info('GET_COMPANY', err?.response?.status);
      err?.status === 403 &&
        dispatch({
          type: VERIFIED_PENDING,
          verifying: true,
        });
    });
};

export const getCompanyMembers = () => (dispatch) => {
  API.get('/companies/members/')
    .then((res) =>
      dispatch({
        type: GET_COMPANY_MEMBERS,
        count: res.data.count,
        payload: res.data.results,
      }),
    )
    .catch((err) => {
      Logger.info('GET_COMPANY_MEMBERS', err.response);
    });
};

export const deleteCompanyMember = (company_id, member_id) => (dispatch) => {
  API.delete(`/companies/members/${member_id}/`)
    .then(() =>
      dispatch({
        type: DELETE_COMPANY_MEMBER,
        id: member_id,
      }),
    )
    .catch((err) => {
      Logger.info('DELETE_COMPANY_MEMBER', err.response);
    });
};

export const getCompanyInvites = () => (dispatch) => {
  API.get('/companies/invites/')
    .then((res) =>
      dispatch({
        type: GET_COMPANY_INVITES,
        count: res.data.count,
        payload: res.data.results,
      }),
    )
    .catch((err) => {
      Logger.info('GET_COMPANY_INVITES', err.response);
    });
};

export const updateCompanyInvite = (id, results) => ({
  type: UPDATE_COMPANY_INVITE,
  payload: {
    id,
    ...results,
  },
});

export const addCompanyInvite = invite => ({
  type: ADD_COMPANY_INVITE,
  payload: invite,
});

export const resetCompany = () => ({
  type: RESET_COMPANY,
});
