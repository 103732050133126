import { FILTER_FIELDS } from 'constants/config';
import { AnyTODO } from 'core/interfaces';
import queryString, { stringify } from 'query-string';
export { queryString };

export function lotParamsToQuery(params: Record<string, unknown>) {
  const filteredParams = FILTER_FIELDS.reduce((acc, filter) => {
    acc[filter] = params[filter];
    return acc;
  }, {} as typeof params);
  const query = toApiQuery(filteredParams);
  return query;
}

export const toUrlQuery = (queryParams: Record<string, AnyTODO>, options = {}) => {
  const copyQueryParams = { ...queryParams };
  Object.keys(queryParams || {}).filter(key => queryParams[key]?.length === 0).forEach(key => delete copyQueryParams[key]);
  return stringify(
    copyQueryParams,
    {
      skipNull: true,
      skipEmptyString: true,
      arrayFormat: 'bracket-separator',
      ...options,
    },
  );
};

export const toApiQuery = (query?: Record<string, unknown>): string =>
  query ? stringify(query, { arrayFormat: 'comma' }) : '';

export const buildApiUrl = (baseUrl: string, query?: Record<string, unknown>): string =>
  `${baseUrl}?${toApiQuery(query)}`;

export const queryFromUrl = (urlQuery: string) =>
  queryString.parse(urlQuery, { arrayFormat: 'bracket-separator' });

export default {
  lotParamsToQuery,
  toUrlQuery,
  toApiQuery,
  queryFromUrl,
};
