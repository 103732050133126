import { useEffect, useRef, useState } from 'react';
import Logger from 'modules/Logger';

const PROTOCOL = process.env.NODE_ENV === 'development' ? 'ws' : 'wss';
const BASE_URL = `${PROTOCOL}://${process.env.REACT_APP_SOCKET_API}/ws/`;


export const useWebSocket = (path?: string, dispatchSocketEvent?: (event: MessageEvent) => void) => {
  const [socket, setSocket] = useState<WebSocket>();
  const refDispatcher = useRef(dispatchSocketEvent);
  refDispatcher.current = dispatchSocketEvent;


  useEffect(() => {
    let reconnectTimeout: NodeJS.Timeout;
    let unbined = false;
    let reconnectAttempts = 0;
    let ws: WebSocket;
    const connect = () => {
      if (!path) {
        return;
      }
      const webSocketUrl = `${BASE_URL}${path}`;
      ws = new WebSocket(webSocketUrl);

      ws.onopen = () => {
        setSocket(ws);
        reconnectAttempts = 0;
        Logger.debug('Connected to WebSocket');
      };

      ws.onmessage = (message) => {
        Logger.debug('Received message:', message.data);
        refDispatcher.current?.(message);
      };

      ws.onerror = (error) => {
        Logger.debug('WebSocket error:', error);
      };

      ws.onclose = () => {
        Logger.debug('WebSocket closed. Attempting to reconnect...');
        setSocket(undefined);
        attemptReconnect();
      };
    };

    const attemptReconnect = () => {
      if (unbined) {
        return;
      }
      reconnectTimeout = setTimeout(() => {
        Logger.debug('Try to reconnect', reconnectAttempts);
        reconnectAttempts++;
        connect();
      }, reconnectAttempts < 10 ? 1000 * reconnectAttempts : 10 * 1000);
    };

    connect();

    return () => {
      clearTimeout(reconnectTimeout);
      unbined = true;
      ws?.close();
    };
  }, [path]);

  return socket;
};
