import { ThemeOptions } from '@material-ui/core';

export const themeConfig: ThemeOptions = {
  // typography: {
  //   fontSize: 10,
  //   // htmlFontSize: 12,
  // },
  palette: {
    primary: {
      main: '#0aaf60',
    },
    secondary: {
      main: '#ff7325',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 977,
      lg: 1173,
      xl: 1589,
    },
    step: 0,
  },
  overrides: {
    MuiButton: {
      root: { borderRadius: 12 },
    },
  },
};
