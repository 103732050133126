import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgBidIcon: React.FC<{ className?: string; }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 11C15.6569 11 17 9.88071 17 8.5C17 7.11929 15.6569 6 14 6"
          stroke="var(--gray800)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 8.26833C1 6.91137 2.19513 5.84519 3.55002 5.77007C4.77408 5.7022 6.24408 5.47958 7.5 4.88889C8.87569 4.24187 10.8742 2.67151 12.2914 1.48381C12.9572 0.925829 14 1.39538 14 2.26407V14.0837C14 14.8918 13.0878 15.3731 12.4138 14.9271C10.9912 13.9857 8.91577 12.6659 7.5 12C6.04572 11.316 4.3044 10.8752 2.99025 10.6121C1.86352 10.3865 1 9.41743 1 8.26833V8.26833Z"
          stroke="var(--gray800)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.52686 10.9977L4.89583 16.2521C5.01059 16.6926 5.40835 17 5.86353 17L6.99651 17C7.63862 17 8.11433 16.4035 7.97144 15.7775L7.17119 12.2716"
          stroke="var(--gray800)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIconContainer>
  );
};
