import { API } from 'modules/Api';
import { AppDispatch } from 'store/store.types';


export const SET = 'SET_COMPANY_INVITE_TOKEN';
export const DELETE = 'DELETE_COMPANY_INVITE_TOKEN';
export const FAILED = 'FAILED_COMPANY_INVITE_TOKEN';
export const LOADING = 'LOADING_COMPANY_INVITE_TOKEN';


export const setToken = (token: string) => (dispatch: AppDispatch) => {
  dispatch({ type: LOADING, payload: true });

  API.get(`/companies/invites/${token}/validate/`)
    .then(({ data: { company, email } }) =>
      dispatch({ type: SET, payload: { token, company, email } }),
    )
    .catch(({ response: { data } }) =>
      dispatch({ type: FAILED, payload: data }),
    )
    .finally(() => {
      dispatch({ type: LOADING, payload: false });
    });
};

export const deleteToken = () => ({
  type: DELETE,
});
