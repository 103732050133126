export const SCOPE_GOODS = 'Goods';

export const uk = {
  MyGoods: {
    title: 'Мої товари',
  },
  MyGoodEdit: {
    title: 'Редагування Товару',
  },
  MyGoodCreate: {
    title: 'Создание Товара',
  },
  fields: {
    name: {
      title: 'Найменування',
    },
    culture_id: {
      title: 'Продукт',
      subTitle: 'Параметри за замовчуванням. У разі потреби уточніть специфікацію продукту.',
      label: '',
      placeholder: 'Виберіть продукт',
    },
    image: {
      title: 'Фото',
      subTitle: 'Завантажте фото продукту, якщо фото не додано, автоматично підвантажується фото з довідника.',
    },
    seasons: {
      title: 'Сезон',
      label: 'Сезонність',
      yearLabel: 'Рік',
    },
    overall: {
      title: 'Загальні відомості',
    },
    annual: {
      title: 'Річний обсяг, т',
    },
    annual_volume_tons_from: {
      label: 'Річне виробництво від (тонн)',
      placeholder: 'Введіть обсяг',
    },
    annual_volume_tons_to: {
      label: 'Річне виробництво до (тонн)',
      placeholder: 'Введіть обсяг',
    },
    is_organic: {
      label: 'Органічний продукт',
    },
    packagings: {
      title: 'Упаковка',
      label: 'Виберіть тип упаковки',
      placeholder: 'Види упаковок',
    },
    shippings: {
      title: 'Доставка',
      label: 'Виберіть вид доставки',
      placeholder: 'Види доставок',
    },
    origin_id: {
      title: 'Походження',
      label: 'Виберіть походження',
      placeholder: 'Походження',
    },
    actions: {
      title: 'Дії',
    },
  },
};

export const en: typeof uk = {
  MyGoods: {
    title: 'My Goods',
  },
  MyGoodEdit: {
    title: 'Editing a Product',
  },
  MyGoodCreate: {
    title: 'Create a Product',
  },
  fields: {
    name: {
      title: 'Name',
    },
    culture_id: {
      title: 'Product',
      subTitle: 'Default settings. If necessary, specify the product specification.',
      label: '',
      placeholder: 'Select product',
    },
    image: {
      title: 'Photo',
      subTitle: 'Upload a product photo, if a photo is not added, a photo from the directory is automatically loaded.',
    },
    seasons: {
      title: 'Season',
      label: 'Seasonality',
      yearLabel: 'Year',
    },
    overall: {
      title: 'General information',
    },
    annual: {
      title: 'Annual volume, t',
    },
    annual_volume_tons_from: {
      label: 'Annual production from (tons)',
      placeholder: 'Enter volume',
    },
    annual_volume_tons_to: {
      label: 'Annual production to (tons)',
      placeholder: 'Enter volume',
    },
    is_organic: {
      label: 'Organic product',
    },
    packagings: {
      title: 'Packaging',
      label: 'Select packaging type',
      placeholder: 'Packaging types',
    },
    shippings: {
      title: 'Shipping',
      label: 'Select shipping type',
      placeholder: 'Shipping types',
    },
    origin_id: {
      title: 'Origin',
      label: 'Select origin',
      placeholder: 'Origin',
    },
    actions: {
      title: 'Actions',
    },
  },
};

export const ru: typeof uk = {
  MyGoods: {
    title: 'Мои товары',
  },
  MyGoodEdit: {
    title: 'Редактирование Товара',
  },
  MyGoodCreate: {
    title: 'Создание Товара',
  },

  fields: {
    name: {
      title: 'Наименование',
    },
    culture_id: {
      title: 'Продукт',
      subTitle: 'Параметры по умолчанию. При необходимости уточните спецификацию продукта.',
      label: '',
      placeholder: 'Выберите продукт',
    },
    image: {
      title: 'Фото',
      subTitle: 'Загрузите фото продукта, если фото не добавлено, автоматически подгружается фото из справочника.',
    },
    seasons: {
      title: 'Сезон',
      label: 'Сезонность',
      yearLabel: 'Год',
    },
    overall: {
      title: 'Общие сведения',
    },
    annual: {
      title: 'Годовой объём, т',
    },
    annual_volume_tons_from: {
      label: 'Годовое производство от (тонн)',
      placeholder: 'Введите объём',
    },
    annual_volume_tons_to: {
      label: 'Годовое производство до (тонн)',
      placeholder: 'Введите объём',
    },
    is_organic: {
      label: 'Органический продукт',
    },
    packagings: {
      title: 'Упаковка',
      label: 'Выберите тип упаковки',
      placeholder: 'Виды упаковок',
    },
    shippings: {
      title: 'Доставка',
      label: 'Выберите вид доставки',
      placeholder: 'Виды доставок',
    },
    origin_id: {
      title: 'Происхождение ',
      label: 'Выберите происхождение',
      placeholder: 'Происхождение',
    },
    actions: {
      title: 'Действия',
    },
  },
};

export const bg: typeof uk = {
  MyGoods: {
    title: 'Моите стоки',
  },
  MyGoodEdit: {
    title: 'Редактиране на стока',
  },
  MyGoodCreate: {
    title: 'Създайте стока',
  },
  fields: {
    name: {
      title: 'Име',
    },
    culture_id: {
      title: 'Продукт',
      subTitle: 'Параметри по подразбиране. Ако е необходимо, уточнете спецификациите на продукта.',
      label: '',
      placeholder: 'Изберете продукт',
    },
    image: {
      title: 'Снимка',
      subTitle: 'Качете снимка на продукта. Ако не е качена, автоматично ще се зареди снимка от каталога.',
    },
    seasons: {
      title: 'Сезон',
      label: 'Сезонност',
      yearLabel: 'Година',
    },
    overall: {
      title: 'Обща информация',
    },
    annual: {
      title: 'Годишен обем, т',
    },
    annual_volume_tons_from: {
      label: 'Годишно производство от (тонове)',
      placeholder: 'Въведете обем',
    },
    annual_volume_tons_to: {
      label: 'Годишно производство до (тонове)',
      placeholder: 'Въведете обем',
    },
    is_organic: {
      label: 'Органичен продукт',
    },
    packagings: {
      title: 'Опаковка',
      label: 'Изберете вид опаковка',
      placeholder: 'Видове опаковки',
    },
    shippings: {
      title: 'Доставка',
      label: 'Изберете вид доставка',
      placeholder: 'Видове доставки',
    },
    origin_id: {
      title: 'Произход',
      label: 'Изберете произход',
      placeholder: 'Произход',
    },
    actions: {
      title: 'Действия',
    },
  },
};
