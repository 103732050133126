// import { createStore, applyMiddleware, compose } from "redux";
import { configureStore } from '@reduxjs/toolkit';
// import thunk from "redux-thunk";
import rootReducer from '../reducers';
import { localStorageMiddleware } from './middlewares/systemSettings.middleware';

// const initialState = {};
// const enhancers = [];

// if (process.env.NODE_ENV === "development") {
//   // Devtools error
//   const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
//   if (typeof devToolsExtension === "function") {
//     enhancers.push(devToolsExtension());
//   }
// }

// const composedEnhancers = compose(applyMiddleware(thunk), ...enhancers);

// const store = createStore(rootReducer, initialState, composedEnhancers);
const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(localStorageMiddleware),
});

export default store;
