export const SCOPE_DASHBOARD = 'Dashboard';
const SCOPE_TITLES = `${SCOPE_DASHBOARD}$titles$`;

export const uk = {
  title: 'Дашборд',
  titles: {
    _request: 'Купівля',
    _offer: 'Продаж',
    _count: 'у заявках',
    _amount: 'у доларах',
    _volume: 'у тоннах',
    my: 'Запити',
    basis: `$t(${SCOPE_TITLES}_{{lotType}}) за базисами $t(${SCOPE_TITLES}_{{grouping}})`,
    categories: `$t(${SCOPE_TITLES}_{{lotType}}) за категоріями $t(${SCOPE_TITLES}_{{grouping}})`,
    companies: `$t(${SCOPE_TITLES}_{{lotType}}) по компаніям $t(${SCOPE_TITLES}_{{grouping}})`,
    countries: `$t(${SCOPE_TITLES}_{{lotType}}) по країнах $t(${SCOPE_TITLES}_{{grouping}})`,
    cultures: `$t(${SCOPE_TITLES}_{{lotType}}) по культурам $t(${SCOPE_TITLES}_{{grouping}})`,
    origins: `$t(${SCOPE_TITLES}_{{lotType}}) по походженню $t(${SCOPE_TITLES}_{{grouping}})`,
    packaging_options: `$t(${SCOPE_TITLES}_{{lotType}}) по упаковці $t(${SCOPE_TITLES}_{{grouping}})`,
    port_options: `$t(${SCOPE_TITLES}_{{lotType}}) по призначенню $t(${SCOPE_TITLES}_{{grouping}})`,
  },
  filters: {
    type: {
      request: 'Запити',
      offer: 'Пропозиції',
    },
    grouping: {
      volume: 'Тонн',
      amount: '$',
      count: 'Кіл-ть',
    },
    owner: {
      my: 'Мої',
      other: 'Інші',
    },
  },
  my: {
    titles: {
      request: 'Запити',
      offer: 'Лоти',
    },
    tooltips: {
      my: 'Заявки моєї Компанії',
      other: 'Заявки не моєї Компанії',
      all: 'Всі заявки',
    },
    all: 'Всього',
    active: 'Активні',
    closed: 'Закриті',
    completed: 'Контракти',
  },
};

export const en: typeof uk = {
  title: 'Dashboard',
  titles: {
    _request: 'Purchase',
    _offer: 'Sales',
    _count: 'in lots',
    _amount: 'in $',
    _volume: 'in tons',
    my: 'Requests',
    basis: `$t(${SCOPE_TITLES}_{{lotType}}) by basis $t(${SCOPE_TITLES}_{{grouping}})`,
    categories: `$t(${SCOPE_TITLES}_{{lotType}}) by categories $t(${SCOPE_TITLES}_{{grouping}})`,
    companies: `$t(${SCOPE_TITLES}_{{lotType}}) by companies $t(${SCOPE_TITLES}_{{grouping}})`,
    countries: `$t(${SCOPE_TITLES}_{{lotType}}) by countries $t(${SCOPE_TITLES}_{{grouping}})`,
    cultures: `$t(${SCOPE_TITLES}_{{lotType}}) by cultures $t(${SCOPE_TITLES}_{{grouping}})`,
    origins: `$t(${SCOPE_TITLES}_{{lotType}}) by origins $t(${SCOPE_TITLES}_{{grouping}})`,
    packaging_options: `$t(${SCOPE_TITLES}_{{lotType}}) by packagings $t(${SCOPE_TITLES}_{{grouping}})`,
    port_options: `$t(${SCOPE_TITLES}_{{lotType}}) by destinations $t(${SCOPE_TITLES}_{{grouping}})`,
  },
  filters: {
    type: {
      request: 'Requests',
      offer: 'Offers',
    },
    grouping: {
      volume: 'Tons',
      amount: '$',
      count: 'No.',
    },
    owner: {
      my: 'My',
      other: 'Other',
    },
  },
  my: {
    titles: {
      request: 'Requests',
      offer: 'Offers',
    },
    tooltips: {
      my: 'My Company\'s lots',
      other: 'Lots not from my Company',
      all: 'All lots',
    },
    all: 'Total',
    active: 'Active',
    closed: 'Closed',
    completed: 'Completed',
  },
};

export const ru: typeof uk = {
  title: 'Дашборд',
  titles: {
    _request: 'Покупка',
    _offer: 'Продажа',
    _count: 'в заявках',
    _amount: 'в долларах',
    _volume: 'в тоннах',
    my: 'Запросы',
    basis: `$t(${SCOPE_TITLES}_{{lotType}}) по базисам $t(${SCOPE_TITLES}_{{grouping}})`,
    categories: `$t(${SCOPE_TITLES}_{{lotType}}) по категориям $t(${SCOPE_TITLES}_{{grouping}})`,
    companies: `$t(${SCOPE_TITLES}_{{lotType}}) по компаниям $t(${SCOPE_TITLES}_{{grouping}})`,
    countries: `$t(${SCOPE_TITLES}_{{lotType}}) по странам $t(${SCOPE_TITLES}_{{grouping}})`,
    cultures: `$t(${SCOPE_TITLES}_{{lotType}}) по культурам $t(${SCOPE_TITLES}_{{grouping}})`,
    origins: `$t(${SCOPE_TITLES}_{{lotType}}) по происхождению $t(${SCOPE_TITLES}_{{grouping}})`,
    packaging_options: `$t(${SCOPE_TITLES}_{{lotType}}) по упаковке $t(${SCOPE_TITLES}_{{grouping}})`,
    port_options: `$t(${SCOPE_TITLES}_{{lotType}}) по назначениям $t(${SCOPE_TITLES}_{{grouping}})`,
  },
  filters: {
    type: {
      request: 'Запросы',
      offer: 'Предложения',
    },
    grouping: {
      volume: 'Тонн',
      amount: '$',
      count: 'Кол-во',
    },
    owner: {
      my: 'Мои',
      other: 'Другие',
    },
  },
  my: {
    titles: {
      request: 'Запросы',
      offer: 'Лоты',
    },
    tooltips: {
      my: 'Заявки моей Компании',
      other: 'Заявки не моей Компании',
      all: 'Все заявки',
    },
    all: 'Всего',
    active: 'Активные',
    closed: 'Закрытые',
    completed: 'Контракты',
  },
};


export const bg: typeof uk = {
  title: 'Табло',
  titles: {
    _request: 'Покупка',
    _offer: 'Продажба',
    _count: 'в заявките',
    _amount: 'в долари',
    _volume: 'в тонове',
    my: 'Запитвания',
    basis: `$t(${SCOPE_TITLES}_{{lotType}}) по базиси $t(${SCOPE_TITLES}_{{grouping}})`,
    categories: `$t(${SCOPE_TITLES}_{{lotType}}) по категории $t(${SCOPE_TITLES}_{{grouping}})`,
    companies: `$t(${SCOPE_TITLES}_{{lotType}}) по компании $t(${SCOPE_TITLES}_{{grouping}})`,
    countries: `$t(${SCOPE_TITLES}_{{lotType}}) по страни $t(${SCOPE_TITLES}_{{grouping}})`,
    cultures: `$t(${SCOPE_TITLES}_{{lotType}}) по култури $t(${SCOPE_TITLES}_{{grouping}})`,
    origins: `$t(${SCOPE_TITLES}_{{lotType}}) по произход $t(${SCOPE_TITLES}_{{grouping}})`,
    packaging_options: `$t(${SCOPE_TITLES}_{{lotType}}) по опаковка $t(${SCOPE_TITLES}_{{grouping}})`,
    port_options: `$t(${SCOPE_TITLES}_{{lotType}}) по назначения $t(${SCOPE_TITLES}_{{grouping}})`,
  },
  filters: {
    type: {
      request: 'Запитвания',
      offer: 'Предложения',
    },
    grouping: {
      volume: 'Тонове',
      amount: '$',
      count: 'Брой',
    },
    owner: {
      my: 'Моите',
      other: 'Други',
    },
  },
  my: {
    titles: {
      request: 'Запитвания',
      offer: 'Лотове',
    },
    tooltips: {
      my: 'Заявки на моята Компания',
      other: 'Заявки на други Компании',
      all: 'Всички заявки',
    },
    all: 'Общо',
    active: 'Активни',
    closed: 'Затворени',
    completed: 'Контракти',
  },
};
