import store from 'store/store';
import i18n from 'i18n';
import moment from 'moment';
import { generatePath } from 'react-router';
import Nav, { ROUTES } from 'nav';
import { IBasis, ICountry, ICurrency, IPackaging, IPayment, IPortOptions, IShipping } from 'reducers/allReducer';
import { DateTimeHelper } from 'core/helpers/dateTime.helper';
import { ILotBase, IOffer } from 'reducers/oneOfferReducer';
import { FormatEnum, LotType } from 'constants/general';
import { formatWithThousands } from 'utils/formatThousands';
import { Address } from 'models/Address';
import { Nullable } from 'core/interfaces/common.interfaces';
import { Category } from 'models/Category';
import { Company } from 'models/Company';
import { Culture } from 'models/Culture';
import { SCOPE_LOT } from 'i18n/_single/Lot';
import { SCOPE_REQUEST_LABEL } from 'i18n/_single/RequestLabel';
import { IRequest } from 'reducers/oneRequestReducer';

export type { ILotBase };
export { LotType } from 'constants/general';

export class LotBase {
  protected _store!: ReturnType<typeof store['getState']>;
  protected _category!: Category;
  protected _company!: Company;
  protected _culture!: Culture;
  constructor (
    public $: ILotBase | IOffer | IRequest,
  ) {}

  get id() { return this.$.id; }

  get basis(): IBasis {
    return this.store.all.basis.list[this.$.basis_id];
  }
  get category(): Category {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._category = this._category || Category.find(this.culture.category_id!);
  }
  get company(): Company {
    return this._company = this._company || Company.find(this.$.company_id);
  }

  get culture(): Culture {
    return this._culture = this._culture || Culture.find(this.$.culture_id);
  }
  get currency(): ICurrency {
    return this.store.all.currencies.list[this.$.currency_id];
  }
  get location(): Address {
    if (!('location' in this.$)) {
      return new Address({});
    }
    return new Address(this.$.location);
  }
  get port(): Nullable<IPortOptions> {
    if (!('location' in this.$)) {
      return null;
    }
    const port_id = this.$.location.port_id;
    if (!port_id) {
      return null;
    }
    return this.store.all.port_options.list[port_id];
  }
  get origin(): ICountry {
    return this.store.all.origins.list[this.$.origin_id];
  }
  get packaging(): IPackaging {
    return this.store.all.packaging_options.list[this.$.packaging_id];
  }
  get payment(): IPayment {
    return this.store.all.payments_options.list[this.$.payment_id];
  }
  get shipping(): IShipping {
    return this.store.all.shipping_options.list[this.$.shipping_id];
  }
  get comment(): string {
    return this.$.comment;
  }
  get image(): string {
    return (this.isOffer() && this.$.images[0]?.image) || this.culture.image || '';
  }

  fullName(): string {
    return `${this.culture.name} #${this.id}`;
  }

  description(): string {
    return [
      i18n.t(`${SCOPE_REQUEST_LABEL}$${this.$.type}`),
      this.fullName(),
      this.quickParams(),
      this.companyName,
    ].join(', ');
  }

  basisWithPort(): string {
    return [
      this.basis.name,
      this.port?.name,
    ].filter(Boolean).join(', ');
  }

  createdAt(format = FormatEnum.main): string {
    return DateTimeHelper.format(this.$.created_at, format);
  }
  dateOfManufacture(): Nullable<number> {
    if (this.isOffer()) {
      return this.$.date_of_manufacture;
    }
    return null;
  }

  formatedPrice({ withCode = true } = {}): string {
    return this.$.price
      ? [formatWithThousands(this.$.price, 0), withCode && this.currency.iso_code].filter(Boolean).join(' ')
      : i18n.t(`${SCOPE_LOT}$zeroPrice`);
  }
  formatedVolume(): string {
    return formatWithThousands(this.$.volume, 0);
  }

  quickParams(): string {
    return [
      this.formatedVolume(),
      i18n.t('т'),
      this.formatedPrice(),
      this.basis.name,
      this.location?.port?.name || this.location?.country?.name,
    ].join(' ');
  }
  quickParamsSecond(): string {
    return [
      this.packaging.name,
      i18n.t('в'),
      this.shipping.name,
    ].join(' ');
  }
  similarLabel(type: LotType = this.$.type): string {
    return i18n.t([SCOPE_LOT, 'labels', 'overalls'].join('$'), this._i18nDataInfo(type));
  }
  similarText(type: LotType = this.$.type): string {
    return i18n.t([SCOPE_LOT, 'text', 'overalls'].join('$'), this._i18nDataInfo(type));
  }
  _i18nDataInfo(type: LotType = this.$.type) {
    const count = 'overalls' in this.$ && this.$.overalls[type].count || 0;
    const volume = 'overalls' in this.$ && this.$.overalls[type].volume || 0;
    const culture = this.culture.name;
    return { type, culture, count, volume };
  }
  shippingDate(format?: FormatEnum): string {
    return this.isRequest()
      ? DateTimeHelper.formatPeriodDates(this.$.shipping_date_from, this.$.shipping_date_to, format)
      : '';
  }
  validLastDate(format?: FormatEnum): string {
    const date = this.isOffer()
      ? this.$.valid_until
      : this.isRequest()
        ? this.$.shipping_date_to
        : '';
    return DateTimeHelper.format(date, format);
  }
  validUntil(format?: FormatEnum): string {
    return this.isOffer()
      ? DateTimeHelper.formatPeriodDates(undefined, this.$.valid_until, format)
      : '';
  }
  dateValid(format?: FormatEnum): string {
    const dates = this.isOffer()
      ? [this.$.valid_until]
      : this.isRequest()
        ? [this.$.shipping_date_from, this.$.shipping_date_to]
        : [];
    return dates.filter(Boolean).map(date => DateTimeHelper.format(date, format)).join(' - ');
  }
  get daysLeft(): number {
    const instanceCloseDate = this.isRequest()
      ? this.$.shipping_date_to
      : this.isOffer()
        ? this.$.valid_until
        : null;
    return instanceCloseDate
      ? moment(instanceCloseDate).diff(moment(), 'days') + 1
      : 0;
  }
  interpolate() {
    return {
      id: this.id,
      company: this.company.name,
      culture: this.culture.name,
      volume: this.$.volume,
      price: this.formatedPrice(),
      addressCountry: this.location.country?.name,
      address: this.location.middleName,
      payment: this.payment.name,
      dateValid: this.dateValid(),
      createdAt: this.createdAt(),
    } as const;
  }

  get type(): LotType { return this.$.type; }

  get hasMyTrade(): boolean { return !!this.$.trade?.id; }
  get isMy(): boolean { return !!this.$.is_my; }
  isOffer(): this is { $: IOffer } { return this.$.type === LotType.offer; }
  isRequest(): this is { $: IRequest } { return this.$.type === LotType.request; }
  get companyName(): string { return this.company.name; }

  get endDate(): string {
    if ('valid_until' in this.$) {
      return this.$.valid_until;
    }
    if ('shipping_date_to' in this.$) {
      return this.$.shipping_date_to;
    }
    return '';
  }

  oppositeType(): LotType { return this.isOffer() ? LotType.request : LotType.offer; }


  goTo(): void {
    Nav.go(this.path);
  }
  goToTrade(): void {
    if (!this.hasMyTrade) return;
    Nav.go(`${ROUTES.chat}?tradeId=${this.$.trade.id}`, { vibrate: 'standard' });
  }

  pathToSimilars(absolute = false): string {
    const path = `${ROUTES.proposals}?cultures[]=${this.$.culture_id}&type=${this.$.type}`;
    const baseUrl = window.location.origin;
    return absolute ? `${baseUrl}${path}` : path;
  }
  pathToOpposits(absolute = false): string {
    const path = `${ROUTES.proposals}?cultures[]=${this.$.culture_id}&type=${this.oppositeType()}`;
    const baseUrl = window.location.origin;
    return absolute ? `${baseUrl}${path}` : path;
  }

  get path(): string {
    return generatePath(ROUTES.lot, { type: this.$.type, id: this.$.id });
  }

  private get store() {
    return this._store ? this._store : this._store = store.getState();
  }
}
