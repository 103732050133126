import React, { ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './index.scss';

const OPACITY_NONE = Symbol('None');

interface Params {
  className?: string;
  show?: boolean;
  opacity?: typeof OPACITY_NONE | boolean;
  children?: ReactNode;
  size?: number;
}

const LoaderForData: React.FC<Params> = ({ className = '', show = false, opacity = OPACITY_NONE, children, size = 80 }) => {
  if (show) return (
    <div className={`loaderForData-global ${className}`}>
      <CircularProgress className="loaderForData" size={size} />
    </div>
  );
  if (opacity === OPACITY_NONE) return <>{children}</>;
  return (
    <div className={`loaderForData-opacity ${opacity ? 'on' : ''} ${className}`}>
      <CircularProgress className="loaderForData" size={size} />
      <div className="opacity">
        {children}
      </div>
    </div>
  );
};

export default LoaderForData;
