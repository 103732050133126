import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toApiQuery } from 'api/utils';
import { AnyTODO } from 'core/interfaces';
import { API, API_ROUTES } from 'modules/Api';
import { ICompanyPosition } from './companyPositions.slice';
import { factoryInitListState, FactoryListBuilder } from './helpers';
import { IApiListResponse, IInitListState } from './interfaces';

export { useTsSelector, useTsDispatch } from 'store/store.types';

export interface ICompanyContact {
  id: number;
  avatar: string | null;
  first_name: string;
  last_name: string;
  company_id: number;
  company_position_id: number;
  company_position_entity: ICompanyPosition;
  phone_number: string;
  // sort: number;
}


const initialState = factoryInitListState<ICompanyContact>();

export const NAME = 'companyContacts';
const parent = 'global';
const scope = `${parent}/${NAME}`;
const apiUrlBase: string = API_ROUTES.myCompany.companyContacts;

export const getCompanyContacts = createAsyncThunk<IApiListResponse<ICompanyContact> | null, { params: Record<string, AnyTODO>, append?: boolean }>(
  `${scope}/getList`,
  (payload) => API.get<IApiListResponse<ICompanyContact>>(`${apiUrlBase}?${toApiQuery(payload.params)}`).then(res => res.data),
);


export const createCompanyContact = createAsyncThunk<ICompanyContact, Partial<ICompanyContact>>(
  `${scope}/create`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await API.post<ICompanyContact>(apiUrlBase, payload);
      return data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

export const updateCompanyContact = createAsyncThunk<ICompanyContact, Partial<ICompanyContact>>(
  `${scope}/update`,
  async (payload, { rejectWithValue }) => {
    try {
      if (!payload.id) {
        throw Error('id not defined');
      }
      const { data } = await API.patch<ICompanyContact>(`${apiUrlBase}${payload.id}/`, payload);
      return data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);


export const deleteCompanyContact = createAsyncThunk<void, number>(
  `${scope}/delete`,
  async (id, { rejectWithValue }) => {
    try {
      await API.delete<void>(`${apiUrlBase}${id}/`);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);


export const {
  reducer,
  // actions: {
  // },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
  },
  extraReducers: builder => new FactoryListBuilder<ICompanyContact, IInitListState<ICompanyContact>>(builder)
    .buildListAction(getCompanyContacts)
    .buildListSetAction(createCompanyContact)
    .buildListSetAction(updateCompanyContact)
    .buildListDeleteAction(deleteCompanyContact)
  ,
});

export default reducer;
