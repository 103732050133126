export const MAP_UK_EN: Record<string, string> = {
  'а': 'a',
  'е': 'e',
  'і': 'i',
  'м': 'm',
  'с': 's',
  'ц': 'ts',
  'ш': 'sh',
  'б': 'b',
  'є': 'ie',
  'ї ': 'yi',
  'н': 'n',
  'т': 't',
  'ч': 'ch',
  'щ': 'shch',
  'в': 'v',
  'ж': 'zh',
  'й': 'y',
  'о': 'o',
  'у': 'u',
  'г': 'g',
  'ґ': 'g',
  'з': 'z',
  'к': 'k',
  'п': 'p',
  'ф': 'f',
  'ь': '',
  'д': 'd',
  'и': 'y',
  'л': 'l',
  'р': 'r',
  'х': 'kh',
  'ю': 'yu',
  'я': 'ya',
};

export const MAP_BG_EN: Record<string, string> = {
  'а': 'a',
  'б': 'b',
  'в': 'v',
  'г': 'g',
  'д': 'd',
  'е': 'e',
  'ж': 'zh',
  'з': 'z',
  'и': 'i',
  'й': 'y',
  'к': 'k',
  'л': 'l',
  'м': 'm',
  'н': 'n',
  'о': 'o',
  'п': 'p',
  'р': 'r',
  'с': 's',
  'т': 't',
  'у': 'u',
  'ф': 'f',
  'х': 'h',
  'ц': 'ts',
  'ч': 'ch',
  'ш': 'sh',
  'щ': 'sht',
  'ъ': 'a',
  'ь': '',
  'ю': 'yu',
  'я': 'ya',
};

export const MAP_RU_EN: Record<string, string> = {
  'а': 'a',
  'б': 'b',
  'в': 'v',
  'г': 'g',
  'д': 'd',
  'е': 'e',
  'ё': 'yo',
  'ж': 'zh',
  'з': 'z',
  'и': 'i',
  'й': 'j',
  'к': 'k',
  'л': 'l',
  'м': 'm',
  'н': 'n',
  'о': 'o',
  'п': 'p',
  'р': 'r',
  'с': 's',
  'т': 't',
  'у': 'u',
  'ф': 'f',
  'х': 'h',
  'ц': 'cz',
  'ч': 'ch',
  'ш': 'sh',
  'щ': 'shch',
  'ъ': '',
  'ы': 'y',
  'ь': '',
  'э': 'e',
  'ю': 'iu',
  'я': 'ia',
};
