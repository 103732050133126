import { CompanyHandshakeSubStatus } from 'models/CompanyHandshake';
import { NOTIFICATION_TYPES } from 'modules/Notifications/NotificationsItem';

export const SCOPE_NOTIFICATIONS = 'Notifications';

export const uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Відміна рукостискання',
      [CompanyHandshakeSubStatus.my]: 'Запит на рукостискання',
      [CompanyHandshakeSubStatus.full]: 'Підтвердження рукостискання',
    },
    description: 'від {{company}}',
  },
};

export const en: typeof uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Revoking Handshake',
      [CompanyHandshakeSubStatus.my]: 'Requesting Handshake',
      [CompanyHandshakeSubStatus.full]: 'Confirming Handshake',
    },
    description: 'from {{company}}',
  },
};

export const ru: typeof uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Отмена рукопожатия',
      [CompanyHandshakeSubStatus.my]: 'Запрос на рукопожатие',
      [CompanyHandshakeSubStatus.full]: 'Подтверждение рукопожатия',
    },
    description: 'от {{company}}',
  },
};

export const bg: typeof uk = {
  [NOTIFICATION_TYPES.company_handshake]: {
    titles: {
      [CompanyHandshakeSubStatus.none]: 'Извикване на ръкостискане',
      [CompanyHandshakeSubStatus.my]: 'Искане за ръкостискане',
      [CompanyHandshakeSubStatus.full]: 'Потвърждение на ръкостискане',
    },
    description: 'от {{company}}',
  },
};
