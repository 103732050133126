import React, { useEffect, useState } from 'react';
import { Button, IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Close from '@material-ui/icons/Close';
import { InfoPopupEnum } from 'hooks/useInfoPopup';
import { useTsDispatch, useTsSelector } from 'store/store.types';
import { HOTKEYS, useHotkeys, useT } from 'hooks';
import { showInfoPopup } from 'actions/infoPopupAction';
import './styles.sass';

export default function InfoPopup() {
  const _t = useT();
  const dispatch = useTsDispatch();
  const infoPopup = useTsSelector(({ infoPopup }) => infoPopup);
  const [duration, setDuration] = useState(5000);

  const hSnackBarclose = () => {
  // const hSnackBarclose: SnackbarProps['onClose'] = () => {
    // if (reason !== 'clickaway')
    dispatch(showInfoPopup(false));
  };

  useEffect(() => {
    setDuration(prev => infoPopup.show ? prev + 1 : 5000);
  }, [infoPopup]);

  useHotkeys([{ call: hSnackBarclose, keyCode: HOTKEYS.ESC, stopPropagation: () => infoPopup.show }]);


  return (
    <Snackbar
      key={8}
      className="info-popup"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={infoPopup.show}
      transitionDuration={500}
      autoHideDuration={duration}
      onClose={hSnackBarclose}
    >
      <SnackbarContent
        className={`snackbar_${infoPopup.type}`}
        message={infoPopup.message}
        action={
          infoPopup.type === InfoPopupEnum.black ?
            <Button color="primary" onClick={hSnackBarclose}>
              {_t.old('Скрыть')}
            </Button>
            :
            <>
              {infoPopup.to &&
                <Link className="link text-body-bold-m" to={infoPopup.to}>
                  {_t.old('Перейти')}
                </Link>
              }
              <IconButton size="small" onClick={hSnackBarclose}>
                <Close fontSize="small" />
              </IconButton>
            </>
        }
      />
    </Snackbar>
  );
}
