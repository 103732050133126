import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GET_COMPANY } from 'actions/companyActions';
import { infoPopupActions } from 'actions/infoPopupAction';
import { API } from 'modules/Api';
import { TagManagerService } from 'services/dataLayer.service';
import { RootState } from 'store/store.types';
import { factoryInitState, factorySetAction } from '../slices/helpers';
import { IAdditionalApiStatuses } from '../slices/interfaces';

export { useTsSelector, useTsDispatch } from 'store/store.types';

export enum SubscriptionPlanEnum {
  zero = 'zero',
  free = 'free',
  eco = 'eco',
  standard = 'standard',
  opti = 'opti',
  custom = 'custom',
}

interface ISubscription {
  months: number,
  members: number,
  fee: number,
  valid_to: string | null, // Date
  plan: SubscriptionPlanEnum,
  is_valid: boolean,
}

const initialData: ISubscription = {
  months: 0,
  members: 0,
  fee: 0,
  valid_to: null,
  plan: SubscriptionPlanEnum.custom,
  is_valid: false,
};
const initialState: ISubscription & IAdditionalApiStatuses = factoryInitState(initialData);


export const NAME = 'subscription';
const parent = 'profile';
const scope = `${parent}/${NAME}`;

export const free = createAsyncThunk(
  `${scope}/free`,
  (_: void, { dispatch }): Promise<ISubscription> =>
    API
      .post<ISubscription>('/subscriptions/free/')
      .then(({ data }) => {
        dispatch(setSubscription(data));
        dispatch({ type: GET_COMPANY, payload: { is_subscribed: true } });
        TagManagerService.events.subscriptionFree;
        return data;
      })
      .catch(error => {
        dispatch(infoPopupActions.apiErrors(error));
        throw error;
      })
  ,
);

export const subscriptionSelector = (state: RootState): typeof initialState => state[parent][NAME];
export const isSubscribedSelector = (state: RootState): boolean => state[parent][NAME].is_valid;


export const {
  reducer,
  actions: {
    setSubscription,
    resetSubscription,
  },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setSubscription: (state, payload) => factorySetAction<ISubscription>(state, payload),
    resetSubscription: () => ({ ...initialState }),
  },
});

export default reducer;
