import { API } from 'modules/Api';

const BASE = 'INVITES';
const TYPES = {
  LOADING: `LOADING_${BASE}`,
  GET: `GET_${BASE}`,
  UPDATE: `UPDATE_${BASE}`,
  FAILED: `FAILED_${BASE}`,
};
export default TYPES;

export const getInvites = () => dispatch => {
  dispatch({ type: TYPES.LOADING, payload: true });

  API.get('/companies/invites/my/')
    .then(({ data }) =>
      dispatch({ type: TYPES.GET, payload: data }),
    )
    .catch(({ response: { data } }) =>
      dispatch({ type: TYPES.FAILED, payload: data }),
    )
    .finally(() => {
      dispatch({ type: TYPES.LOADING, payload: false });
    });
};

export const updateInvite = (id, results) => ({
  type: TYPES.UPDATE,
  payload: {
    id,
    ...results,
  },
});
