import { combineReducers } from '@reduxjs/toolkit';
import user, { NAME as userName } from './user.slice';
import avatar, { NAME as avatarName } from './avatar.slice';
import settings, { NAME as settingsName } from './settings.slice';
import subscription, { NAME as subscriptionName } from './subscription.slice';
import company, { NAME as companyName } from './company.reducer';


export default combineReducers({
  [userName]: user,
  [settingsName]: settings,
  [avatarName]: avatar,
  [companyName]: company,
  [subscriptionName]: subscription,
});
