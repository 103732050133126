import store from 'store/store';
import Nav, { Options as NavOptions, ROUTES } from 'nav';
import { ICountry,  IPortOptions } from 'reducers/allReducer';
import { DateTimeHelper } from 'core/helpers/dateTime.helper';
import { IAddress } from 'reducers/slices/addresses.slice';
import { NotRequired, Nullable } from 'core/interfaces/common.interfaces';
import { generatePath } from 'react-router';

export type { IAddress };
export { Nav };

export class Address {
  protected _store!: ReturnType<typeof store['getState']>;

  static goRoot(options?: NavOptions): void {
    Nav.go(ROUTES.settings.addresses.root, options);
  }
  static goToCreate(options?: NavOptions): void {
    Nav.go(ROUTES.settings.addresses.create, options);
  }
  static isArchivedPath(path: string): boolean {
    return path === this.pathToArchived;
  }
  static get pathToArchived(): string {
    return ROUTES.settings.addresses.archive;
  }
  static get pathToRoot(): string {
    return ROUTES.settings.addresses.root;
  }
  static get pathToCreate(): string {
    return ROUTES.settings.addresses.create;
  }
  constructor (
    public $: Partial<IAddress>,
  ) {}

  get id() { return this.$.id; }
  get name() { return this.$.name; }

  get country(): Nullable<ICountry> {
    if (!this.$.country_id) {
      return null;
    }
    return this.store.all.countries.list[this.$.country_id];
  }
  get state(): NotRequired<string> {
    if (this.$.country_id && this.$.state_id) {
      const state = this.country?.states?.find(state => state.id === this.$.state_id);
      return state?.name || this.$.state_sum || this.$.state_name;
    }
    return this.$.state_name || this.$.state_sum;
  }
  get district(): NotRequired<string> {
    if (this.$.country_id && this.$.state_id && this.$.district_id) {
      const state = this.country?.states?.find(state => state.id === this.$.state_id);
      const district = state?.districts.find(district => district.id === this.$.district_id);
      return district?.name || this.$.district_sum || this.$.district_name;
    }
    return this.$.district_name || this.$.district_sum;
  }
  get port(): Nullable<IPortOptions> {
    if (!this.$.port_id) {
      return null;
    }
    return this.store.all.port_options.list[this.$.port_id];
  }

  get createdAt(): Nullable<string> {
    if (!this.$.created_at) {
      return null;
    }
    return DateTimeHelper.format(this.$.created_at); // main
  }
  get archivedAt(): Nullable<string> {
    if (!this.$.archived_at) {
      return null;
    }
    return DateTimeHelper.format(this.$.archived_at); // main
  }

  get shortName(): string {
    return [
      this.country?.name,
      this.port?.name,
    ].filter(Boolean).join(' ');
  }
  get middleName(): string {
    return [
      this.port?.name || this.$.city,
      this.$.index,
      this.$.station,
    ].filter(Boolean).join(', ');
  }
  get detailedName(): string {
    return [
      this.country?.name,
      this.port?.name,
      this.$.index,
    ].filter(Boolean).join(' ');
  }
  get fullDetails(): string {
    return [
      this.port?.name || this.$.city,
      this.$.index,
      this.state,
      this.district,
      this.$.details,
    ].filter(Boolean).join(', ');
  }

  get isArchived(): boolean {
    return this.$.archived_at !== null;
  }


  get pathToShow(): string {
    const id = this.$.id;
    if (!id) {
      return '';
    }
    return generatePath(ROUTES.settings.addresses.show, { id });
  }
  goToShow(): void {
    Nav.go(this.pathToShow, { vibrate: 'standard' });
  }
  goToEdit(): void {
    Nav.editAddress(this, { vibrate: 'standard' });
  }


  private get store() {
    return this._store ? this._store : this._store = store.getState();
  }
}
