import { Dispatch, SetStateAction, useState } from 'react';
import { useLocation } from 'react-router';

export function useLocalStorage<T>(
  key: string | null,
  initialValue: T,
  isPageDepending = true,
): [T, Dispatch<SetStateAction<T>>] {
  const location = useLocation();
  const pageKey: string = location.pathname;
  const keyForLS: string | null = key && isPageDepending ? `${pageKey}::${key}` : key;
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (!keyForLS) return initialValue;
    try {
      const stringValue = window.localStorage.getItem(keyForLS);
      return stringValue ? JSON.parse(stringValue) as T : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  try {
    if (keyForLS) {
      if (storedValue === null) {
        window.localStorage.removeItem(keyForLS);
        return [initialValue, setStoredValue];
      }
      const stringValue: string = JSON.stringify(storedValue);
      if (window.localStorage.getItem(keyForLS) !== stringValue ) {
        window.localStorage.setItem(keyForLS, stringValue);
      }
    }
  } catch (error) { /* empty */ }
  return [storedValue, setStoredValue];
}
