import { ROUTES } from 'nav';
import { LocalStorageHelper } from 'utils/LocalStorage.helper';
const UKRANIAN_ID = 1;

interface IItem {
  id: string;
  name: string;
  ports?: number[];
}

const NULL_ITEM: IItem = {
  id: '',
  name: '',
};

const cultures = {
  null: NULL_ITEM,
  peas: {
    id: '77,76,70,69,68',
    name: 'peas',
  } as IItem,
  millet: {
    id: '126,65,43,42,39,1',
    name: 'millet',
  } as IItem,
  sunflower_seeds: {
    id: '6',
    name: 'sunflower seeds',
  } as IItem,
  rape_seeds: {
    id: '1',
    name: 'rape seeds',
  } as IItem,
} as const;

const countries = {
  null: NULL_ITEM,
  nl: {
    id: `${UKRANIAN_ID},21`,
    name: 'Netherlands',
  } as IItem,
  it: {
    id: `${UKRANIAN_ID},25`,
    name: 'Italy',
  } as IItem,
  pl: {
    id: `${UKRANIAN_ID},26`,
    name: 'Poland',
  } as IItem,
  gb: {
    id: `${UKRANIAN_ID},7`,
    name: 'Great Britain',
    ports: [328, 327],
  } as IItem,
  bg: {
    id: `${UKRANIAN_ID},27`,
    name: 'Bulgaria',
  } as IItem,
  hu: {
    id: `${UKRANIAN_ID},28`,
    name: 'Hungary',
  } as IItem,
  de: {
    id: `${UKRANIAN_ID},29`,
    name: 'Germany',
  } as IItem,
} as const;

const LS_KEY = 'LandingPageInfo';

export interface ILandingParams {
  cultureId: keyof typeof cultures;
  countryId: keyof typeof countries;
}

interface ILandingPageInfo extends ILandingParams {
  createdAt: Date;
  registered: boolean;
}

const DEFAULT_COMPANY_INFO = {
  country: null,
  countries: [UKRANIAN_ID],
  cultures: [1],
  ports: [250],
  shipments: [1, 2, 3, 4, 5, 6, 7],
  amount_of_workers: '-5',
  annual_turnover: '-5',
  is_organic: null,
};

const NULL_LANDING_PARAMS: ILandingParams = {
  cultureId: 'null',
  countryId: 'null',
};
Object.freeze(NULL_LANDING_PARAMS);

export class LandingPageInfo {
  private static store: LocalStorageHelper<ILandingPageInfo> = new LocalStorageHelper(LS_KEY);

  static isValid(): boolean {
    return false;
    // return this.localItem().isValid();
  }
  static get marketPath(): string {
    const item = this.localItem();
    if (item.isValid()) return item.filteredMarketPath;
    return ROUTES.market;
  }

  static localItem(): LandingPageInfo {
    const currentData = this.store.getValue();
    return new this(currentData || NULL_LANDING_PARAMS);
  }

  static companyDefaultValues() {
    const currentData = this.store.getValue();
    if (!currentData || !this.isValid()) return null;
    const item = new this(currentData);
    const countries: number[] = item.country.id.split(',').map(Number);
    const cultures: number[] = item.culture.id.split(',').map(Number);
    const country: number = countries.slice(-1)[0];
    const ports: number[] = item.country.ports || DEFAULT_COMPANY_INFO.ports;

    return {
      ...DEFAULT_COMPANY_INFO,
      country,
      countries,
      cultures,
      ports,
    };
  }


  constructor (
    private readonly params: ILandingParams,
    private readonly isAuth?: boolean,
  ) {}

  get country(): IItem {
    return countries[this.params.countryId];
  }

  get culture(): IItem {
    return cultures[this.params.cultureId];
  }

  get filteredMarketPath(): string {
    return `${ROUTES.proposals}?countries[]=${this.country.id}&cultures[]=${this.culture.id}&type=offer`;
  }

  isValid(): boolean {
    if (this.params === NULL_LANDING_PARAMS) return false;
    return !!this.country && !!this.culture;
  }

  updateInfo(): void {
    if (!this.needUpdate) return;

    LandingPageInfo.store.setValue({
      createdAt: new Date(),
      cultureId: this.params.cultureId,
      countryId: this.params.countryId,
      registered: false,
    });
  }

  private get needUpdate(): boolean {
    return !this.isAuth && !LandingPageInfo.localItem().isValid();
  }
}
