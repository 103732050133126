import { useEffect } from 'react';
import { useParams } from 'react-router';
import Logger from 'modules/Logger';
import Nav from 'nav';
import { LandingPageInfo } from 'models/LandingPageInfo';
import { isAuthSelector, loginByLink, profileUserSelector, useTsDispatch, useTsSelector } from 'reducers/profile/user.slice';

export default function Activation() {
  const dispatch = useTsDispatch();

  const { activation_token } = useParams<{ activation_token: string }>();
  const isAuth = useTsSelector(isAuthSelector);
  const user = useTsSelector(profileUserSelector);

  useEffect(() => {
    if (!isAuth) return;
    if (!user.company_id || !user.contact_name) Nav.routes().registration.root;
    else Nav.go(LandingPageInfo.marketPath);
  }, [isAuth, user]);

  useEffect(() => {
    // if (isAuth) return;
    dispatch(loginByLink({ activation_token })).unwrap()
      .catch(error => {
        Logger.error(error);
        if (isAuth) Nav.routes().market;
        else Nav.routes().signIn;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activation_token]);

  return null;
}
