import { AnyTODO } from 'core/interfaces';
import { IOffer } from 'reducers/oneOfferReducer';

export const GET_ONE_OFFER_START = 'GET_ONE_OFFER_START';
export const GET_ONE_OFFER_SUCCESS = 'GET_ONE_OFFER_SUCCESS';
export const GET_ONE_OFFER_FAILURE = 'GET_ONE_OFFER_FAILURE';
export const CLEAR_ONE_OFFER = 'CLEAR_ONE_OFFER';
export const GET_UNITS_OFFER = 'GET_UNITS_OFFER';

const getOneOfferSuccess = (payload: IOffer) => ({
  type: GET_ONE_OFFER_SUCCESS,
  payload,
});

const getOneOfferStarted = () => ({
  type: GET_ONE_OFFER_START,
});

const getOneOfferFailure = (error: AnyTODO) => ({
  type: GET_ONE_OFFER_FAILURE,
  payload: {
    error,
  },
});

const clearOneOffer = () => ({
  type: CLEAR_ONE_OFFER,
});



export { getOneOfferSuccess, getOneOfferStarted, getOneOfferFailure, clearOneOffer };
