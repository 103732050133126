import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgClockFullIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="7.99957"
          cy="7.99999"
          r="6.22222"
          stroke="var(--gray800)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 5.66669V8.00002L9.55556 9.55558"
          stroke="var(--gray800)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIconContainer>
  );
};
