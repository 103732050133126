import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import './index.scss';

const Preloader = () => {
  return (
    <div className="preloaderBox">
      <CircularProgress className="preloader" size={80} />
    </div>
  );
};

export default Preloader;
