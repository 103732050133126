import TYPES from 'actions/invitesActions';
import { IAdditionalApiStatuses } from './slices/interfaces';


interface IInvite {
  id: number,
  status: 'sent' | string,
}
interface Items<T> {
  items: T[],
}

const initialState: Items<IInvite> & IAdditionalApiStatuses = {
  loading: true,
  error: null,
  loaded: false,

  items: [],
};

export default function invitesReducer(state = initialState, action: { type: string, payload: unknown }): typeof initialState {
  switch (action.type) {
    case TYPES.LOADING:
      return { ...state, loading: (action.payload as boolean) };
    case TYPES.GET:
      return { ...state, error: null, loaded: true, items: (action.payload as { results: IInvite[]}).results };
    case TYPES.UPDATE:
      return {
        ...state,
        error: null,
        items: state.items.map(item => item.id === (action.payload as IInvite).id ? { ...item, ...(action.payload as IInvite) } : item),
      };
    case TYPES.FAILED:
      return { ...state, error: (action.payload as string) };
    default:
      return state;
  }
}
