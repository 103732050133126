import React, { useState } from 'react';
import { ClickAwayListener, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { queryFromUrl, toUrlQuery } from 'api/utils';
import { useHistory, useLocation } from 'react-router';
import { HandshakeFilterIcon } from '../HandshakeIcon';
import { classes } from 'utils';
import Notificate from 'services/notificate.service';
import { DropdownStatus } from 'modules/PreviewPage/components/ShareButton/interfaces';
import { useT } from 'hooks';
import { SCOPE_COMPANIES, SCOPE_COMPANY_HANDSHAKES } from 'i18n/structure/modules/Companies/Companies';
import './style.sass';


export enum HandshakeFilterEnum {
  any = 'any',
  full = 'full',
  my = 'my',
  target = 'target',

  none = 'none',
  all = 'all',
}

interface Props {
  defaultValue: HandshakeFilterEnum;
  fieldName: string;
}

const ITEMS = [
  HandshakeFilterEnum.any,
  HandshakeFilterEnum.full,
  HandshakeFilterEnum.my,
  HandshakeFilterEnum.target,
];


export const HandshakeFilter: React.FC<Props> = ({ defaultValue, fieldName }) => {
  const _t = useT([SCOPE_COMPANIES, SCOPE_COMPANY_HANDSHAKES, 'filter']);
  const { search } = useLocation();
  const { replace } = useHistory();
  const queryParams = queryFromUrl(search);
  const value = ITEMS.find(item => item === queryParams[fieldName]) || defaultValue;


  const go = (newValue: HandshakeFilterEnum) => {
    replace({
      search: toUrlQuery({
        ...queryParams,
        [fieldName]: newValue,
      }),
    });
  };

  const hMenuItemClick = (item: HandshakeFilterEnum): React.MouseEventHandler<HTMLDivElement> => () => {
    go(item);
  };

  const [dropdownStatus, setDropdownStatus] = useState<DropdownStatus>('standard');
  const turnOffOpenedStatus = () => setDropdownStatus('standard');
  // const instanceCloseDropdown = () => {
  //   setDropdownStatus('closed');
  //   setTimeout(() => setDropdownStatus('standard'), 100);
  // };
  const onClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    Notificate.standard();
    setDropdownStatus(prevState => prevState == 'opened' ? 'standard' : 'opened' );
  };


  return (
    <ClickAwayListener onClickAway={turnOffOpenedStatus}>
      <div className={classes('HandshakeFilter', dropdownStatus)}>
        <div className="HandshakeFilter__button" onClick={onClick}>
          <HandshakeFilterIcon type={value} />
          <IconButton className="HandshakeFilter__button-icon_down" component="span" size="small" >
            <ExpandMoreIcon />
          </IconButton>

        </div>

        <div className="HandshakeFilter__dropdown-wrap">
          <div className="HandshakeFilter__dropdown">

            {ITEMS.map(item => (
              <div className={classes('HandshakeFilter__dropdown-item', { selected: value === item })}
                key={item}
                onClick={hMenuItemClick(item)}
              >
                <HandshakeFilterIcon type={item} />
                {_t(['names', item])}
              </div>
            ))}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};
