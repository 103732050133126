import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgOrganicIcon: React.FC<{ className?: string; }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.34692 14.6226C19.0579 14.096 18.4873 4.95978 16.1621 1.07327C13.2468 1.02794 8.77914 2.72828 7.27344 6.49069M8.31099 14.6226L12.3304 7.66079" stroke="#BABABA" strokeWidth="2"/>
        <path d="M8.22733 14.6979C0.438151 14.3149 0.838617 7.59809 2.52952 4.77175C3.94528 4.74974 5.85535 5.2991 7.28994 6.48384C9.19226 8.05485 10.2585 10.7431 8.22733 14.6979Z" stroke="#BABABA" strokeWidth="2"/>
        <path d="M8.20773 14.6431L5.00781 9.10071" stroke="#BABABA" strokeWidth="2"/>
      </svg>
    </SvgIconContainer>
  );
};
