import { Middleware } from '@reduxjs/toolkit';
import { NAME, ISystemSettings, LOCALSTORAGE_KEY } from 'reducers/slices/systemSettings.slice';
import { LocalStorageHelper } from 'utils/LocalStorage.helper';
// import { RootState } from '../store.types';


export const localStorageMiddleware: Middleware = store => next => action => {
  const result = next(action);

  if ((action.type as string || '').startsWith(`${NAME}/set`)) {
    const state = store.getState();
    new LocalStorageHelper<ISystemSettings>(LOCALSTORAGE_KEY).setValue(state.systemSettings);
  }

  return result;
};
