import React, { useEffect } from 'react';
import Nav from 'nav';
import { useTsDispatch, useTsSelector } from 'store/store.types';
import { isAuthSelector, logout } from 'reducers/profile/user.slice';
import { ROUTES } from 'constants/routes';

export const LogoutPage: React.FC = () => {
  const isAuth = useTsSelector(isAuthSelector);
  const dispatch = useTsDispatch();

  useEffect(() => { dispatch(logout()); }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !isAuth && Nav.replace(ROUTES.signIn);
  }, [isAuth]);

  return null; // TODO: Can display info and loader
};
