type ArgumentSimpleType = string | string[] | boolean | undefined | null | (() => ArgumentSimpleType);
type ArgumentType = ArgumentSimpleType | Record<string, ArgumentSimpleType>;

export const classes = (...args: ArgumentType[]): string => {
  return args.map(extract).flat().filter(Boolean).join(' ');
};

const extract = (item: ArgumentType): string | false | (string | false)[] => {
  if (typeof item === 'function') {
    return extract(item());
  }
  if (typeof item === 'string') {
    return item;
  }
  if (Array.isArray(item)) {
    return item.map(extract).flat();
  }
  if (!item || item === true) {
    return false;
  }
  return Object.keys(item).map(key => {
    if (item[key] === true) {
      return key;
    }
    return [extract(item[key])].flat().map(value => value && `${key}--${value}`);
  }).flat();
};
