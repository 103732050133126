export const SCOPE_SEO_SCHEMA = 'SeoSchema';

export const uk = {
  Home: {
    slogan: 'Експортуйте легко та безпечно!',
    contactPoint: {
      supportEmail: 'Виконання контрактів під ключ',
    },
  },
  types: {
    Place: 'Локація доставки',
  },
};

export const en: typeof uk = {
  Home: {
    slogan: 'Export easily and safely!',
    contactPoint: {
      supportEmail: 'Execution of turnkey contracts',
    },
  },
  types: {
    Place: 'Delivery location',
  },
};

export const ru: typeof uk = {
  Home: {
    slogan: 'Экспортируйте легко и безопасно!',
    contactPoint: {
      supportEmail: 'Выполнение контрактов под ключ',
    },
  },
  types: {
    Place: 'Локация доставки',
  },
};

export const bg: typeof uk = {
  Home: {
    slogan: 'Изнасяйте лесно и безопасно!',
    contactPoint: {
      supportEmail: 'Изпълнение на договори до ключ',
    },
  },
  types: {
    Place: 'Място за доставка',
  },
};
