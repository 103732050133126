import axios, { AxiosError, AxiosInstance } from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { ROUTES } from 'constants/routes';
import i18n from 'i18n';
import Nav from 'nav';
import { companyIdSelector, setToken, tokenSelector } from 'reducers/profile/user.slice';
import store from '../store/store';
export { API_ROUTES } from '../constants/general';

const HTTP_401_UNAUTHORIZED = 401;
const baseURL = process.env.REACT_APP_API_URL;

const AUTH_HEADER_NAME = 'authorization';

const cache = setupCache({
  maxAge: 4 * 60 * 60 * 1000,
  exclude: {
    methods: ['put', 'patch', 'delete'],
    query: false,
    // filter: req => +(req?.params?.page || 0) > 3,
  },
  debug: process.env.NODE_ENV === 'development',
});


export const API = axios.create({ baseURL });
export const ApiCached = axios.create({ baseURL, adapter: cache.adapter });


addInterceptors(API);
addInterceptors(ApiCached);

function addInterceptors(instance: AxiosInstance) {
  instance.interceptors.request.use(
    function (config) {
      if (config.headers) {
        const token = tokenSelector(store.getState());
        if (token) {
          config.headers[AUTH_HEADER_NAME] = token;
        }
        const language = i18n.language;
        if (language) {
          config.headers['Accept-Language'] = language;
        }
        const company_id = companyIdSelector(store.getState());
        if (company_id) {
          config.headers['Company-Id'] = company_id;
        }
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    function (response) {
      const token = response.headers[AUTH_HEADER_NAME];
      token && store.dispatch(setToken(token));
      return response;
    },
    function (error: AxiosError) {
      if (HTTP_401_UNAUTHORIZED === error.response?.status) {
        store.dispatch(setToken(null));
        Nav.go(ROUTES.logout);
      }
      return Promise.reject(error);
    },
  );
}
