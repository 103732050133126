export const SCOPE_REVIEWS = 'Reviews';

export const uk = {
  Fields: {
    text: {
      label: 'Текст',
      placeholder: 'Текст',
    },
    ratings: {
      label: 'Оцінки',
    },
  },
};

export const en: typeof uk = {
  Fields: {
    text: {
      label: 'Text',
      placeholder: 'Text',
    },
    ratings: {
      label: 'Ratings',
    },
  },
};

export const ru: typeof uk = {
  Fields: {
    text: {
      label: 'Текст',
      placeholder: 'Текст',
    },
    ratings: {
      label: 'Оценки',
    },
  },
};

export const bg: typeof uk = {
  Fields: {
    text: {
      label: 'Текст',
      placeholder: 'Текст',
    },
    ratings: {
      label: 'Оценки',
    },
  },
};
