import TYPES from 'actions/allActions';
import { UPDATE_DETAILS_CULTURE, UPDATE_FULL_COUNTRY } from 'actions/culturesActions';
import { LotType } from 'constants/general';
import { AnyTODO } from 'core/interfaces/AnyTODO.type';
import i18n, { LanguageEnum } from 'i18n';
import { ICompanyHandshake } from 'models/CompanyHandshake';
import { RootState } from 'store/store.types';
import { Churl } from 'utils/clients/Churl';
import { factoryInitStateLoadingFalse } from './slices/helpers';
import { IAdditionalApiStatuses, IInitListState } from './slices/interfaces';

export const ALL_LIST = [
  'basis',
  'categories',
  'companies',
  'company_types',
  'countries',
  'cultures',
  'currencies',
  'documents_options',
  'markets',
  'packaging_options',
  'parameters',
  'payments_options',
  'port_options',
  'shipping_lines',
  'shipping_options',
] as const;
const LIST_NOT_SORT = ['packaging_options'];

interface IBaseOptions {
  id: number;
  name: string;
  default_sell: boolean;
  default_buy: boolean;
}

export interface ICompany extends IBaseOptions {
  id: number;
  country_id: number;
  name: string;
  local_name?: string;
  logo: string | null;
  show_cabinet: boolean;
  can_show_cabinet: boolean;
  handshake: ICompanyHandshake;
}

export interface ICompanyType {
  id: number;
  name: string;
}

export interface ILotCultureParameter {
  id?: number;
  culture_parameter_id: number;
  value: string | null;
  offer_id?: number;
  product_request_id?: number;
}

export interface ICultureParameter {
  id: number;
  parameter_id: number;
  value: string;
  units: string;
  required: boolean;
}

interface ISeo {
  title: string;
  churl: string;
  description: string;
  text: string;
  language: LanguageEnum;
}

export interface ICulture extends IBaseOptions {
  category_id: number;
  churl: string;
  image: string;
  icon: string;
  parameters?: ICultureParameter[];
  _fullLoaded?: boolean;
  total_offers_count: number;
  total_offers_sum: number;
  total_requests_count: number;
  total_requests_sum: number;
  load_standards?: {
    shipping_id: number;
    packaging_id: number;
    value: number;
  }[];
  created_at: string;
  seo?: ISeo | null;
}

export interface ICategory extends IBaseOptions {
  cultures: ICulture[];
  churl: string;
  seo_title?: string;
  seo_keywords?: string;
  seo_description?: string;
}

export interface IParameter extends IBaseOptions {
  id: number;
  value: string;
  units: string;
}

export interface ICountry extends IBaseOptions {
  iso3166a2: string;
  phone_code: string;
  market_id: number;
  _fullLoaded?: boolean;
  states?: {
    id: number;
    name: string;
    districts: {
      id: number;
      name: string;
    }[];
  }[];
}

export interface IBasis extends IBaseOptions {
  is_calculated: boolean,
  shipping_types: number[];
}

export interface IPortOptions extends IBaseOptions {
  country_id: number;
}

export interface IMarket extends IBaseOptions {
  countries: ICountry[];
}

export type IPackaging = IBaseOptions;
export interface IShipping extends IBaseOptions {
  load_limitation: boolean;
  load_standard: boolean;
  number_of_containers: boolean;
  party: boolean;
  optional_documents: number[];
  mandatory_documents: number[];
}
export type IPayment = IBaseOptions;

export interface ICurrency extends IBaseOptions {
  iso_code: string;
  symbol: string;
}

export interface IAll extends Record<typeof ALL_LIST[number], AnyTODO> {
  basis: IInitListState<IBasis>;
  categories: IInitListState<ICategory>;
  companies: IInitListState<ICompany>;
  company_types: IInitListState<ICompanyType>;
  countries: IInitListState<ICountry>;
  cultures: IInitListState<ICulture>;
  currencies: IInitListState<ICurrency>;
  documents_options: IInitListState<IBaseOptions>;
  markets: IInitListState<IMarket>;
  origins: IInitListState<ICountry>;
  packaging_options: IInitListState<IPackaging>;
  parameters: IInitListState<IParameter>;
  payments_options: IInitListState<IPayment>;
  port_options: IInitListState<IPortOptions>;
  shipping_lines: IInitListState<IBaseOptions>;
  shipping_options: IInitListState<IShipping>;
}

interface ActionLoading { type: typeof TYPES.LOADING; payload: IAll; }
interface ActionGet { type: typeof TYPES.GET; payload: AnyTODO; }
interface ActionFailed { type: typeof TYPES.FAILED; payload: IAll; }
interface ActionUpdate { type: typeof UPDATE_DETAILS_CULTURE; payload: ICulture; }
interface ActionUpdateFullCountry { type: typeof UPDATE_FULL_COUNTRY; payload: ICountry; }
type Action = ActionLoading | ActionGet | ActionFailed | ActionUpdate | ActionUpdateFullCountry;


const initialState: IAll & IAdditionalApiStatuses = factoryInitStateLoadingFalse(
  ALL_LIST.reduce<Partial<IAll>>((acc, model) => {
    acc[model] = {
      items: [],
      list: {},
      count: 0,
    };
    return acc;
  }, {}) as IAll,
);


export const getAllDefaults = (type: LotType) => (state: RootState) => {
  const defaultField = type === LotType.request ? 'default_buy' : 'default_sell';

  const addresses = state.addresses;
  const company = state.profile.company;
  const basis_id = extractDefault('basis');
  const shippingOptionsList = state.all.basis.list[basis_id].shipping_types;
  const shipping_id =(shippingOptionsList.find((id: number) => state.all.shipping_options.list[id][defaultField]) || shippingOptionsList[0]);

  return {
    basis_id,
    culture_id: company.cultures?.[0] || extractDefault('cultures'),
    currency_id: company.currency || extractDefault('currencies'),
    location_id: addresses.items.find(({ archived_at }) => !archived_at)?.id || null,
    packaging_id: extractDefault('packaging_options'),
    payment_id: extractDefault('payments_options'),
    shipping_id,
    volume: null,
    price: null,
    is_organic: company.is_organic || false,

    origin_id: company.countries[0] || state.all.countries.items[0].id,
    // date_of_manufacture: DEFAULT_VALUES_REQUESTS.date_of_manufacture,
    // valid_until: moment(DEFAULT_VALUES_REQUESTS.valid_until).format(FORMAT_DATES.mainForBack),

    mandatory_documents: state.all.shipping_options.list[shipping_id].mandatory_documents,
  };

  function extractDefault(key: typeof ALL_LIST[number]): number {
    return (state.all[key].items.find((item) => (item as IBaseOptions)[defaultField]) || state.all[key].items[0]).id;
  }
};


export default function allReducer(state = initialState, action: Action): typeof initialState {
  switch (action.type) {
    case TYPES.LOADING:
      return {
        ...state,
        loading: action.payload as unknown as boolean,
      };
    case TYPES.GET:
      return {
        ...state,
        error: null,
        loaded: true,
        ...build(action.payload),
      };
    case TYPES.FAILED:
      return {
        ...state,
        error: String(action.payload),
      };
    case UPDATE_DETAILS_CULTURE: {
      const culture = action.payload;
      return updateOne('cultures', culture, state);
    }
    case UPDATE_FULL_COUNTRY: {
      const country = action.payload;
      return updateOne('countries', country, state);
    }
    default:
      return state;
  }
}


function build(data: Record<typeof ALL_LIST[number], { count: number, results: AnyTODO[]; }>): Partial<IAll> {
  const res: Partial<IAll> = {};
  ALL_LIST.forEach(key => {
    res[key] = {
      count: data[key].count,
      items: data[key].results || [],
      list: arrayToList(data[key].results),
    };
    if (LIST_NOT_SORT.includes(key)) return;
    res[key]?.items.sort((a, b) => a.name?.localeCompare(b.name, i18n.language, { sensitivity: 'accent' }));
  });

  Churl.assign(res.categories?.items || []);
  Churl.assign(res.cultures?.items || []);
  manyToOne(res, 'countries', 'market_id', res.markets, res.countries);
  manyToOne(res, 'cultures', 'category_id', res.categories, res.cultures);
  res.origins = res.countries;
  return res;
}

function arrayToList<T extends { id: number }[]>(items: T): AnyTODO {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: any = {};
  items.forEach(item => res[item.id] = item);
  return res;
}

function manyToOne<P extends IBaseOptions, C extends IBaseOptions>(
  res: Partial<IAll>,
  relation: keyof P,
  foreignKey: keyof C,
  parents?: IInitListState<P>,
  children?: IInitListState<C>,
): void {
  parents?.items.forEach((parent: AnyTODO) => { parent[relation] = []; });
  children?.items.forEach((child: AnyTODO) => {
    const parent_id = child[foreignKey];
    const parent: AnyTODO = parents?.list[parent_id];
    parent?.[relation]?.push(child);
  });
}

function updateOne(type: typeof ALL_LIST[number], payload: IBaseOptions, state: IAll): IAll {
  return {
    ...state,
    [type]: {
      ...state[type],
      list: {
        ...state[type].list,
        [payload.id]: payload,
      },
      items: state[type].items.map(item => item.id === payload.id ? payload : item),
    },
  };
}
