import { SubscriptionPlanEnum } from 'reducers/profile/subscription.slice';

const IS_BETA: boolean = process.env.REACT_APP_BETA === 'TRUE';
const ZERO_PLAN = IS_BETA ? 'beta' : '';

export const uk = {
  plans: {
    [SubscriptionPlanEnum.custom]: 'individual',
    [SubscriptionPlanEnum.eco]: 'economy',
    [SubscriptionPlanEnum.standard]: 'standard',
    [SubscriptionPlanEnum.opti]: 'optimal',
    [SubscriptionPlanEnum.free]: ZERO_PLAN,
    [SubscriptionPlanEnum.zero]: ZERO_PLAN,
  },
};

export const ru: typeof uk = {
  plans: {
    [SubscriptionPlanEnum.custom]: 'individual',
    [SubscriptionPlanEnum.eco]: 'economy',
    [SubscriptionPlanEnum.standard]: 'standard',
    [SubscriptionPlanEnum.opti]: 'optimal',
    [SubscriptionPlanEnum.free]: ZERO_PLAN,
    [SubscriptionPlanEnum.zero]: ZERO_PLAN,
  },
};

export const en: typeof uk = {
  plans: {
    [SubscriptionPlanEnum.custom]: 'individual',
    [SubscriptionPlanEnum.eco]: 'economy',
    [SubscriptionPlanEnum.standard]: 'standard',
    [SubscriptionPlanEnum.opti]: 'optimal',
    [SubscriptionPlanEnum.free]: ZERO_PLAN,
    [SubscriptionPlanEnum.zero]: ZERO_PLAN,
  },
};

export const bg: typeof uk = {
  plans: {
    [SubscriptionPlanEnum.custom]: 'individual',
    [SubscriptionPlanEnum.eco]: 'economy',
    [SubscriptionPlanEnum.standard]: 'standard',
    [SubscriptionPlanEnum.opti]: 'optimal',
    [SubscriptionPlanEnum.free]: ZERO_PLAN,
    [SubscriptionPlanEnum.zero]: ZERO_PLAN,
  },
};
