import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toApiQuery } from 'api/utils';
import { AnyTODO } from 'core/interfaces';
import { API, API_ROUTES } from 'modules/Api';
import { factoryInitListState, FactoryListBuilder } from './helpers';
import { IApiListResponse, IInitListState } from './interfaces';

export { useTsSelector, useTsDispatch } from 'store/store.types';


export interface ICompanyPosition {
  id: number;
  name: string;
}

const initialState = factoryInitListState<ICompanyPosition>();

export const NAME = 'companyPositions';
const parent = 'global';
const scope = `${parent}/${NAME}`;
const apiUrlBase: string = API_ROUTES.companies.companyPositions;

export const getCompanyPositions = createAsyncThunk<IApiListResponse<ICompanyPosition> | null, { params: Record<string, AnyTODO>, append?: boolean }>(
  `${scope}/getList`,
  (payload) => API.get<IApiListResponse<ICompanyPosition>>(`${apiUrlBase}?${toApiQuery(payload.params)}`).then(res => res.data),
);


export const {
  reducer,
  // actions: {
  // },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
  },
  extraReducers: builder => new FactoryListBuilder<ICompanyPosition, IInitListState<ICompanyPosition>>(builder)
    .buildListAction(getCompanyPositions)
  ,
});

export default reducer;
