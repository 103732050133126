type Values = 'farmers' | 'brokers' | 'buyers';

export class LandingPageEnum {
  static farmers = new LandingPageEnum('farmers');
  static brokers = new LandingPageEnum('brokers');
  static buyers = new LandingPageEnum('buyers');

  constructor(public value: Values) {}
  get isFarmers(): boolean { return this.value == 'farmers'; }
  get isBrokers(): boolean { return this.value == 'brokers'; }
  get isCustomers(): boolean { return this.value == 'buyers'; }
}
