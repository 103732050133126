export const uk = {
  title: 'Підписка',
  plans: {
    eco: 'Економ',
    standard: 'Стандарт',
    opti: 'Оптимальний',
    custom: 'Індивідуальний',
    zero: '',
    free: 'Пробний',
  },
  SubscriptionConstructor: {
    title: 'Конструктор підписки',
    description: 'Підписка {{months}} $t(month, {"count": {{months}}}) {{members}} $t(user, {"count": {{members}} })',
    labels: {
      months: 'До місяців',
      members: 'К-ть користувачів',
    },
    placeholders: {
      months: 'До місяців',
      members: 'К-ть користувачів',
    },
    fee: 'комісія {{fee}}',
    test: 'Спробувати безкоштовно',
    testConfirmation: 'Это месячная ознакомительная версия, с возможностью доступа ко всем функциям Платформы.',
  },
  SubscriptionPlan: {
    title: 'Плани підписок',
    months: 'Підписка {{months}} $t(month, {"count": {{months}}})',
    members: '{{members}} $t(user, {"count": {{members}}})',
    fee: 'Комісія {{fee}}',
    validTo: 'Діє до',
  },
  aboutFee: 'Комісія 0.1% зі ВСІХ залучених клієнтів ДОВІЧНО',
  PaymentDialog: {
    label: 'Платіжна сістема',
    choosen: 'Підписка {{months}} $t(month, {"count": {{months}}}) {{members}} $t(user, {"count": {{members}}})',
    cost: 'Загальна вартість',
    paymentSystems: {
      liqpay: 'LIQPAY',
      fondy: 'FONDY',
    },
  },
};

export const ru: typeof uk = {
  title: 'Подписка',
  plans: {
    eco: 'Эконом',
    standard: 'Стандарт',
    opti: 'Оптимальный',
    custom: 'Индивидуальный',
    zero: '',
    free: 'Индивидуальный',
  },
  SubscriptionConstructor: {
    title: 'Конструктор подписки',
    description: 'Подписка {{months}} $t(month, {"count": {{months}}}) {{members}} $t(user, {"count": {{members}}})',
    labels: {
      months: 'К-во месяцев',
      members: 'К-во пользователей',
    },
    placeholders: {
      months: 'К-во месяцев',
      members: 'К-во пользователей',
    },
    fee: 'комиссия {{fee}}',
    test: 'Пробовать бесплатно',
    testConfirmation: 'Це місячна ознайомча версія, з можливістю доступу до всіх функцій Платформи.',
  },
  SubscriptionPlan: {
    title: 'Планы подписок',
    months: 'Подписка {{months}} $t(month, {"count": {{months}}})',
    members: '{{members}} $t(user, {"count": {{members}}})',
    fee: 'Комиссия {{fee}}',
    validTo: 'Действителен до',
  },
  aboutFee: 'Комиссия 0.1% со ВСЕХ привлеченных клиентов ПОЖИЗНЕННАЯ',
  PaymentDialog: {
    label: 'Платёжная система',
    choosen: 'Подписка {{months}} $t(month, {"count": {{months}}}) {{members}} $t(user, {"count": {{members}}})',
    cost: 'Общая стоимость',
    paymentSystems: {
      liqpay: 'LIQPAY',
      fondy: 'FONDY',
    },
  },
};

export const en: typeof uk = {
  title: 'Subscription',
  plans: {
    eco: 'Economy',
    standard: 'Standard',
    opti: 'Optimal',
    custom: 'Custom',
    zero: '',
    free: 'Free',
  },
  SubscriptionConstructor: {
    title: 'Subscription constructor',
    description: 'Subscription {{months}} $t(month, {"count": {{months}}}) {{members}} $t(user, {"count": {{members}} })',
    labels: {
      months: 'Number of months',
      members: 'Number of users',
    },
    placeholders: {
      months: 'Number of months',
      members: 'Number of users',
    },
    fee: 'fee {{fee}}',
    test: 'Try for free',
    testConfirmation: 'This is a monthly trial version, with the ability to access all the features of the Platform.',
  },
  SubscriptionPlan: {
    title: 'Subscription plans',
    months: 'Subscription {{months}} $t(month, {"count": {{months}}})',
    members: '{{members}} $t(user, {"count": {{members}}})',
    fee: 'Fee {{fee}}',
    validTo: 'Valid to',
  },
  aboutFee: '0.1% commission on ALL referred clients is LIFETIME',
  PaymentDialog: {
    label: 'Payment system',
    choosen: 'Subscription {{months}} $t(month, {"count": {{months}}}) {{members}} $t(user, {"count": {{members}}})',
    cost: 'Total amount',
    paymentSystems: {
      liqpay: 'LIQPAY',
      fondy: 'FONDY',
    },
  },
};

export const bg: typeof uk = {
  title: 'Абонамент',
  plans: {
    eco: 'Икономичен',
    standard: 'Стандартен',
    opti: 'Оптимален',
    custom: 'Индивидуален',
    zero: '',
    free: 'Индивидуален',
  },
  SubscriptionConstructor: {
    title: 'Конструктор на абонаменти',
    description: 'Абонамент за {{months}} $t(month, {"count": {{months}}}) {{members}} $t(user, {"count": {{members}}})',
    labels: {
      months: 'Брой месеци',
      members: 'Брой потребители',
    },
    placeholders: {
      months: 'Брой месеци',
      members: 'Брой потребители',
    },
    fee: 'комисионна {{fee}}',
    test: 'Изпробвайте безплатно',
    testConfirmation: 'Това е месечна пробна версия с възможност за достъп до всички функции на Платформата.',
  },
  SubscriptionPlan: {
    title: 'Абонаментни планове',
    months: 'Абонамент за {{months}} $t(month, {"count": {{months}}})',
    members: '{{members}} $t(user, {"count": {{members}}})',
    fee: 'Комисионна {{fee}}',
    validTo: 'Валиден до',
  },
  aboutFee: 'Комисионна 0.1% от ВСИЧКИ привлечени клиенти ЗА ЦЯЛ ЖИВОТ',
  PaymentDialog: {
    label: 'Платежна система',
    choosen: 'Абонамент за {{months}} $t(month, {"count": {{months}}}) {{members}} $t(user, {"count": {{members}}})',
    cost: 'Обща стойност',
    paymentSystems: {
      liqpay: 'LIQPAY',
      fondy: 'FONDY',
    },
  },
};
