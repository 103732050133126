import { TOGGLE_DIALOG } from 'actions/registrationDialogAction';
import { AnyTODO } from 'core/interfaces';

interface IState {
  isShow: boolean;
  data: AnyTODO;
}

interface ActionToggle {
  type: typeof TOGGLE_DIALOG;
  isShow: boolean;
}

type Action = ActionToggle;

const initialState: IState = {
  isShow: false,
  data: {},
};

export default function registrationDialog(state = initialState, action: Action) {
  switch (action.type) {
    case TOGGLE_DIALOG:
      return { ...state, isShow: action.isShow };
    default:
      return state;
  }
}
