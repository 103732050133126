import React, { useEffect, useRef } from 'react';
import { AnyTODO } from 'core/interfaces/AnyTODO.type';

export function useEventListener(
  eventName: string,
  handler: ((event: Event) => unknown) | ((event: React.KeyboardEvent) => unknown),
  element: Window | HTMLElement | Element = window,
) {
  const savedHandler = useRef(handler);
  useEffect(() => { savedHandler.current = handler; }, [handler]); // TODO can remove UE?

  useEffect(() => {
    const isSupported = element?.addEventListener;
    if (!isSupported || !savedHandler.current) return;

    const eventListener = savedHandler.current;
    element.addEventListener(eventName, eventListener as AnyTODO);

    return () => {
      element.removeEventListener(eventName, eventListener as AnyTODO);
    };
  }, [eventName, element]);
}
