import {
  GET_COMPANY,
  VERIFIED_PENDING,
  GET_COMPANY_MEMBERS,
  GET_COMPANY_INVITES,
  ADD_COMPANY_INVITE,
  UPDATE_COMPANY_INVITE,
  DELETE_COMPANY_MEMBER,
  RESET_COMPANY,
} from 'actions/companyActions';
import { AnyTODO } from 'core/interfaces/AnyTODO.type';
import { RootState } from 'store/store.types';

export interface IProfileCompnany {
  id: null | number;
  address: string;
  amount_of_workers: string;
  annual_turnover: string;
  created_date: null;
  country: number | null;
  countries: number[];
  cultures: number[];
  markets: number[];
  ports: number[];
  shipments: number[];
  currency: null | number;
  invites_count: null | number;
  invites: AnyTODO[];
  is_organic: null | boolean;
  is_verified: null | boolean;
  loading_invites: boolean;
  loading_members: boolean;
  members_count: null | number;
  members: AnyTODO[];
  name: string;
  local_name?: string;
  owner: null | number | string;
  verifying: null | boolean;
  is_used_free: null | boolean;

  loaded: boolean;
}

const initialState: IProfileCompnany = {
  id: null,
  address: '',
  amount_of_workers: '',
  annual_turnover: '',
  created_date: null,
  countries: [],
  country: null,
  cultures: [],
  currency: null,
  invites_count: null,
  invites: [],
  is_organic: null,
  is_verified: null,
  loading_invites: true,
  loading_members: true,
  markets: [],
  members_count: null,
  members: [],
  name: '',
  local_name: '',
  owner: null,
  ports: [],
  shipments: [],
  verifying: null,
  is_used_free: null,

  loaded: false,
};

const parent = 'profile';
export const NAME = 'company';
export const profileCompanySelector = (state: RootState) => state[parent][NAME];

interface ActionGetCompany {
  type: typeof GET_COMPANY;
  payload: IProfileCompnany;
}
interface ActionVerifiedPending {
  type: typeof VERIFIED_PENDING;
  verifying: boolean;
}
interface ActionCompanyMembers {
  type: typeof GET_COMPANY_MEMBERS;
  count: number;
  payload: AnyTODO[];
}
interface ActionCompanyInvites {
  type: typeof GET_COMPANY_INVITES;
  count: number;
  payload: AnyTODO[];
}
interface ActionUpdateCompanyInvite {
  type: typeof UPDATE_COMPANY_INVITE;
  payload: AnyTODO;
}
interface ActionAddCompanyInvite {
  type: typeof ADD_COMPANY_INVITE;
  payload: AnyTODO[];
}
interface ActionDeleteCompanyMember {
  type: typeof DELETE_COMPANY_MEMBER;
  id: number;
  payload: AnyTODO[];
}
interface ActionResetCompany {
  type: typeof RESET_COMPANY;
}

type Action = ActionGetCompany | ActionVerifiedPending | ActionCompanyMembers | ActionCompanyInvites |
  ActionUpdateCompanyInvite | ActionAddCompanyInvite | ActionDeleteCompanyMember | ActionResetCompany;


export default function companyReducer(state: IProfileCompnany = initialState, action: Action): IProfileCompnany {
  switch (action.type) {
    case GET_COMPANY:
      return {
        ...state,
        ...action.payload,
        loaded: true,
        ...(action.payload?.ports && { locations: [...action.payload.ports] }),
      };
    case VERIFIED_PENDING:
      return {
        ...state,
        verifying: action.verifying,
      };
    case GET_COMPANY_MEMBERS:
      return {
        ...state,
        loading_members: false,
        members_count: action.count,
        members: action.payload,
      };
    case GET_COMPANY_INVITES:
      return {
        ...state,
        loading_invites: false,
        invites_count: action.count,
        invites: action.payload,
      };
    case UPDATE_COMPANY_INVITE:
      return {
        ...state,
        invites: state.invites.map(item => item.id === action.payload.id ? { ...item, ...action.payload } : item),
      };
    case ADD_COMPANY_INVITE:
      return {
        ...state,
        invites_count: (state.invites_count || 0) + 1,
        invites: [action.payload, ...state.invites],
      };
    case DELETE_COMPANY_MEMBER:
      return {
        ...state,
        members: state.members.filter((member) => member.id !== action.id),
      };
    case RESET_COMPANY:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
