export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';
export const INCREMENT_FILTER_COUNTER = 'INCREMENT_FILTER_COUNTER';
export const CLOSE_FILTERS = 'CLOSE_FILTERS';
export const RESET_FILTER = 'RESET_FILTER';

export const toggleFilters = (value: null | boolean = false) => ({
  type: TOGGLE_FILTERS,
  value,
});

export const closeFilters = () => ({
  type: CLOSE_FILTERS,
});

export const incrementFilterCounter = () => ({
  type: INCREMENT_FILTER_COUNTER,
});

export const resetFilter = () => ({
  type: RESET_FILTER,
});
