import { createSlice } from '@reduxjs/toolkit';
import { LotType } from 'constants/general';
import { TodoAny } from 'core/interfaces/common.interfaces';
import { ParsedQuery } from 'query-string';
import Notificate from 'services/notificate.service';
import { RootState } from 'store/store.types';

const name = 'filtersData';
export const IdsFields = [
  'basis',
  'categories',
  'companies',
  'company_types',
  'countries',
  'cultures',
  'origins',
  'packaging_options',
  'port_options',
] as const;
export const StringFields = ['shipping_date_from', 'shipping_date_to', 'search', 'status', 'type'] as const;
const PaginationFields = ['limit', 'offset'] as const;

export type IFilterInitialState =
  Record<typeof IdsFields[number], number[]>
    & Partial<Record<typeof StringFields[number], string> >
    & Partial<Record<typeof PaginationFields[number], number | string> >
    & { type?: LotType };

export type TFilterActionFn = <T extends keyof IFilterInitialState>( args: { key: T; value: IFilterInitialState[T] } ) => void;

const initialState: IFilterInitialState = {
  basis: [],
  categories: [],
  companies: [],
  company_types: [],
  countries: [],
  cultures: [],
  origins: [],
  port_options: [],
  packaging_options: [],
  shipping_date_from: '',
  shipping_date_to: '',
  search: undefined,
  limit: undefined,
  offset: undefined,
};

type ActionKeyValue = {
  payload: {
    key: keyof IFilterInitialState,
    value: IFilterInitialState[keyof IFilterInitialState],
  },
}

export const {
  reducer,
  actions: {
    initFilterData,
    updateFilter,
    toggleFilterItem,
    resetFilter,
    resetFilterData,
  },
} = createSlice({
  name,
  initialState,
  reducers: {
    initFilterData: (state, action: { payload: ParsedQuery }) => _init(action.payload || {}),
    updateFilter: (state, action: ActionKeyValue) => {
      Notificate.standard(); state[action.payload.key] = action.payload.value as TodoAny;
    },
    toggleFilterItem: (state, { payload: { key, value } }: ActionKeyValue) => {
      const values = state[key];
      const strValue = value?.toString();
      if (Array.isArray(values)) {
        const num = +(strValue || 0);
        values.includes(num) ? state[key] = values.filter(item => item !== num) as TodoAny : values.push(num) as TodoAny;
      } else {
        state[key] = values == strValue ? null : strValue as TodoAny;
      }
      Notificate.standard();
    },
    resetFilter: (state, action: { payload: { key: keyof IFilterInitialState } }) => {
      Notificate.reset();
      state[action.payload.key] = initialState[action.payload.key] as TodoAny;
    },
    resetFilterData: () => { Notificate.reset(); return initialState; },
  },
});

export default reducer;


function _init(query: ParsedQuery): IFilterInitialState {
  const [dateStart, dateEnd] = query.date_range?.toString()?.split('_') || ['', ''];
  const state = {
    type: query.type,
    shipping_date_from: dateStart,
    shipping_date_to: dateEnd,
    search: query.search,
    status: query.status,
    limit: query.limit,
    offset: query.offset,
  } as IFilterInitialState;
  IdsFields.forEach((key) => {
    state[key] = [query[key]].flat().filter(Boolean).map(id => parseInt(`${id}`));
  });
  return state;
}

export const filtersData = (state: RootState) => state[name];
export const filtersDataByKey = (key: keyof IFilterInitialState) => (state: RootState) => state[name][key];
