export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const LOAD_MORE_NOTIFICATIONS = 'LOAD_MORE_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const GET_UNREAD_COUNT_NOTIFICATIONS_SUCCESS = 'GET_UNREAD_COUNT_NOTIFICATIONS_SUCCESS';
export const CHANGE_NOTIFICATIONS_CHAT_MESSAGES_COUNT = 'CHANGE_NOTIFICATIONS_CHAT_MESSAGES_COUNT';

const getNotificationsSuccess = ({ page, count, next, results }: { page: number, count: number, next: null | string, results: unknown[] }) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: {
    page,
    count,
    next,
    list: results,
  },
});

const getNotificationsStarted = () => ({
  type: GET_NOTIFICATIONS_START,
});

const getNotificationsFailure = (error: string) => ({
  type: GET_NOTIFICATIONS_FAILURE,
  payload: {
    error,
  },
});

const loadMoreNotifications = ({ page, count, next, results }: { page: number; count: number; next: null | string; results: unknown[]}) => ({
  type: LOAD_MORE_NOTIFICATIONS,
  payload: {
    page,
    count,
    next,
    list: results,
  },
});

const getUnreadCountNotificationSuccess = (payload: { unread_notifications: number; unread_trade_message: number }) => ({
  type: GET_UNREAD_COUNT_NOTIFICATIONS_SUCCESS,
  payload,
});

const changeNotificationsChatMessagesCount = (payload: { increment?: number; absolute?: number }) => ({
  type: CHANGE_NOTIFICATIONS_CHAT_MESSAGES_COUNT,
  payload,
});

const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
});

export {
  getNotificationsSuccess,
  getNotificationsStarted,
  getNotificationsFailure,
  clearNotifications,
  loadMoreNotifications,
  getUnreadCountNotificationSuccess,
  changeNotificationsChatMessagesCount,
};
