import { createSlice } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import { FORMAT_CYCLE } from 'constants/general';
import { RootState } from 'store/store.types';

export { useTsSelector, useTsDispatch } from 'store/store.types';


const name = 'settings';

interface IInitialState {
  currentDateFormat: string;
  _currentDateIndex: number;
}

const initialState: IInitialState = {
  currentDateFormat: FORMAT_CYCLE[0],
  _currentDateIndex: 0,
};


export const {
  reducer,
  actions: {
    nextCurrentDateFormat,
  },
} = createSlice({
  name,
  initialState,
  reducers: {
    nextCurrentDateFormat: (state) => {
      state._currentDateIndex = ++state._currentDateIndex % FORMAT_CYCLE.length;
      state.currentDateFormat = FORMAT_CYCLE[state._currentDateIndex];
    },
  },
});

export default reducer;

export const formateDate = (date: string | Date | Moment) => (state: RootState): string => {
  const currentDateFormat = state.settings.currentDateFormat;
  return currentDateFormat === 'fromNow'
    ? moment(date).fromNow()
    : moment(date).format(currentDateFormat);
};
