export const SCOPE_FORMS_ERROR = ['forms', 'errors'];

export const uk = {
  not_all_correct: 'Не всі поля коректно введені',
  mixed: {
    requred: 'Обов\'язкове поле',
  },
  string: {
    max: 'Кількість символів не може перевищувати {{max}}',
    min: 'Кількість символів не меньше {{min}}',
    email: 'Невірно введений e-mail',
    emailOrPhone: 'Невірний e-mail чи телефон',
    phone: 'Неправильний формат (+380509999999)',
  },
  number: {
    max: 'Має бути менше {{max}}',
    min: 'Має бути більше {{min}}',
    moreThan: 'Має бути більше {{more}}',
  },
};

export const en: typeof uk = {
  not_all_correct: 'Not all fields are entered correctly',
  mixed: {
    requred: 'Required field',
  },
  string: {
    max: 'The number of characters cannot exceed {{max}}',
    min: 'The number of characters cannot be less {{min}}',
    phone: 'Wrong format (+380509999999)',
    emailOrPhone: 'Wrong email/phone format',
    email: 'Wrong email address',
  },
  number: {
    max: 'Must be less than {{max}}',
    min: 'Must be more than {{min}}',
    moreThan: 'Must be more than {{more}}',
  },
};

export const ru: typeof uk = {
  not_all_correct: 'Не все поля корректно введены',
  mixed: {
    requred: 'Обязательное поле',
  },
  string: {
    max: 'Количество символов не может превышать {{max}}',
    min: 'Количество символов не меньше {{min}}',
    email: 'Неверно введен e-mail',
    emailOrPhone: 'Неверный формат e-mail или телефона',
    phone: 'Неправильный формат (+380509999999)',
  },
  number: {
    max: 'Должно быть меньше {{max}}',
    min: 'Должно быть больше {{min}}',
    moreThan: 'Должно быть больше {{more}}',
  },
};

export const bg: typeof uk = {
  not_all_correct: 'Не всички полета са въведени правилно',
  mixed: {
    requred: 'Задължително поле',
  },
  string: {
    max: 'Броят на символите не може да надвишава {{max}}',
    min: 'Броят на символите не може да бъде по-малък от {{min}}',
    email: 'Невалиден имейл адрес',
    emailOrPhone: 'Невалиден формат на имейл или телефон',
    phone: 'Невалиден формат (+380509999999)',
  },
  number: {
    max: 'Трябва да бъде по-малко от {{max}}',
    min: 'Трябва да бъде повече от {{min}}',
    moreThan: 'Трябва да бъде повече от {{more}}',
  },
};
