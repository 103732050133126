import React from 'react';
import { ROUTES } from 'nav';
import { Redirect } from 'react-router';
import { AnyTODO } from 'core/interfaces/AnyTODO.type';

export const Redirects = buildProxy();

interface Props {
  on?: boolean;
}

interface IRoutes {
  [key: string]: string | IRoutes;
}

type RecursiveProxe<T extends IRoutes> = {
  readonly [P in keyof T]:
    T[P] extends string
      ? React.FC<Props>
      : T[P] extends IRoutes ?
        RecursiveProxe<T[P]>
        : never
}


function buildProxy(): RecursiveProxe<typeof ROUTES> {
  const handler = {
    get(target: IRoutes, p: string): RecursiveProxe<IRoutes> | React.FC<Props> | never {
      if (p in target) {
        const value = target[p];
        if (typeof value === 'string') {
          return function RedirectsC({ on = true }) {
            return on ? <Redirect to={value} /> : null;
          };
        }
        return new Proxy(value, handler) as RecursiveProxe<IRoutes>;
      }
      throw Error('bad proxy');
    },
  };

  return new Proxy(ROUTES, handler) as AnyTODO as RecursiveProxe<typeof ROUTES>;
}
