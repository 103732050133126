import store from 'store/store';
import Nav, { ROUTES } from 'nav';
import i18n from 'i18n';
import { ITrade } from 'api/trades.api';
import { IBasis, ICategory, ICountry, ICulture, ICurrency, IPackaging, IShipping } from 'reducers/allReducer';
import { FormatEnum, TradeStatus } from 'constants/general';
import { DateTimeHelper } from 'core/helpers/dateTime.helper';
import { formatWithThousands } from 'utils/formatThousands';
import { LotBase } from 'models/lotBase';
import { IRequest } from 'reducers/oneRequestReducer';
import { IOffer } from 'reducers/oneOfferReducer';
import { SCOPE_LOT } from 'i18n/_single/Lot';

export type { ITrade };

export enum InitiatorTypeEnum {
  opened = 'opened',
  got = 'got',
}

export class Trade {
  private _store!: ReturnType<typeof store['getState']>;
  constructor (
    public $: ITrade,
    public companyId: string = '',
  ) {}

  get id() { return this.$.id; }

  goToPage(replace = false): void {
    const path = `${ROUTES.chat}?tradeId=${this.$.id}`;
    Nav.go(path, { replace, state: { trade: this } });
  }

  validUntil(format?: FormatEnum): string {
    return DateTimeHelper.format(this.$.valid_until, format);
  }

  get basis(): IBasis {
    return this.store.all.basis.list[this.$.basis_id];
  }
  get category(): ICategory {
    return this.store.all.categories.list[this.culture.category_id];
  }
  get culture(): ICulture {
    const culture_id = this.$.culture_id || this.$.offer?.culture_id || this.$.request?.culture_id;
    return this.store.all.cultures.list[culture_id!];
  }
  get currency(): ICurrency {
    return this.store.all.currencies.list[this.$.currency_id];
  }
  get origin(): ICountry {
    const origin_id = this.$.origin_id || this.$.offer?.origin_id || this.$.request?.origin_id;
    return this.store.all.origins.list[origin_id!];
  }
  get packaging(): IPackaging {
    return this.store.all.packaging_options.list[this.$.packaging_id];
  }
  get shipping(): IShipping {
    return this.store.all.shipping_options.list[this.$.shipping_id];
  }
  get myLot(): LotBase {
    return new LotBase(this.isMyOffer ? this.$.offer : this.$.request);
  }
  get oppositeLot(): LotBase {
    return new LotBase(this.oppositeLot$);
  }
  get oppositeLot$(): IRequest | IOffer {
    return this.isMyOffer ? this.$.request : this.$.offer;
  }
  get companyName(): string {
    return this.oppositeLot.company.name;
  }
  get openType(): InitiatorTypeEnum {
    return this.$[this.$.initiator].is_my_company
      ? InitiatorTypeEnum.opened
      : InitiatorTypeEnum.got;
  }
  get createdAt(): string {
    return DateTimeHelper.format(this.$.created_at);
  }
  get currentLotSide(): LotBase {
    return new LotBase(this.$[this.$.last_bid_side]);
  }
  get isMyCurrentSide(): boolean {
    return !!this.currentLotSide.$.is_my;
  }

  get formatedPrice(): string {
    if (!('price' in this.$)) {
      return '';
    }
    return this.$.price
      ? `${formatWithThousands(this.$.price, 0)} ${this.currency.iso_code}`
      : i18n.t(`${SCOPE_LOT}$zeroPrice`);
  }

  quickParams(): string {
    return [
      formatWithThousands(this.$.volume),
      this.$.volume && i18n.t('т'),
      this.formatedPrice,
      this.basis.name,
    ].filter(Boolean).join(' ');
  }

  quickSecondParams(): string {
    return [
      this.packaging.name,
      i18n.t('в'),
      this.shipping.name,
    ].join(' ');
  }

  paramsWIthoutPrice(): string {
    return [
      formatWithThousands(this.$.volume),
      this.$.volume && i18n.t('т'),
      this.basis.name,
      this.packaging.name,
      i18n.t('в'),
      this.shipping.name,
    ].join(' ');
  }

  sideStatus(isMy = false): string {
    if (this.isClosed && (isMy === this.isMyCurrentSide)) {
      return 'closed';
    }
    if (this.isConfirmed) {
      return isMy === this.myLot.isOffer() ? 'hardOffer' : 'hardRequest';
    }
    if (!this.$.price) {
      return 'nothing';
    }
    if (isMy === this.isMyCurrentSide) {
      return this.currentLotSide.isOffer()
        ? this.isConfirming
          ? 'hardOffer'
          : 'softOffer'
        : this.isConfirming
          ? 'hardRequest'
          : 'softRequest';
    }
    return 'nothing';
  }


  get isCompany(): boolean {
    return !!this.oppositeLot.company.id;
  }
  get isConfirming(): boolean {
    return this.$.status === TradeStatus.confirming;
  }
  get isConfirmed(): boolean {
    return this.$.status === TradeStatus.confirmed;
  }
  get hasMyConfirming(): boolean {
    return this.$.offer.is_my ? this.$.seller_confirmation : this.$.buyer_confirmation;
  }
  get isValid(): boolean {
    return DateTimeHelper.isFuture(this.$.valid_until);
  }
  get isMy(): boolean {
    return this.$.is_my;
  }
  get isMyCompany(): boolean {
    return this.$.is_my_company;
  }
  get isMyConfirm(): boolean {
    return this.isMyOffer
      ? this.$.seller_confirmation
      : this.$.buyer_confirmation;
  }
  get isOppositConfirm(): boolean {
    return this.isMyOffer
      ? this.$.buyer_confirmation
      : this.$.seller_confirmation;
  }

  get isEnded(): boolean {
    return [TradeStatus.confirmed, TradeStatus.closed].includes(this.$.status);
  }
  get isOpen(): boolean {
    return this.$.status === TradeStatus.open;
  }
  get isClosed(): boolean {
    return this.$.status === TradeStatus.closed;
  }
  get canMessage(): boolean {
    return [TradeStatus.confirming, TradeStatus.open, TradeStatus.confirmed].includes(this.$.status);
  }
  get canBid(): boolean {
    return this.isOpen || (!this.isClosed && !this.isMyConfirm);
  }
  get canConfirm(): boolean {
    if (!this.$.price && this.$.price <= 0) {
      return false;
    }
    return (
      this.$.status === TradeStatus.confirming && !this.isMyConfirm
    ) || (
      this.$.status === TradeStatus.open && !this.currentLotSide.isMy
    );
  }

  private get isMyOffer(): boolean {
    return !!this.$.offer.is_my_company;
  }

  private get store() {
    return this._store ? this._store : this._store = store.getState();
  }
}
