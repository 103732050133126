export const SCOPE_MY_COMPANY_INFO = 'MyCompanyInfo';

export const uk = {
  title: 'Інформація',
  Fields: {
    fotos: {
      title: 'Фото',
      info: 'Завантажте фото виробничих потужностей, складів, ліній, силосів',
    },
    files: {
      title: 'Документи',
      info: 'Розділ для завантаження сертифікатів (ISO, HACCP, HALAL, Global GAP, BRC тощо)',
    },
    show_cabinet: {
      label: 'Показувати всім',
      tooltip: 'Показувати картку компанії всім або лише зазначеним рукостисканням',
    },
  },
};

export const en: typeof uk = {
  title: 'Information',
  Fields: {
    fotos: {
      title: 'Photo',
      info: 'Download photos of production facilities, warehouses, lines, silos',
    },
    files: {
      title: 'Documents',
      info: 'Section for downloading certificates (ISO, HACCP, HALAL, Global GAP, BRC, etc.)',
    },
    show_cabinet: {
      label: 'Show to all',
      tooltip: 'Show company card to all or only to those marked with a handshake',
    },
  },
};

export const ru: typeof uk = {
  title: 'Информация',
  Fields: {
    fotos: {
      title: 'Фото',
      info: 'Загрузите фото производственных мощностей, складов, линий, силосов',
    },
    files: {
      title: 'Документы',
      info: 'Раздел для загрузки сертификатов (ISO, HACCP, HALAL, Global GAP, BRC и т.д.)',
    },
    show_cabinet: {
      label: 'Показывать всем',
      tooltip: 'Показывать карточку компании всем или только отмеченным рукопожатием',
    },
  },
};

export const bg: typeof uk = {
  title: 'Информация',
  Fields: {
    fotos: {
      title: 'Снимка',
      info: 'Изтегляне на снимки на производствени съоръжения, складове, линии, силози',
    },
    files: {
      title: 'Документи',
      info: 'Секция за изтегляне на сертификати (ISO, HACCP, HALAL, Global GAP, BRC и др.)',
    },
    show_cabinet: {
      label: 'Покажи на всички',
      tooltip: 'Показване на фирмена карта на всички или само маркирано ръкостискане',
    },
  },
};
