import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, AnyAction, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import store from 'store/store';

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useTsDispatch = () => useDispatch<ThunkAppDispatch>();
// export const useTsDispatch = () => useDispatch<AppDispatch>();
export const useTsSelector: TypedUseSelectorHook<RootState> = useSelector;

export type TsThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>
