import React from 'react';
import { SvgHandshakeIcon } from 'components/Icons';
import { HandshakeFilterEnum } from '../HandshakeFilter';
import { classes } from 'utils';
import { useRenderEverySeconds } from 'hooks';
import { CompanyHandshake, CompanyHandshakeSubStatus, ICompanyHandshake } from 'models/CompanyHandshake';
import './style.sass';

interface Props {
  handshake: ICompanyHandshake;
}

export const HandshakeIcon: React.FC<Props> = ({ handshake }) => {
  useRenderEverySeconds(60);
  const handshakeModel = new CompanyHandshake(handshake);
  const circle = handshakeModel.subStatus;
  const active = handshakeModel.on;
  return (
    <div
      className={classes('HandshakeIcon', { circle, active })}
      style={{'--HandshakeIcon__icon_percent': `${handshakeModel.percentElapcedToNextAction}%`} as React.CSSProperties}
    >
      <SvgHandshakeIcon className="HandshakeIcon__icon" />
    </div>
  );
};

interface HandshakeFilterIconProps {
  type: HandshakeFilterEnum;
}

export const HandshakeFilterIcon: React.FC<HandshakeFilterIconProps> = ({ type }) => {
  const circle = type == HandshakeFilterEnum.my
    ? CompanyHandshakeSubStatus.my
    : type == HandshakeFilterEnum.target
      ? CompanyHandshakeSubStatus.opposite
      : CompanyHandshakeSubStatus.none;
  const active = type == HandshakeFilterEnum.full;
  return (
    <div
      className={classes('HandshakeIcon', { circle, active })}
      style={{'--HandshakeIcon__icon_percent': '0%'} as React.CSSProperties}
    >
      <SvgHandshakeIcon className="HandshakeIcon__icon" />
    </div>
  );
};
