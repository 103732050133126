import { LOADING, SET, DELETE, FAILED } from 'actions/inviteActions';
import { factoryInitState } from './slices/helpers';
import { IInitState } from './slices/interfaces';

export interface IInvite {
  company_invite_token: string | null;
  company: string;
  email: string | null;
  is_active: boolean;
  token?: string; // for response
}

interface ActionSet {
  type: typeof SET;
  payload: IInvite;
}
interface ActionLoading {
  type: typeof LOADING;
  payload: boolean;
}
interface ActionDelete {
  type: typeof DELETE;
  payload: unknown;
}
interface ActionFailed {
  type: typeof FAILED;
  payload: string;
}

type Action = ActionSet | ActionLoading | ActionDelete | ActionFailed;


const initialState: IInitState<IInvite> = factoryInitState<IInvite>({
  company_invite_token: null,
  company: '',
  email: null,
  is_active: false,
});


export default function inviteReducer(state = initialState, action: Action): IInitState<IInvite> {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };
    case SET:
      return {
        ...state,
        company_invite_token: action.payload?.token || '',
        company: action.payload.company,
        email: action.payload.email,
        is_active: action.payload.is_active,
        error: null,
      };
    case DELETE:
      return initialState;
    case FAILED:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
