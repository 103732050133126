import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export { useTsSelector, useTsDispatch } from 'store/store.types';

interface IState {
  show: boolean;
  title?: string;
  text: string;
}

const initialState: IState = {
  show: false, text: '',
};

const NAME = 'globalAlert';


export const {
  reducer,
  actions: {
    hideGlobalAlert,
    showGlobalAlert,
  },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
    hideGlobalAlert: (state) => { state.show = false; },
    showGlobalAlert: (_state, action: PayloadAction<Omit<IState, 'show'>>) => ({ show: true, ...action.payload }),
  },
});

export default reducer;
