import { InfoPopupEnum } from 'hooks/useInfoPopup';
import { SET_INFO_POPUP, SHOW_INFO_POPUP } from '../actions/infoPopupAction';

interface IInfoPopup {
  type: InfoPopupEnum;
  show: boolean;
  message: string;
  to: string;
}

interface Action {
  type: typeof SET_INFO_POPUP | typeof SHOW_INFO_POPUP;
  payload: IInfoPopup;
}

const initialState: IInfoPopup = {
  show: false,
  type: InfoPopupEnum.black,
  message: '',
  to: '',
};

export default function infoPopupReducer(state = initialState, action: Action): IInfoPopup {
  switch (action.type) {
    case SHOW_INFO_POPUP:
      return { ...state, show: action.payload.show };
    case SET_INFO_POPUP:
      return {
        ...state,
        show: true,
        type: action.payload.type,
        message: action.payload.message,
        to: action.payload.to,
      };
    default:
      return state;
  }
}
