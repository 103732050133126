import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgDownloadIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <SvgIconContainer classes={className}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2L12 14M12 14L16 10.625M12 14L8 10.625" stroke="#2A3EA7" strokeWidth="2" />
        <path
          d="M3 13V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V13"
          stroke="#2A3EA7"
          strokeWidth="2"
        />
      </svg>
    </SvgIconContainer>
  );
};
