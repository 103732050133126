import { TypesLabel } from 'components/RequestLabel';

export const SCOPE_REQUEST_LABEL = 'RequestLabel';

export const uk = {
  [TypesLabel.request]: 'Покупка',
  [TypesLabel.offer]: 'Продаж',
  [TypesLabel.trade]: 'Торг',
  public: {
    true: 'ПБ',
    false: 'ПТ',
    tooltips: {
      true: 'Публічна заявка',
      false: 'Приватна заявка',
    },
  },
};

export const en: typeof uk = {
  [TypesLabel.request]: 'Buy',
  [TypesLabel.offer]: 'Sell',
  [TypesLabel.trade]: 'Trade',
  public: {
    true: 'PC',
    false: 'PT',
    tooltips: {
      true: 'Public lot',
      false: 'Private lot',
    },
  },
};

export const ru: typeof uk = {
  [TypesLabel.request]: 'Покупка',
  [TypesLabel.offer]: 'Продажа',
  [TypesLabel.trade]: 'Торг',
  public: {
    true: 'ПБ',
    false: 'ПТ',
    tooltips: {
      true: 'Публичная заявка',
      false: 'Приватная заявка',
    },
  },
};

export const bg: typeof uk = {
  [TypesLabel.request]: 'Покупка',
  [TypesLabel.offer]: 'Продажба',
  [TypesLabel.trade]: 'Търг',
  public: {
    true: 'ПБ',
    false: 'ЧТ',
    tooltips: {
      true: 'Публична заявка',
      false: 'Частна заявка',
    },
  },
};
