export const GET_LOTS_SUCCESS = 'GET_LOTS_SUCCESS';
export const SET_LOADING = 'SET_LOTS_LOADING';
export const SET_APPENDING = 'SET_LOTS_APPENDING';

const getSuccess = payload => {
  return {
    type: GET_LOTS_SUCCESS,
    payload,
  };
};

const setLoading = loading => ({
  type: SET_LOADING,
  loading,
});

const setAppending = appending => ({
  type: SET_APPENDING,
  appending,
});

export {
  getSuccess,
  setLoading,
  setAppending,
};
