import { useMemo } from 'react';
import { AxiosError } from 'axios';
import Logger from 'modules/Logger';
import { setInfoPopup } from 'actions/infoPopupAction';
import { useTsDispatch } from 'store/store.types';
import { TArgs, useT } from './useT';

export enum InfoPopupEnum {
  success = 'success',
  black = 'black',
  error = 'error',
  info = 'info',
  link = 'link',
}
type Style = 'success' | 'black' | 'error' | 'info' | 'link';

const convertApiFormErrorsToString = (errors: {} | string): string => {
  if (typeof errors === 'string') {
    return errors;
  }
  return Object.entries(errors)
    .map(([field, value]) => `${field}: ${value}`)
    .join('\n');
};

export function useInfoPopup(scope = '') {
  const dispatch = useTsDispatch();
  const _t = useT(scope);
  const send = function (type: InfoPopupEnum, text: string | string[], args?: TArgs) {
    dispatch(setInfoPopup(type, _t(text, args), args?.to));
  };
  function styled(style: Style, text: string, to?: string): void {
    dispatch(setInfoPopup(style as InfoPopupEnum, text, to));
  }
  return useMemo(() => ({
    send,
    success: (text: string | string[], args?: TArgs) => send(InfoPopupEnum.success, text, args),
    black: (text: string | string[], args?: TArgs) => send(InfoPopupEnum.black, text, args),
    error: (text: string | string[], args?: TArgs) => send(InfoPopupEnum.error, text, args),
    info: (text: string | string[], args?: TArgs) => send(InfoPopupEnum.info, text, args),
    link: (text: string | string[], to: string, args?: TArgs) => dispatch(setInfoPopup(InfoPopupEnum.link, _t(text, args), to)),
    styled,
    apiErrors: (error?: AxiosError | unknown) => {
      Logger.error(error);
      const message: string = !isAxiosError(error) || !error?.response?.data || error.response.status === 500 || !error.response.status
        ? _t.common('somethingsWrong')
        : convertApiFormErrorsToString(error.response?.data?.error || error.response?.data || error.response?.statusText);
      dispatch(setInfoPopup(InfoPopupEnum.error, message));
    },
    socketErrors: ({ error }: { error: string | {} }) => {
      Logger.error(error);
      const message: string = typeof error === 'string'
        ? error
        : convertApiFormErrorsToString(error);
      dispatch(setInfoPopup(InfoPopupEnum.error, message));
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [_t]);
}


export function isAxiosError(object: unknown): object is AxiosError {
  return Object.prototype.hasOwnProperty.call(object, 'response');
}
