import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, API_ROUTES } from 'modules/Api';
import { RootState } from 'store/store.types';
import { FactoryBuilder, factoryInitState, factorySetAction } from '../slices/helpers';
import { IAdditionalApiStatuses } from '../slices/interfaces';
import { userIdSelector } from './user.slice';

export { useTsSelector } from 'store/store.types';

interface IUserSettings {
  about_soon_emails: boolean,
  about_soon_notifications: boolean,
  my_company_emails: boolean,
  my_company_notifications: boolean,
  new_proposal_emails: boolean,
  new_proposal_notifications: boolean,
  trades_emails: boolean,
  trades_notifications: boolean,
}

const initialData: IUserSettings = {
  about_soon_emails: true,
  about_soon_notifications: true,
  my_company_emails: true,
  my_company_notifications: true,
  new_proposal_emails: true,
  new_proposal_notifications: true,
  trades_emails: true,
  trades_notifications: true,
};
const initialState: IUserSettings & IAdditionalApiStatuses = factoryInitState(initialData);


export const NAME = 'settings';
const parent = 'profile';
const scope = `${parent}/${NAME}`;

export const updateUserSettings  = createAsyncThunk(
  `${scope}/update`,
  async (data: IUserSettings, thunkApi): Promise<IUserSettings> => {
    const id = userIdSelector(thunkApi.getState() as RootState);
    await API.patch(`${API_ROUTES.users.root}${id}/settings/`, data);
    return data;
  },
);

export const profileUserSettingsSelector = (state: RootState): typeof initialState => state[parent][NAME];


export const {
  reducer,
  actions: {
    setUserSettings,
    resetUserSettings,
  },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setUserSettings: (state, payload) => factorySetAction<IUserSettings>(state, payload),
    resetUserSettings: () => ({ ...initialState }),
  },
  extraReducers: builder => new FactoryBuilder(builder).buildAction(updateUserSettings),
});

export default reducer;
