import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgCompanyIcon: React.FC<{ className?: string; }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 5.98885C1 5.38281 1.3424 4.82879 1.88446 4.55776L8.28446 1.35776C8.7349 1.13254 9.2651 1.13254 9.71554 1.35776L16.1155 4.55776C16.6576 4.82879 17 5.38281 17 5.98885V17H1V5.98885Z"
          stroke="var(--gray800)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 17V14C12 12.3431 10.6569 11 9 11V11C7.34315 11 6 12.3431 6 14V17"
          stroke="var(--gray800)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8 6.99999H10" stroke="var(--gray800)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </SvgIconContainer>
  );
};
