import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, API_ROUTES } from 'modules/Api';
import { toApiQuery } from 'api/utils';
import { LotType } from 'constants/general';
import { IInitState } from './interfaces';
import { AnyTODO } from 'core/interfaces';

export const LABELS = ['active', 'closed', 'completed', 'all'] as const;

type DataType = Record<typeof LABELS[number], number>;

interface IUserDashboard {
  all: DataType;
  my: DataType;
  other: DataType;
}

const initialState: IInitState<IUserDashboard> = {
  all: {
    active: 0,
    closed: 0,
    completed: 0,
    all: 0,
  },
  my: {
    active: 0,
    closed: 0,
    completed: 0,
    all: 0,
  },
  other: {
    active: 0,
    closed: 0,
    completed: 0,
    all: 0,
  },
  loading: true,
  loaded: false,
  error: null,
};
const name = 'userDashboard';

export const getUserDashboard = createAsyncThunk(
  `${name}/getUserDashboard`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ type = LotType.request, owner, status, grouping, ...params }: Record<string, AnyTODO>) => {
    const queryString = toApiQuery(params);
    const { data } = await API.get(`${API_ROUTES.userDashboard[type as LotType]}?${queryString}`);
    // const { data } = await ApiCached.get(`${API_ROUTES.userDashboard[type]}?${queryString}`);
    return data;
  },
);

export const {
  reducer,
  // actions: { },
} = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => builder
    .addCase(getUserDashboard.pending, state => { state.loading = true; })
    .addCase(getUserDashboard.rejected, (state, action) => { state.error = action.error?.message; })
    .addCase(getUserDashboard.fulfilled, (state, action) => ({
      ...state,
      ...{ other: calculateOther(action.payload) },
      loading: false,
      loaded: true,
      ...action.payload,
    })),
});

export default reducer;

function calculateOther({ my, all }: IUserDashboard): Partial<DataType> {
  return LABELS.reduce<Partial<DataType>>((acc, id) => (acc[id] = all[id] - my[id], acc), {});
}
