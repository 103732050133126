import {
  GET_CULTURES_LIST_START,
  GET_CULTURES_LIST_SUCCESS,
} from 'actions/culturesActions';
import { ICulture } from './allReducer';
import Factory, { IListState } from './utils/FactoryLots';

interface ActionSuccses {
  type: typeof GET_CULTURES_LIST_SUCCESS;
  payload: Pick<IListState<ICulture>, 'page' | 'count' | 'next' | 'overalls' | 'list'>;
  append: boolean;
}
interface ActionLoading {
  type: typeof GET_CULTURES_LIST_START;
  append: boolean;
}

type Action = ActionSuccses | ActionLoading;

const initialState = Factory.initialState<ICulture>();


export default function culturesReducer(state = initialState, action: Action): IListState<ICulture> {
  switch (action.type) {
    case GET_CULTURES_LIST_START:
      return {
        ...state,
        loading: !action.append,
        appending: action.append,
      };
    case GET_CULTURES_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        appending: false,
        error: null,
        list: action.append ? [...state.list, ...action.payload.list] : action.payload.list,
      };
    default:
      return state;
  }
}
