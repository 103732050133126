import React from 'react';
import SvgIconContainer from '../SvgIconContainer';
import { IconProps } from '.';

export const SvgCustomSubscription: React.FC<IconProps> = ({ className, width = 30, height = 36 }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width={width} height={height} viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0C8.28427 0 15 6.71573 15 15V35.4545C6.71573 35.4545 0 28.7388 0 20.4545V0Z" fill="#AAB2DC"/>
        <path d="M0 14.7725C8.28427 14.7725 15 21.4882 15 29.7725V35.4543C6.71573 35.4543 0 28.7385 0 20.4543V14.7725Z" fill="#5D6CBC"/>
        <path d="M15 15C15 6.71573 21.7157 0 30 0V20.4545C30 28.7388 23.2843 35.4545 15 35.4545V15Z" fill="#2A3EA7"/>
      </svg>
    </SvgIconContainer>
  );
};
