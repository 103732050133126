const BASE = 'DASHBOARD';
export const TYPES = {
  GET: `GET_${BASE}`,
  FAILED: `FAILED_${BASE}`,
  LOADING: `LOADING_${BASE}`,
  BASE_KEY: `BASE_KEY_${BASE}`,
};

const getSuccess = (payload, isBase = false) => ({
  type: TYPES.GET,
  payload,
  isBase,
});

const setLoading = (loading, isBase = false) => ({
  type: TYPES.LOADING,
  payload: loading,
  isBase,
});

const setFailed = (failed, isBase = false) => ({
  type: TYPES.FAILED,
  payload: failed,
  isBase,
});

const setBaseKey = key => ({
  type: TYPES.BASE_KEY,
  payload: key,
});

export {
  getSuccess,
  setLoading,
  setFailed,
  setBaseKey,
};
