import { LandingPageEnum } from 'modules/Landing/Pages/interfaces';

export const SCOPE_LANDING = 'Landing';

export const uk = {
  Header: {
    trade: 'Торгівля',
    solutions: 'Рішення',
    support: 'Підтримка',
    DropDownMenu: {
      farmers: 'Фермерам',
      brokers: 'Посередникам',
      customers: 'Покупцям',
      contacts: 'Контакти',
      about_us: 'Про нас',
    },
  },
  Start: {
    title: 'Перший український \n агромаркетплейс',
    subtitle: 'З Гленділ ваша продукція досягне будь-якої країни світу. \n Експортуйте легко та безпечно!',
    buttons: {
      trade: 'Почати торгівлю',
      help: 'Допомога консультанта',
    },
  },
  Ticker: {
    title: 'Торгують зараз',
  },
  HowItWorks: {
    title: 'Як користуватись платформою',
    subtitle: {
      first: 'Вам потрібно пройти',
      accent: 'всього 3 кроки,',
      last: 'щоб знайти покупця за кордоном та продати сільськогосподарську продукцію на вигідних умовах.',
    },
    steps: {
      one: 'Створіть безкоштовний акаунт та опублікуйте заявку',
      two: 'Беріть участь в торгівлі онлайн',
      three: 'Отримайте документ про підтвердження угоди',
    },
    buttons: {
      trade: 'Почати торгівлю',
      help: 'Консультація',
    },
  },
  Home: {
    StartNow: {
      title: 'Почати торги прямо зараз',
      subtitle: 'Зареєструйтеся та розмістіть заявку, беріть участь в торгах і укладайте вигідні контракти.',
      buttons: {
        start: 'Почати торгувати',
      },
    },
  },
  About: {
    AboutMission: {
      title: {
        start: 'Наша місія',
        text: 'підтримати українські фермерські господарства і виробників та діджиталізувати взаємовідносини на агроринку.',
      },
      subtitle: `Ми будуємо екосистему, яка дозволить кожному гравцю на ринку досягти максимального економічного результату: фермерам та виробникам, посередникам та брокерам, покупцям та торговельним компаніям.
      `,
      column_one: {
        text_one: `Наш досвід та увага сконцентрована на виробниках та фермерських господарствах України, щоб
          поділитися своїми знаннями та навичками роботи проведених на міжнародному ринку. Маркетплейс
          Гленділ надає можливість продати онлайн агропродукцію за кордон, так само легко та безпечно,
          як і на місцевому ринку. Україна вже відома такими своїми цифровими продуктами як Дія та
          ПриватБанк/Монобанк і ми зараз створюємо такий сучасний інструмент для агросфери.
        `,
        text_two: `Завдяки внутрішньому комплаєнсу та нашим партнерам, всі наші користувачі проходять обов’язкову
          верифікацію. Система відгуків та рейтингів дозволяє співпрацювати лише з перевіреними та
          сумлінними контрагентами. Перевірка сертифікованими лабораторіями обсягів продукції
          виробників дозволить покупцям бути впевненими у належній якості та обсязі продукції яку купують.
        `,
      },
      column_two: {
        text_one: `Ми пропонуємо велику кількість інструментів, які зроблять експорт зручним та безпечним:
          внутрішній месенджер для прямого спілкування з контрагентами, зручна система повідомлень
          не дозволить пропустити жодної важливої події і у разі успішної угоди на пошту ви
          отримаєте бізнес підтвердження для подальшого укладення контракту. Наші юристи дозволять
          вирішити будь-які суперечки найбільш швидко та безболісно для кожної із сторін.
        `,
        text_two: `Приєднуйтесь до нашої платформи та відкривайте для себе торгівлю з нового боку, де купувати
          та продати стало набагато простіше, цікавіше та надійніше.
        `,
      },
      olexandr: 'Олександр Груєв',
    },
    AboutHistory: {
      title: 'Історія створення Гленділ',
      subtitle: 'Гленділ - український маркетплейс, який став результатом накопиченого 15-річного досвіду роботи на фізичному ринку трейдингу, логістики,\u00A0фермерства та брокерства.',
    },
    StartNow: {
      title: 'Приєднуйтесь до нашої платформи',
      subtitle: 'Відкрийте для себе торгівлю з нового боку, де купувати та продати ставати простіше, цікавіше та надійніше.',
      buttons: {
        start: 'Почати торгувати',
      },
    },
  },
  Solving: {
    title: 'Наші рішення',
    subtitle: 'Ми прагнемо покращити агроринок за допомогою нового покоління цифрових і фінансових рішень, які спеціально розроблені для кожного учасника ринку.',
    buttons: {
      details: 'Детальніше',
    },
    farmers: {
      subtitle: 'Фермерам та Виробникам',
      text: 'Продавайте агропродукцію покупцям з усього світу',
    },
    brokers: {
      subtitle: 'Посередникам та Брокерам',
      text: 'Контактуйте з найкращими фермерами України та покупцями з усього світу',
    },
    customers: {
      subtitle: 'Покупцям та Торгівельним компаніям',
      text: 'Купуйте агропродукцію у найкращих фермерів України',
    },
  },
  Services: {
    title: 'Наші додаткові сервіси',
    subtitle: 'Гленділ - ваш партнер у світі аграрного бізнесу.',
    buttons: {
      details: 'Детальніше',
    },
    quality: {
      title: 'Перевірка якості товару',
      text: 'Ми допомогаємо нашим фермерам пройти перевірку якості товару в лабораторії та проконтролювати її при відвантаженні.',
    },
    logistic: {
      title: 'Логістика',
      text: 'Наш відділ логістики безкоштовно прораховує вартість логістики у будь-яку точку світу. Та за потреби ми можемо забезпечити виконання усього логістичного процесу “під ключ”.',
    },
    finance: {
      title: 'Фінансування',
      text: 'Ви можете подати заявку на наш сервіс “Фінансування” і отримати фінансове страхування вашої угоди',
    },
    contracts: {
      title: 'Супровід контракту',
      text: 'Афільована компанія буде учасником договору і забезпечить укладення угоди з фермером, гарантуючи оплату, а покупцю - доставку в установлені строки',
    },
    denied_items: {
      title: 'Вантажи, від яких відмовились',
      text: 'Якщо ви опинились у ситуації, коли від вашого вантажу відмовляються, ми можемо допомогти знайти нового покупця.',
    },
  },
  Support: {
    support: {
      title: 'Підтримка',
      text: 'Якщо у вас виникли питання чи потрібна консультація, звертайтесь до нашої служби підтримки',
    },
    contract: {
      title: 'Виконання контрактів “Під\u00A0ключ”',
      text: 'Виконання контрактів під ключ з дотримання всіх норм міжнародного права з повною відповідальністю',
    },
  },
  Pricing: {
    title: 'Вартість послуг',
    valid_until: 'до 2024 року',
    subtitle: 'Ми пропонуємо скористатись всіма сервісами платформи Гленділ абсолютно безкоштовно.',
    free: 'Безкоштовно',
    columns: {
      registration: 'Реєстрація',
      create_lot: 'Розміщення запиту/лоту',
      trade: 'Доступ до торгівлі',
      analitics: 'Доступ до аналітики',
    },
    buttons: {
      registration: 'Зареєструватись',
    },
    farmers: {
      title: 'Фермерам',
    },
    brokers: {
      title: 'Посередникам',
    },
    customers: {
      title: 'Покупцям',
    },
  },
  Propositions: {
    title: 'Що ми пропонуємо',
    subtitle: 'Гленділ надає вам ефективні інструменти для торгівлі, доступ до пропозицій по всьому світу та підтримку на кожному етапі.',
    export: {
      title: 'Легкий експорт продукції',
      text: 'Ви отримуєте доступ до світового ринку актуальних пропозицій. Знаходьте покупців та продавайте урожай всього у декілька кліків.',
    },
    identification: {
      title: 'Ідентифікація учасників торгівлі',
      text: 'Ви можете бути впевнені, що на платформі знаходяться тільки верифіковані користувачі. Відгуки та рейтинги користувачів підвищують якість угод.',
    },
    logistic: {
      title: 'Розрахунок логістики',
      text: 'Швидкий розрахунок вартості доставки будь-яким видом транспорту. Детальна калькуляція доставки надасть вам оптимальну ціну під вашу угоду.',
    },
    security: {
      title: 'Надійність та безпечність',
      text: 'Перевірені учасники та налагоджений сервіс виконання контрактів гарантують успішність угоди. У разі виникнення непередбачуваних обставин, платформа допоможе з їх вирішенням.',
    },
    services: {
      title: 'Додаткові сервіси',
      text: 'Ми пропонуємо усі необхідні сервіси, які можуть знадобитись для заключення та виконання угод. Ми завжди готові підтримати вас на будь-якому етапі торгівлі.',
    },
    support: {
      title: 'Підтримка користувачів',
      text: 'Ми прагнемо надати кожному нашому користувачу найкращий сервіс. Якщо у вас є питання або вам потрібна допомога, ми завжди на зв’язку.',
    },
  },
  Footer: {
    columns: {
      services: 'Сервіси',
      insites: 'Інсайти',
      support: 'Підтримка',
      company: 'Компанія',
    },
    links: {
      trade: 'Торгівля',
      farmers: 'Фермерам',
      brokers: 'Брокерам',
      customers: 'Покупцям',
      blog: 'АгроБлог',
      partners: 'Партнери',
      faq: 'Часті питання',
      listing_rules: 'Правила лістингу',
      videos: 'Відео з налаштування',
      contacts: 'Контакти',
      about: 'Про нас',
    },
  },
  AfterFooter: {
    cookie_policy: 'Політика Cookies',
    public_agreement: 'Публічна угода',
    privacy_policy: 'Політика конфіденційності',
  },
  PagesFaq: {
    title: 'Часті питання',
    cost: {
      question: 'Яка ціна за використання маркетплейсу?',
      answer: 'Для усіх користувачів Гленділ діє спеціальна пропозиція - до кінця 2023 року ви можете знаходити покупців та підписувати угоди абсолютно безкоштовно.',
    },
    start: {
      question: 'Що потрібно для початку торгівлі?',
      answer: 'Для початку треба зареєструватись та пройти верифікацію. Реєстрація займе всього кілька хвилин, вам знадобиться адреса електронної пошти та інформація про ваше фермерське господарство або компанію. А процес верифікації триває до 24 годин.',
    },
    howLong: {
      question: 'Як довго розміщується заявка?',
      answer: 'Скориставшись нашим простим і легким у користуванні веб-сайтом з комп’ютера або телефона ви можете створити заявку всього за декілька хвилин',
    },
    verification: {
      question: 'Навіщо потрібна верифікація кожного учасника?',
      answer: 'Ми хочемо бути впевненими, що всі покупці та продавці справжні та надійні. Ось чому ми перевіряємо кожен профіль, перш ніж дозвілити торгівлю на платформі. Для того, щоб усі угоди були захищені Гленділ, спілкуйтесь та проводьте свої угоди на платформі. При спілкуванні та підписанні договору поза платформою, ми не зможемо допомогти у разі виникнення спірної ситуації.',
    },
    whoOtherSide: {
      question: 'Я продаю чи купую саме у Гленділ?',
      answer: 'Ні. Гленділ не бере участі в торгових процесах і не обробляє, не купує та не продає товар. Гленділ - це онлайн маркетплейс, який дозволяє покупцям і продавцям вести переговори та укладати угоди безпосередньо один з одним.',
    },
    why: {
      question: 'Навіщо мені публікувати пропозицію на Гленділ?',
      answer: 'Маркетплейс Гленділ надає вам усі необхідні інструменти для легкого та безпечного експорту агропродукції. Ви маєте можливість продати агропродукцію на ваших умовах у будь-який зручнй час. Коли ваша пропозиція з’явиться на платформі, покупці зможуть вести з вами переговори та надсилати зустрічні пропозиції, які ви можете прийняти, відхилити або відповісти.',
    },
    how: {
      question: 'Як заключити угоду?',
      answer: `У вас є угода, коли умови були узгоджені та прийняті. Це означає, що обидві сторони, які беруть участь у переговорах, погоджуються з умовами остаточної зустрічної пропозиції.

        Наприклад:

        Якщо ви продавець і отримали зустрічну пропозицію від покупця, ви можете переглянути та прийняти умови, що означає, що ви уклали угоду. Якщо вам не подобаються умови і ви хочете продовжити переговори, ви можете надіслати зустрічну пропозицію з новими умовами. Якщо покупець приймає їх - ви укладаєте угоду.`,
    },
    profit: {
      [`question_${LandingPageEnum.farmers.value}`]: 'Як я отримаю оплату?',
      [`question_${LandingPageEnum.brokers.value}`]: 'Як я отримаю оплату?',
      [`question_${LandingPageEnum.buyers.value}`]: 'Як я отримаю товар?',
      answer: 'Коли продавець приймає пропозицію, він укладає з покупцем обов’язковий договір. Покупець платить відповідно до визначених умов оплати, які були затверджені до укладання договору, а продавець відправляє товар у зазначені в договорі строки.',
    },
  },
  PagesStart: {
    buttons: {
      trade: 'Почати торгівлю',
      help: 'Допомога консультанта',
    },
    [LandingPageEnum.farmers.value]: {
      title: 'Підкорюйте світові ринки разом з Гленділ',
      subtitle: 'З нами продавати товар за кордон легше, ніж на місцевому ринку!',
    },
    [LandingPageEnum.brokers.value]: {
      title: 'Знаходьте найвигідніші угоди по всьому світу',
      subtitle: 'Найкращі умови для трейдерів, брокерів та посередників.',
    },
    [LandingPageEnum.buyers.value]: {
      title: 'Доступ до усіх пропозицій від фермерів України',
      subtitle: 'Ви отримаєте якісний товар за конкурентними цінами.',
    },
  },
  PagesHowItWorks: {
    [LandingPageEnum.farmers.value]: {
      title: 'Як почати заробляти на\u00A0експорті\u00A0агропродукції?',
      subtitle: {
        start: '',
        free: 'Безкоштовна',
        end: 'реєстрація та розміщення заявок для фермерів',
      },
    },
    [LandingPageEnum.brokers.value]: {
      title: 'Прямий доступ до усіх пропозицій від\u00A0фермерів та покупців',
      subtitle: {
        start: 'Необхідно зробити всього',
        free: '3 кроки',
        end: ':',
      },
    },
    [LandingPageEnum.buyers.value]: {
      title: 'Що необхідно для того, щоб купити агропродукцію за найкращою ціною?',
      subtitle: {
        start: 'Необхідно зробити всього',
        free: '3 кроки',
        end: ':',
      },
    },
    publicLot: {
      title: 'Опублікуйте лот',
      text: 'Розкажіть нам, що ви хочете продати, де та коли ви хочете, щоб товар був доставлений.',
    },
    getRequests: {
      title: 'Отримуйте заявки',
      text: 'Переглядайте заявки від багатьох покупців в одному місці.',
    },
    signContract: {
      title: 'Підпишіть угоду',
      text: 'Порівняйте заявки та прийміть найкращу пропозицію.',
    },
  },
  PagesExport: {
    [LandingPageEnum.farmers.value]: {
      title: 'Ще ніколи експорт агропродукції не був таким легким!',
      subtitle: 'Ви реєструєтесь на платформі Гленділ та публікуєте лоти, а платформа супроводжує вашу угоду та гарантує її виконання.',
      resume: '',
      lot: {
        title: 'Розміщення товару',
        registration: 'Безкоштовна реєстрація',
        landingLot: 'Розміщення заявки (лоту)',
        verification: 'Верифікація вашого фермерського господарства',
      },
      trades: {
        title: 'Переговори',
        getDeal: 'Отримуєте пропозицію Покупців',
        communicate: 'Ведете переговори онлайн та домовляєтесь про угоду',
        logistic: 'Гленділ прораховує логістику',
        confirmation: 'Фіксуєте бізнес підтвердження на Гленділ',
      },
      sale: {
        title: 'Продаж',
        control: 'Надсилаєте 1,5 кг вашої культури для підтвердження контролю якості',
        contract: 'Підписуєте договір з верифікованим Покупцем',
        send: 'Відправляєте товар Покупцю',
        money: 'Отримуєте гроші згідно угоди',
      },
    },
    [LandingPageEnum.brokers.value]: {
      title: 'Найкращий інструмент для посередників та брокерів!',
      subtitle: 'Ви реєструєтесь на платформі Гленділ та публікуєте заявки чи лоти, а платформа супроводжує вашу угоду та гарантує її виконання.',
      resume: 'Усі кроки легкі та прозорі:',
      lot: {
        title: 'Розміщення товару',
        registration: 'Безкоштовна реєстрація',
        landingLot: 'Розміщення заявки (лоту)',
        verification: 'Верифікація',
      },
      trades: {
        title: 'Переговори',
        getDeal: 'Отримуєте пропозицію Покупців або продавців',
        communicate: 'Ведете переговори онлайн та домовляєтесь про угоду',
        logistic: 'Гленділ прораховує логістику',
        confirmation: 'Фіксуєте бізнес підтвердження на Гленділ',
      },
      sale: {
        title: 'Продаж',
        control: '',
        contract: 'Підписуєте договір з Покупцем чи Продавцем',
        send: 'Відправляєте товар Покупцю',
        money: 'Отримуєте гроші згідно угоди',
      },
    },
    [LandingPageEnum.buyers.value]: {
      title: 'Ще ніколи експорт агропродукції не був таким легким!',
      subtitle: 'Ви реєструєтесь на платформі Гленділ та публікуєте лоти, а платформа супроводжує вашу угоду та гарантує її виконання.',
      resume: 'Необхідно пройти наступні прості кроки:',
      lot: {
        title: 'Розміщення товару',
        registration: 'Безкоштовна реєстрація',
        landingLot: 'Розміщення лоту',
        verification: 'Верифікація вашої компанії',
      },
      trades: {
        title: 'Переговори',
        getDeal: 'Отримуєте пропозиції від Фермерів чи Виробників',
        communicate: 'Ведете переговори онлайн та домовляєтесь про угоду',
        logistic: 'Гленділ прораховує логістику',
        confirmation: 'Фіксуєте бізнес підтвердження на Гленділ',
      },
      sale: {
        title: 'Продаж',
        control: '',
        contract: 'Підписуєте договір з верифікованим Продавцем',
        send: 'Оплачуєте товар згірно угоди',
        money: 'Отримуєте товар згідно угоди',
      },
    },
  },
  PagesProcess: {
    title: 'Платформа Гленділ забезпечить повний процес виконання угоди',
    subtitle: 'Наш великий досвід з експорту агропродукції дозволив нам побудувати увесь процес підписання таким чином, щоб кожен учасник угоди відчував себе захищеним',
    contract: {
      title: 'Точність в укладені контракту',
      text: 'Наші юристи забезпечать вашу угоду надійним бізнес підтвердженням, яке захистить вас від несподіванок',
    },
    legals: {
      title: 'Юридичний супровід',
      text: 'У разі виникнення несподіваних ситуацій ви зможете отримати необхідну підтримку та рекомендації щодо подальших дій',
    },
    logistics: {
      title: 'Оптимізація логістики',
      text: 'Детальна та швидка калькуляція вартості логістики надасть вам оптимальну ціну під вашу угоду',
    },
    buttons: {
      trade: 'Почати торгівлю',
    },
  },
  PagesHelp: {
    title: 'Залишились питання?',
    subtitle: 'Контактуйте з нашою командою підтримки для отримання необхідної інформації',
  },
  HelpContact: {
    Messengers: {
      title: 'Спілкування в месенджері:',
    },
    HelpPlacehodler: {
      title: 'Залишились питання?',
      subtitle: 'Контактуйте з нашою командою підтримки для отримання необхідної інформації',
    },
  },
  HelpAnna: {
    name: 'Анна',
    details: 'Допомога Фермерам та Виробникам',
    line: 'лінія',
  },
  HelpDariya: {
    name: 'Дар`я',
    details: 'Допомога Покупцям та Торгівельним компаніям',
    line: 'лінія',
  },
  HelpAya: {
    name: 'Ая',
    details: 'Допомога за напрямком Гарбузове насіння та горіх',
  },
  Contact: {
    ContactsSupport: {
      title: 'Будьте з нами на зв’язку!',
      subtitle: 'Наша команда завжди готова допомогти вам і відповісти на усі запитання щодо платформи та послуг.',
    },
    ContactsHelp: {
      title: 'Залишились питання?',
      subtitle: 'Контактуйте з нашою командою підтримки для отримання необхідної інформації',
    },
    FormFeedback: {
      title: 'Відправте свій запит і\u00A0ми\u00A0вам допоможемо',
      name: {
        label: 'Ім’я',
        placeholder: 'Ім’я',
      },
      email: {
        label: 'Електронна пошта',
        placeholder: 'Електронна пошта',
      },
      phone: {
        label: 'Номер телефона',
        placeholder: '+38 (ХХХ) ХХХ-ХХ-ХХ',
      },
      text: {
        label: 'Як ми можемо вам допомогти?',
        placeholder: 'Напишіть подробиці',
      },
      submit: 'Відправити',
    },
  },
};

export const en: typeof uk = {
  Header: {
    trade: 'Market',
    solutions: 'Solutions',
    support: 'Support',
    DropDownMenu: {
      farmers: 'Farmers',
      brokers: 'Brokers',
      customers: 'Buyers',
      contacts: 'Contacts',
      about_us: 'About us',
    },
  },
  Start: {
    title: 'Global grain marketplace',
    subtitle: 'Unlock foreign markets with Glendeal \n Trade confidently and safely',
    buttons: {
      trade: 'Start trading',
      help: 'Help me',
    },
  },
  Ticker: {
    title: 'Trading now',
  },
  HowItWorks: {
    title: 'How to Use the Platform',
    subtitle: {
      first: '',
      accent: 'Just 3 steps',
      last: ' to find an international buyer and sell your agricultural products under favorable conditions.',
    },
    steps: {
      one: 'Create a free account and post your request.',
      two: 'Participate in online trading',
      three: 'Receive a business confirmation',
    },
    buttons: {
      trade: 'Start trading',
      help: 'Need help',
    },
  },
  Home: {
    StartNow: {
      title: 'Begin trading right now',
      subtitle: 'Register and list your request or lot, participate in trades, and close profitable contracts.',
      buttons: {
        start: 'Start Trading',
      },
    },
  },
  About: {
    AboutMission: {
      title: {
        start: 'Our mission',
        text: `is to remove barriers for farmers and producers and to digitalize relationships in the
          agricultural market. Creating a world where agricultural commerce flourishes, unconfined by
          the boundaries of geography.
        `,
      },
      subtitle: `We are building an ecosystem that will enable every market player to achieve maximum
        economic results: from farmers and producers to intermediaries and brokers, from buyers to
        trading companies.
      `,
      column_one: {
        text_one: `Our experience and attention is focused on Ukrainian producers and farms to
          share their knowledge and skills in the international market. Marketplace
          Glendeal provides an opportunity to sell agricultural products online abroad, as easily and safely
          as it is in the local market. Ukraine is already known for its digital products such as Diia and
          PrivatBank/Monobank, and we are now creating a similar modern tool for the agricultural sector.
        `,
        text_two: `Thanks to our internal compliance and our partners, all our users undergo mandatory
          verification. A system of reviews and ratings allows for collaboration only with verified and
          conscientious counterparts. The verification of producers' product volumes by certified laboratories
          ensures that buyers can be confident in the proper quality and quantity of the purchased goods.
        `,
      },
      column_two: {
        text_one: `We offer a wide array of tools that make exporting convenient and safe: an internal
          messenger for direct communication with counterparts, a convenient messaging system that ensures
          no important event is missed, and in case of a successful agreement, you will receive a business
          confirmation by email for further contract conclusion. Our lawyers will allow any disputes to be
          resolved quickly and painlessly for all parties involved.
        `,
        text_two: `Join our platform and discover a new side of trading where buying and selling have
          become simpler, more interesting, and more reliable.
        `,
      },
      olexandr: 'Oleksandr Gruev',
    },
    AboutHistory: {
      title: 'How the company Glendeal was created',
      subtitle: `Glendeal is an agro marketplace, the creation of which is the culmination of 15 years of
        accumulated experience in the physical market of trading, logistics, farming, and brokerage.
      `,
    },
    StartNow: {
      title: 'Join our Platform',
      subtitle: 'Discover trading from a new perspective, where buying and selling becomes easier, more fun and more reliable',
      buttons: {
        start: 'Start trading',
      },
    },
  },
  Solving: {
    title: 'Our Solutions',
    subtitle: 'We aim to enhance the agricultural market through a new generation of digital and financial solutions, tailored specifically for each market player.',
    buttons: {
      details: 'Learn More',
    },
    farmers: {
      subtitle: 'For Farmers and Producers',
      text: 'Sell your crops to buyers around the world',
    },
    brokers: {
      subtitle: 'For Intermediaries and Brokers',
      text: 'Connect with top farmers and global buyers',
    },
    customers: {
      subtitle: 'For Buyers and Trading Companies',
      text: 'Purchase crops from the best farmers around the world',
    },
  },
  Services: {
    title: 'Our additional services',
    subtitle: 'Glendeal – your partner in the agribusiness world.',
    buttons: {
      details: 'Детальніше',
    },
    quality: {
      title: 'Quality assurance',
      text: 'We assist our farmers to check their products quality in the laboratory and ensure monitoring during dispatch.',
    },
    logistic: {
      title: 'Logistics',
      text: 'Our logistics department provides free cost estimates for shipping anywhere in the world. If needed, we can handle the entire logistics process end-to-end.',
    },
    finance: {
      title: 'Financing',
      text: 'You can apply to our "Financing" service and get financial insurance for your agreement.',
    },
    contracts: {
      title: 'Contract Support',
      text: 'An affiliated company will be part of the contract, ensuring its finalization. The farmer is guaranteed payment, while the buyer is assured of timely delivery.',
    },
    denied_items: {
      title: 'Refused cargo',
      text: 'If you find yourself in a situation where your shipment is declined, we can help you find a new buyer.',
    },
  },
  Support: {
    support: {
      title: 'Support',
      text: 'If you have any questions or need advice, reach out to our support team.',
    },
    contract: {
      title: 'End-to-End Contract Fulfillment',
      text: 'Complete contract execution, adhering to all international legal standards with full accountability.',
    },
  },
  Pricing: {
    title: 'Pricing',
    valid_until: 'Until 2024',
    subtitle: 'We offer full access to all Glendeal platform services completely free',
    free: 'Free',
    columns: {
      registration: 'Registration',
      create_lot: 'Listing a request/lot',
      trade: 'Access to trading',
      analitics: 'Access to analytics',
    },
    buttons: {
      registration: 'Registration',
    },
    farmers: {
      title: 'Farmers',
    },
    brokers: {
      title: 'Brokers',
    },
    customers: {
      title: 'Buyers',
    },
  },
  Propositions: {
    title: 'What We Offer',
    subtitle: 'Glendeal provides you with powerful trading tools, access to worldwide offers, and support every step of your way',
    export: {
      title: 'Effortless crop export',
      text: 'Tap into a global marketplace of real-time offers. Discover buyers and market your produce with just a few clicks',
    },
    identification: {
      title: 'Trade participant verification',
      text: 'Rest assured, our platform boasts only vetted members. User reviews and ratings enhance transaction quality.',
    },
    logistic: {
      title: 'Logistics calculation',
      text: 'Quickly delivery costs calculation across all transport types. Detailed shipment estimates ensure you get the best price for your deal.',
    },
    security: {
      title: 'Reliability and safety',
      text: 'With vetted participants and a refined contract fulfillment process, we guarantee successful transactions. If unforeseen issues arise, our platform is here to help you navigate them.',
    },
    services: {
      title: 'Additional services',
      text: 'We provide all the essential services you\'ll need to finalize and fulfill agreements. We\'re poised to assist you at every trade juncture.',
    },
    support: {
      title: 'Customer support',
      text: 'Our commitment is to deliver premier service to every user. If you have questions or need assistance, we\'re always just a call or message away.',
    },
  },
  Footer: {
    columns: {
      services: 'Services',
      insites: 'Інсайти',
      support: 'Support',
      company: 'Company',
    },
    links: {
      trade: 'Trading',
      farmers: 'Farmers',
      brokers: 'Brokers',
      customers: 'Buyers',
      blog: 'AgroBlog',
      partners: 'Партнери',
      faq: 'FAQ',
      listing_rules: 'Listing Rules',
      videos: 'Відео з налаштування',
      contacts: 'Contacts',
      about: 'About us',
    },
  },
  AfterFooter: {
    cookie_policy: 'Cookie Policy',
    public_agreement: 'Public Agreement',
    privacy_policy: 'Privacy Policy',
  },
  PagesFaq: {
    title: 'Frequently asked questions',
    cost: {
      question: 'What is the cost of using the marketplace?',
      answer: 'For all Glendeal users, there is a special offer - until the end of 2023 year, you can buy or sell products and sign contracts absolutely free of charge.',
    },
    start: {
      question: 'What do I need to start trading?',
      answer: 'To start, you need to register and go through verification. Registration takes just a few minutes; you will need an email address and information about your farm or company. The verification process takes up to 24 hours.',
    },
    howLong: {
      question: 'How long does it take to place a request?',
      answer: 'Using our simple and user-friendly website from a computer or phone, you can create a request in just a few minutes.',
    },
    verification: {
      question: 'Why is verification required for each user?',
      answer: 'We want to be sure that all buyers and sellers are genuine and reliable. That\'s why we check each profile before allowing trading on the platform. To ensure that all transactions are protected by Glendeal, communicate and sign the business confirmation on the marketplace. If you communicate and sign a business confirmation outside the platform, we will not be able to assist in the event of a dispute',
    },
    whoOtherSide: {
      question: 'Am I selling or buying from Glendeal?',
      answer: 'No. Glendeal does not participate in the trading processes and does not process, buy, or sell goods. Glendeal is an online marketplace that allows buyers and sellers to negotiate and make deals directly with each other.',
    },
    why: {
      question: 'Why should I post an offer on Glendeal?',
      answer: 'The Glendeal marketplace provides you with all the necessary tools for easy and secure export of agricultural products. You have the opportunity to sell your agricultural products on your terms at any convenient time. When your offer appears on the platform, buyers will be able to negotiate with you and send counteroffers, which you can accept, reject, or respond to.',
    },
    how: {
      question: 'How to make a deal?',
      answer: `You have a deal when the terms have been agreed upon and accepted. This means that both parties involved in the negotiations agree to the terms of the final counteroffer.

        For example:

        If you are a seller and have received a counteroffer from a buyer, you can review and accept the terms, which means you have made a deal. If you do not like the terms and want to continue negotiations, you can send a counteroffer with new terms. If the buyer accepts them - you have made a deal.`,
    },
    profit: {
      [`question_${LandingPageEnum.farmers.value}`]: 'How will I receive payment?',
      [`question_${LandingPageEnum.brokers.value}`]: 'How will I receive payment?',
      [`question_${LandingPageEnum.buyers.value}`]: 'How will I receive the goods?',
      answer: 'When the seller accepts the offer, he enters into a binding contract with the buyer. The buyer pays according to the payment terms that were agreed upon before the contract was concluded, and the seller sends the goods within the time frames specified in the contract.',
    },
  },
  PagesStart: {
    buttons: {
      trade: 'Start Trading',
      help: 'Need help',
    },
    [LandingPageEnum.farmers.value]: {
      title: 'Conquer global markets with Glendeal',
      subtitle: 'Selling abroad with us is easier than in your local market!',
    },
    [LandingPageEnum.brokers.value]: {
      title: 'Find the most profitable deals around the world.',
      subtitle: 'The best terms for traders, brokers, and intermediaries',
    },
    [LandingPageEnum.buyers.value]: {
      title: 'Access to all offers from farmers around the world',
      subtitle: 'You will receive quality goods at competitive prices.',
    },
  },
  PagesHowItWorks: {
    [LandingPageEnum.farmers.value]: {
      title: 'How to start profiting from agricultural exports?',
      subtitle: {
        start: '',
        free: 'Free',
        end: 'registration and request placement for farmers.',
      },
    },
    [LandingPageEnum.brokers.value]: {
      title: 'Direct access to all offers from farmers and buyers',
      subtitle: {
        start: 'You only need to take',
        free: '3 steps',
        end: ':',
      },
    },
    [LandingPageEnum.buyers.value]: {
      title: 'What is needed to buy agricultural products at the best price?',
      subtitle: {
        start: 'You only need to take',
        free: '3 steps',
        end: ':',
      },
    },
    publicLot: {
      title: 'List your lot',
      text: 'Tell us what you want to sell, where, and when you\'d like the product to be delivered.',
    },
    getRequests: {
      title: 'Receive inquiries',
      text: 'Review requests from multiple buyers in one place.',
    },
    signContract: {
      title: 'Sign an agreement',
      text: 'Compare the requests and accept the best offer.',
    },
  },
  PagesExport: {
    [LandingPageEnum.farmers.value]: {
      title: 'Exporting agricultural products has never been so easy!',
      subtitle: 'You register on the Glendeal platform and list your lots, and the platform protects your agreement, ensuring its execution.',
      resume: '',
      lot: {
        title: 'Product listing',
        registration: 'Free Registration',
        landingLot: 'Listing a request (lot)',
        verification: 'Verification of your farm',
      },
      trades: {
        title: 'Negotiations',
        getDeal: 'Receive a buyer\'s offer',
        communicate: 'Engage in online negotiations and finalize an agreement',
        logistic: 'Glendeal calculates logistics',
        confirmation: 'Sign a business confirmation on Glendeal',
      },
      sale: {
        title: 'Sale',
        control: 'Send 1.5 kg of your crop for quality control confirmation',
        contract: 'Sign an agreement with a verified Buyer',
        send: 'Ship the product to the Buyer',
        money: 'Receive funds according to the deal',
      },
    },
    [LandingPageEnum.brokers.value]: {
      title: 'The best tool for intermediaries and brokers!',
      subtitle: 'Register on the Glendeal marketplace and post your requests or lots, and the platform supports your agreement and guarantees its fulfillment.',
      resume: 'All steps are easy and transparent:',
      lot: {
        title: 'Placing a request',
        registration: 'Free registration',
        landingLot: 'List the request/lot',
        verification: 'Verification',
      },
      trades: {
        title: 'Negotiation',
        getDeal: 'Receive offers from buyers and sellers',
        communicate: 'Conduct online negotiations and agree on an agreement',
        logistic: 'Glendeal calculates logistics',
        confirmation: 'Sign a business confirmation on Glendeal',
      },
      sale: {
        title: 'Sale',
        control: '',
        contract: 'Sign an agreement with the Buyer or Seller',
        send: 'Send or receive goods',
        money: 'Mutual settlements according to the deal',
      },
    },
    [LandingPageEnum.buyers.value]: {
      title: 'Agricultural export has never been so easy!',
      subtitle: 'You register on the Glendeal marketplace and list a lot, and the platform supports your agreement and guarantees its fulfillment.',
      resume: 'You need to follow these simple steps:',
      lot: {
        title: 'Product placement',
        registration: 'Free registration',
        landingLot: 'Listing a request (lot)',
        verification: 'Verification',
      },
      trades: {
        title: 'Negotiation',
        getDeal: 'Receive offers from buyers and sellers',
        communicate: 'Conduct online negotiations and agree on an agreement',
        logistic: 'Glendeal calculates logistics',
        confirmation: 'Sign a business confirmation on Glendeal',
      },
      sale: {
        title: 'Sale',
        control: '',
        contract: 'Sign an agreement with the Buyer or Seller',
        send: 'Pay for the goods according to the agreement',
        money: 'Receive the goods according to the agreement',
      },
    },
  },
  PagesProcess: {
    title: 'Glendeal platform ensures complete deal execution',
    subtitle: 'Our extensive experience in agricultural exports has allowed us to design the agreement process so every party involved feels protected.',
    contract: {
      title: 'Precision in contract signing',
      text: 'Our lawyers will fortify your agreement with a reliable business confirmation, protecting you from any unexpected events.',
    },
    legals: {
      title: 'Legal Support',
      text: 'In case of unexpected situations, you\'ll receive the necessary support and advice for subsequent actions.',
    },
    logistics: {
      title: 'Logistics Optimization',
      text: 'A detailed and quick logistics cost calculation will provide the best price for your agreement.',
    },
    buttons: {
      trade: 'Start trading',
    },
  },
  PagesHelp: {
    title: 'Still have questions?',
    subtitle: 'Contact our support team for the required information',
  },
  HelpContact: {
    Messengers: {
      title: 'Messaging:',
    },
    HelpPlacehodler: {
      title: 'Still have questions?',
      subtitle: 'Contact our support team for the required information',
    },
  },
  HelpAnna: {
    name: 'Anna',
    details: 'Assistance for Farmers and Producers',
    line: 'line',
  },
  HelpDariya: {
    name: 'Daria',
    details: 'Assistance for Buyers and Trading companies',
    line: 'line',
  },
  HelpAya: {
    name: 'Aya',
    details: 'Help regarding Pumpkin Seeds and Nuts',
  },
  Contact: {
    ContactsSupport: {
      title: 'Let\'s keep in touch!',
      subtitle: 'Our team is always ready to assist you and answer any questions related to the platform and services.',
    },
    ContactsHelp: {
      title: 'Still have questions?',
      subtitle: 'Contact our support team for the information you need',
    },
    FormFeedback: {
      title: 'Send us your question and we will help you',
      name: {
        label: 'Name',
        placeholder: 'Name',
      },
      email: {
        label: 'Email',
        placeholder: 'Email',
      },
      phone: {
        label: 'Phone number',
        placeholder: '+38 (ХХХ) ХХХ-ХХ-ХХ',
      },
      text: {
        label: 'How can we help you?',
        placeholder: 'Provide details',
      },
      submit: 'Send',
    },
  },
};

export const ru: typeof uk = {
  Header: {
    trade: 'Торговля',
    solutions: 'Решения',
    support: 'Поддержка',
    DropDownMenu: {
      farmers: 'Фермерам',
      brokers: 'Посредникам',
      customers: 'Покупателям',
      contacts: 'Контакты',
      about_us: 'Про нас',
    },
  },
  Start: {
    title: 'Первый украинский \n агромаркетплейс',
    subtitle: 'С Глендил ваша продукция достигнет любой страны мира. \n Экспортируйте легко и безопасно!',
    buttons: {
      trade: 'Начать торговлю',
      help: 'Помощь консультанта',
    },
  },
  Ticker: {
    title: 'Торгуют сейчас',
  },
  HowItWorks: {
    title: 'Как пользоваться платформой',
    subtitle: {
      first: 'Вам нужно пройти',
      accent: 'всего 3 шага,',
      last: 'чтобы найти покупателя за рубежом и продать сельскохозяйственную продукцию на выгодных условиях.',
    },
    steps: {
      one: 'Создайте бесплатный аккаунт и опубликуйте заявку',
      two: 'Примите участие в торговле онлайн',
      three: 'Получите документ о подтверждении соглашения',
    },
    buttons: {
      trade: 'Начать торговлю',
      help: 'Помощь консультанта',
    },
  },
  Home: {
    StartNow: {
      title: 'Начать торги прямо сейчас',
      subtitle: 'Зарегистрируйтесь и разместите заявку, участвуйте в торгах и заключайте выгодные контракты.',
      buttons: {
        start: 'Начать торги',
      },
    },
  },
  About: {
    AboutMission: {
      title: {
        start: 'Наша миссия',
        text: 'поддержать украинские фермерские хозяйства и диджитализировать взаимоотношения на агрорынке.',
      },
      subtitle: `Мы строим экосистему, которая позволит каждому игроку на рынке добиться максимального
        экономического результата: фермерам и производителям, посредникам и брокерам, покупателям и торговым компаниям.
      `,
      column_one: {
        text_one: `Наш опыт и внимание сконцентрированы на производителях и фермерских хозяйствах Украины, чтобы
          поделиться своими знаниями и навыками работы, приобретенными за 15 лет работы на международном рынке.
          Маркетплейс Глендил предоставляет возможность продать онлайн агропродукцию за границу так же легко и
          безопасно, как и на местном рынке. Украина уже известна такими своими цифровыми продуктами, как Дия и
          ПриватБанк/Монобанк, и мы сейчас создаем такой современный инструмент для агросферы.
        `,
        text_two: `Благодаря внутреннему комплаенсу и нашим партнерам, все наши пользователи проходят обязательную
          верификацию. Система отзывов и рейтингов позволяет сотрудничать только с проверенными и добросовестными
          контрагентами. Проверка объемов продукции производителей сертифицированными лабораториями позволит
          покупателям быть уверенными в надлежащем качестве и объеме покупаемой продукции.
        `,
      },
      column_two: {
        text_one: `Мы предлагаем большое количество инструментов, которые сделают экспорт удобным и безопасным:
          внутренний мессенджер для прямого общения с контрагентами, удобная система сообщений не позволит упустить
          ни одного важного события и в случае успешного соглашения на почту вы получите бизнес подтверждение для
          дальнейшего заключения контракта. Наши юристы позволят разрешить любые споры наиболее быстро и безболезненно
          для каждой из сторон.
        `,
        text_two: `Присоединяйтесь к нашей платформе и открывайте для себя торговлю с новой стороны, где покупать
          и продать стало проще, интереснее и надежнее.
        `,
      },
      olexandr: 'Александр Груев',
    },
    AboutHistory: {
      title: 'История создания Глендил',
      subtitle: 'Глендил – украинский маркетплейс, который стал результатом накопленного 15-летнего опыта работы на физическом рынке трейдинга, логистики, фермерства и брокерства.',
    },
    StartNow: {
      title: 'Присоединяйтесь к нашей платформе',
      subtitle: 'Откройте для себя торговлю с новой стороны, где покупать и продавать становиться проще, интереснее и надежнее.',
      buttons: {
        start: 'Начать торги',
      },
    },
  },
  Solving: {
    title: 'Наши решения',
    subtitle: 'Мы стремимся улучшить агрорынок с помощью нового поколения цифровых и финансовых решений, специально разработанных для каждого участника рынка.',
    buttons: {
      details: 'Подробнее',
    },
    farmers: {
      subtitle: 'Фермерам и производителям',
      text: 'Продавайте агропродукцию покупателям со всего мира',
    },
    brokers: {
      subtitle: 'Посредникам и \n Брокерам',
      text: 'Общайтесь с лучшими фермерами Украины и покупателями со всего мира',
    },
    customers: {
      subtitle: 'Покупателям и Торговым компаниям',
      text: 'Купите агропродукцию у лучших фермеров Украины',
    },
  },
  Services: {
    title: 'Наши дополнительные сервисы',
    subtitle: 'Глендил – ваш партнер в мире аграрного бизнеса.',
    buttons: {
      details: 'Детальніше',
    },
    quality: {
      title: 'Проверка качества товара',
      text: 'Мы помогаем нашим ферерам пройти проверку качества товара в лаборатории и проконтролировать ее при отгрузке.',
    },
    logistic: {
      title: 'Логистика',
      text: 'Наш отдел логистики бесплатно рассчитывает стоимость логистики в любую точку мира. Но при необходимости мы можем обеспечить выполнение всего логистического процесса “под ключ”.',
    },
    finance: {
      title: 'Финансирование',
      text: 'Вы можете подать заявку на наш сервис "Финансирование" и получить финансовое страхование вашего соглашения',
    },
    contracts: {
      title: 'Сопровождение контракта',
      text: 'Аффилированная компания будет участником договора и обеспечит заключение соглашения. Фермеру гарантирует оплату, а покупателю – доставку в установленные сроки.',
    },
    denied_items: {
      title: 'Грузы, от которых отказались',
      text: 'Если вы оказались в ситуации, когда от вашего груза отказываются, мы можем помочь найти нового покупателя.',
    },
  },
  Support: {
    support: {
      title: 'Поддержка пользователей',
      text: 'Если у вас возникли вопросы или нужна консультация, обращайтесь в нашу службу поддержки',
    },
    contract: {
      title: 'Выполнение контрактов "Под\u00A0ключ"”',
      text: 'Выполнение контрактов  "Под ключ" по соблюдению всех норм международного права с полной ответственностью',
    },
  },
  Pricing: {
    title: 'Стоимость услуг',
    valid_until: 'до 2024 года',
    subtitle: 'Мы предлагаем воспользоваться всеми сервисами платформы Глендил совершенно бесплатно.',
    free: 'Бесплатно',
    columns: {
      registration: 'Регистрация',
      create_lot: 'Размещение запроса/лота',
      trade: 'Доступ к торговле',
      analitics: 'Доступ к аналитике',
    },
    buttons: {
      registration: 'Зарегистрироваться',
    },
    farmers: {
      title: 'Фермерам',
    },
    brokers: {
      title: 'Посредникам',
    },
    customers: {
      title: 'Покупателям',
    },
  },
  Propositions: {
    title: 'Что мы предлагаем',
    subtitle: 'Глендил предоставляет вам эффективные инструменты для торговли, доступ к предложениям по всему миру и поддержку на каждом этапе.',
    export: {
      title: 'Легкий экспорт продукции',
      text: 'Вы получаете доступ к мировому рынку актуальных предложений. Находите покупателей и продавайте урожай всего в несколько кликов.',
    },
    identification: {
      title: 'Идентификация участников торговли',
      text: 'Вы можете быть уверены, что на платформе находятся только проверенные пользователи. Отзывы и рейтинги пользователей повышают качество сделок.',
    },
    logistic: {
      title: 'Расчет логистики',
      text: 'Быстрый расчет стоимости доставки любым видом транспорта. Подробная калькуляция доставки даст вам оптимальную цену под вашу сделку.',
    },
    security: {
      title: 'Надеждност и безопасност',
      text: 'Проверенные участники и отлаженный сервис выполнения контрактов гарантируют успешность сделки. В случае возникновения непредвиденных обстоятельств платформа поможет с их решением.',
    },
    services: {
      title: 'Дополнительные сервисы',
      text: 'Мы предлагаем все необходимые сервисы, которые могут понадобиться для заключения и выполнения соглашений. Мы всегда готовы поддержать вас на любом этапе торговли.',
    },
    support: {
      title: 'Поддержка',
      text: 'Мы стремимся предоставить каждому пользователю наилучший сервис. Если у вас есть вопросы или нужна помощь, мы всегда на связи.',
    },
  },
  Footer: {
    columns: {
      services: 'Сервисы',
      insites: 'Инсайты',
      support: 'Поддержка',
      company: 'Компания',
    },
    links: {
      trade: 'Торговля',
      farmers: 'Фермерам',
      brokers: 'Брокерам',
      customers: 'Покупателям',
      blog: 'АгроБлог',
      partners: 'Партнери',
      faq: 'Часто задаваемые вопросы',
      listing_rules: 'Правила листинга',
      videos: 'Видео по настройкам',
      contacts: 'Контакты',
      about: 'О нас',
    },
  },
  AfterFooter: {
    cookie_policy: 'Политика Cookies',
    public_agreement: 'Публичное соглашение',
    privacy_policy: 'Политика конфиденциальности',
  },
  PagesFaq: {
    title: 'Частые вопросы:',
    cost: {
      question: 'Какая стоимость использования маркетплейса?',
      answer: 'Для всех пользователей Глендил действует специальное предложение – до конца 2023 года вы можете находить покупателей и подписывать сделки совершенно бесплатно.',
    },
    start: {
      question: 'Что нужно для начала торговли?',
      answer: 'Для начала, вам необходимо зарегистрироваться и пройти верификацию. Регистрация займет всего несколько минут, для этого понадобится адрес электронной почты и информация о вашем фермерском хозяйстве или компании. Процесс верификации длится до 24 часов.',
    },
    howLong: {
      question: 'Как долго размещается заявка?',
      answer: 'Воспользовавшись нашим простым и легким в использовании веб-сайтом с компьютера или телефона, вы можете создать заявку всего за несколько минут.',
    },
    verification: {
      question: 'Для чего нужна верификация каждого участника?',
      answer: 'Мы хотим быть уверены, что все покупатели и продавцы настоящие и надежные. Поэтому мы проверяем каждый профиль, прежде чем разрешить торговлю на платформе. Верификация позволяет защитить все соглашения на платформе Глендил. При общении и подписании договора вне платформы мы не сможем помочь вам, в случае возникновения спорной ситуации.',
    },
    whoOtherSide: {
      question: 'Я продаю или покупаю именно у Глендил?',
      answer: 'Нет. Глендил не участвует в торговых процессах и не обрабатывает, не покупает и не продает товар. Глендил – это онлайн маркетплейс, позволяющий покупателям и продавцам вести переговоры и заключать сделки непосредственно друг с другом.',
    },
    why: {
      question: 'Зачем мне публиковать предложение на Глендил?',
      answer: 'Агромаркетплейс Глендил предоставляет все необходимые инструменты для легкого и безопасного экспорта агропродукции. Вы можете продать товар на ваших условиях в удобное для вас время. Когда ваше предложение появится на платформе, покупатели смогут вести с вами переговоры и посылать встречные предложения, которые вы можете принять, отклонить или провести переговоры.',
    },
    how: {
      question: 'Как заключить сделку?',
      answer: `Вы получаете бизнес подтверждение сделки, согласно международного права, когда все условия были обсуждены и приняты. Оно подтверждает, что обе стороны, участвующие в переговорах, согласны с условиями окончательного встречного предложения.

        К примеру:

        Если вы продавец и получили встречное предложение от покупателя, вы можете просмотреть и принять условия, так вы сразу заключаете сделку. Если вам не нравятся условия и вы хотите продлить переговоры, вы можете отправить встречное предложение с новыми условиями. Если покупатель принимает их – вы заключаете сделку. И так до полного согласования з двух сторон.`,
    },
    profit: {
      [`question_${LandingPageEnum.farmers.value}`]: 'Как я получу оплату?',
      [`question_${LandingPageEnum.brokers.value}`]: 'Как я получу оплату?',
      [`question_${LandingPageEnum.buyers.value}`]: 'Как я получу товар?',
      answer: 'Когда продавец принимает предложение, он получает бизнес подтверждение, после которого заключает с покупателем обязательный договор. Покупатель платит в соответствии с оговоренными условиями оплаты, утвержденными до заключения договора, а продавец отправляет товар в указанные в договоре сроки.',
    },
  },
  PagesStart: {
    buttons: {
      trade: 'Начать торговлю',
      help: 'Помощь консультанта',
    },
    [LandingPageEnum.farmers.value]: {
      title: 'Покоряйте мировые рынки вместе с Глендилом',
      subtitle: 'С нами продавать товар за границу легче, чем на местном рынке!',
    },
    [LandingPageEnum.brokers.value]: {
      title: 'Находите самые выгодные сделки по всему миру',
      subtitle: 'Лучшие условия для трейдеров, брокеров и посредников.',
    },
    [LandingPageEnum.buyers.value]: {
      title: 'Доступ ко всем предложениям от фермеров Украины',
      subtitle: 'Вы получите качественный товар по конкурентным ценам.',
    },
  },
  PagesHowItWorks: {
    [LandingPageEnum.farmers.value]: {
      title: 'Как начать зарабатывать на\u00A0экспорте\u00A0агропродукции?',
      subtitle: {
        start: '',
        free: 'Бесплатная',
        end: 'регистрация и размещение заявок для фермеров',
      },
    },
    [LandingPageEnum.brokers.value]: {
      title: 'Прямой доступ ко всем предложениям от\u00A0фермеров и покупателей',
      subtitle: {
        start: 'Необходимо сделать всего',
        free: '3 шага',
        end: ':',
      },
    },
    [LandingPageEnum.buyers.value]: {
      title: 'Что необходимо для того, чтобы купить агропродукцию по лучшей цене?',
      subtitle: {
        start: 'Необходимо сделать всего',
        free: '3 шага',
        end: ':',
      },
    },
    publicLot: {
      title: 'Опубликуйте лот',
      text: 'Расскажите нам, что вы хотите продать, где и когда вы хотите, чтобы товар был доставлен.',
    },
    getRequests: {
      title: 'Получайте заявки',
      text: 'Просмотрите заявки от многих покупателей в одном месте.',
    },
    signContract: {
      title: 'Подпишите соглашение',
      text: 'Сравните заявки и примите самое лучшее предложение.',
    },
  },
  PagesExport: {
    [LandingPageEnum.farmers.value]: {
      title: 'Еще никогда экспорт агропродукции не был настолько легким!',
      subtitle: 'Вы регистрируетесь на платформе Глендил и публикуете лоты, а платформа сопровождает ваше соглашение и гарантирует его выполнение.',
      resume: '',
      lot: {
        title: 'Размещение товара',
        registration: 'Бесплатная регистрация',
        landingLot: 'Размещение заявки (лота)',
        verification: 'Верификация вашего фермерского хозяйства',
      },
      trades: {
        title: 'Переговоры',
        getDeal: 'Получаете предложение покупателей',
        communicate: 'Ведете онлайн переговоры и договариваетесь о соглашении',
        logistic: 'Глендил просчитывает логистику',
        confirmation: 'Фиксируете бизнес подтверждение на Глендил',
      },
      sale: {
        title: 'Продажа',
        control: 'Отправляете 1,5 кг вашей культуры для подтверждения контроля качества',
        contract: 'Подписываете договор с верифицированным Покупателем',
        send: 'Отправляете товар Покупателю',
        money: 'Получаете деньги согласно сделке',
      },
    },
    [LandingPageEnum.brokers.value]: {
      title: 'Лучший инструмент для посредников и брокеров!',
      subtitle: 'Вы регистрируетесь на платформе Глендил и публикуете заявки или лоты, а платформа сопровождает ваше соглашение и гарантирует его выполнение.',
      resume: 'Все шаги легкие и прозрачные:',
      lot: {
        title: 'Размещение товара',
        registration: 'Бесплатная регистрация',
        landingLot: 'Размещение заявки (лота)',
        verification: 'Верификация',
      },
      trades: {
        title: 'Переговоры',
        getDeal: 'Получаете предложения покупателей и продавцов',
        communicate: 'Ведете онлайн переговоры и договариваетесь о соглашении',
        logistic: 'Глендил просчитывает логистику',
        confirmation: 'Фиксируете бизнес подтверждение на Глендил',
      },
      sale: {
        title: 'Продажа',
        control: '',
        contract: 'Подписываете договор с Покупателем или Продавцом',
        send: 'Отправляете товар или получаете товар',
        money: 'Получаете деньги или товар согласно сделке',
      },
    },
    [LandingPageEnum.buyers.value]: {
      title: 'Еще никогда экспорт агропродукции не был настолько легким!',
      subtitle: 'Вы регистрируетесь на платформе Глендил и публикуете лоты, а платформа сопровождает ваше соглашение и гарантирует его выполнение.',
      resume: 'Необходимо пройти следующие простые шаги:',
      lot: {
        title: 'Размещение товара',
        registration: 'Бесплатная регистрация',
        landingLot: 'Размещение заявки (лота)',
        verification: 'Верификация',
      },
      trades: {
        title: 'Переговори',
        getDeal: 'Отримуєте пропозиції від Фермерів чи Виробників',
        communicate: 'Ведете переговори онлайн та домовляєтесь про угоду',
        logistic: 'Гленділ прораховує логістику',
        confirmation: 'Фіксуєте бізнес підтвердження на Гленділ',
      },
      sale: {
        title: 'Продаж',
        control: '',
        contract: 'Подписываете договор с Покупателем или Продавцом',
        send: 'Оплачиваете товар согласно сделке',
        money: 'Получаете товар согласно сделке',
      },
    },
  },
  PagesProcess: {
    title: 'Платформа Глендил обеспечит полный процесс выполнения сделки',
    subtitle: 'Наш большой опыт в экспорте агропродукции позволил нам построить весь процесс подписания таким образом, чтобы каждый участник соглашения чувствовал себя защищенным.',
    contract: {
      title: 'Точность в заключении контракта',
      text: 'Наши юристы обеспечат ваше соглашение надежным бизнес подтверждением, которое защитит вас от неожиданностей',
    },
    legals: {
      title: 'Юридическое сопровождение',
      text: 'В случае возникновения неожиданных ситуаций вы сможете получить необходимую поддержку и рекомендации по дальнейшим действиям',
    },
    logistics: {
      title: 'Оптимизация логистики',
      text: 'Подробная и быстрая калькуляция стоимости логистики даст вам оптимальную цену под вашу сделку',
    },
    buttons: {
      trade: 'Начать торговлю',
    },
  },
  PagesHelp: {
    title: 'Остались вопросы?',
    subtitle: 'Свяжитесь с нашей командой поддержки для получения необходимой информации',
  },
  HelpContact: {
    Messengers: {
      title: 'Общение в мессенджере:',
    },
    HelpPlacehodler: {
      title: 'Остались вопросы?',
      subtitle: 'Обратитесь к нашей команде поддержки для получения необходимой информации',
    },
  },
  HelpAnna: {
    name: 'Анна',
    details: 'Помощь Фермерам и Производителям',
    line: 'линия',
  },
  HelpDariya: {
    name: 'Дарья',
    details: 'Помощь Покупателям и Торговым компаниям',
    line: 'линия',
  },
  HelpAya: {
    name: 'Айя',
    details: 'Помощь по направлению Тыквенные семечки и орех',
  },
  Contact: {
    ContactsSupport: {
      title: 'Будьте с нами на связи!',
      subtitle: 'Наша команда всегда готова помочь вам и ответить на все вопросы, касающиеся платформы и услуг.',
    },
    ContactsHelp: {
      title: 'Остались вопросы?',
      subtitle: 'Обратитесь к нашей команде поддержки для получения необходимой информации',
    },
    FormFeedback: {
      title: 'Отправьте свой вопрос и\u00A0мы\u00A0вам поможем',
      name: {
        label: 'Имя',
        placeholder: 'Имя',
      },
      email: {
        label: 'Электронная почта',
        placeholder: 'Электронная почта',
      },
      phone: {
        label: 'Номер телефона',
        placeholder: '+38 (ХХХ) ХХХ-ХХ-ХХ',
      },
      text: {
        label: 'Как мы можем помочь вам?',
        placeholder: 'Напишите подробности',
      },
      submit: 'Отправить',
    },
  },
};

export const bg: typeof uk = {
  Header: {
    trade: 'Търговия',
    solutions: 'Решения',
    support: 'Поддръжка',
    DropDownMenu: {
      farmers: 'За фермери',
      brokers: 'За посредници',
      customers: 'За клиенти',
      contacts: 'Контакти',
      about_us: 'За нас',
    },
  },
  Start: {
    title: 'Международен агропазар',
    subtitle:
      'С Glendeal  вашите продукти ще достигнат всяка страна по света. \n Експортирайте лесно и безопасно!',
    buttons: {
      trade: 'Започнете да търгувате',
      help: 'Помощ от консултант',
    },
  },
  Ticker: {
    title: 'Търгуват сега',
  },
  HowItWorks: {
    title: 'Как да използвате платформата',
    subtitle: {
      first: 'Трябва да преминете',
      accent: 'само 3 стъпки,',
      last: 'за да намерите купувач в чужбина и да продадете селскостопански продукти при изгодни условия.',
    },
    steps: {
      one: 'Създайте безплатен акаунт и публикувайте заявка',
      two: 'Вземете участие в онлайн търговия',
      three: 'Получете документ за потвърждение на споразумението',
    },
    buttons: {
      trade: 'Започнете да търгувате',
      help: 'Помощ от консултант',
    },
  },
  Home: {
    StartNow: {
      title: 'Започнете да търгувате веднага ',
      subtitle: 'Регистрирайте се и публикувайте заявка, участвайте в търгове и сключвайте изгодни договори.',
      buttons: {
        start: 'Започнете да търгувате',
      },
    },
  },
  About: {
    AboutMission: {
      title: {
        start: 'Нашата мисия',
        text: 'е да премахнем бариерите за фермерите и производителите и да дигитализираме взаимоотношенията на аграрния пазар.',
      },
      subtitle: 'Ние изграждаме екосистема, която ще позволи на всеки участник на пазара да постигне максимален икономически резултат: за фермерите и производителите, за посредниците и брокерите, за купувачите и търговските компании.',
      column_one: {
        text_one: 'Маркетплейсът Glendeal предоставя възможност за онлайн продажба на аграрни продукти в чужбина толкова лесно и безопасно, колкото и на местния пазар.',
        text_two: 'Благодарение на вътрешния комплаенс и нашите партньори, всички наши потребители преминават задължителна верификация. Системата за отзиви и рейтинги позволява да се сътрудничи само с проверени и добросъвестни контрагенти. Проверка на обемите на продукцията на производителите в сертифицирани лаборатории ще позволи на купувачите да бъдат уверени в надлежното качество и обем на закупените продукти.',
      },
      column_two: {
        text_one: 'Ние предлагаме множество инструменти, които ще направят износа удобен и безопасен: вътрешен месинджър за директна комуникация с контрагенти, удобна система за съобщения, която няма да ви позволи да пропуснете нито едно важно събитие, и при успешна сделка ще получите бизнес потвърждение на имейла за по-нататъшно сключване на договора. Нашите юристи ще разрешат всички спорове възможно най-бързо и безболезнено за всяка от страните.',
        text_two: 'Присъединете се към нашата платформа и открийте търговията отново, където купуването и продаването стават по-лесно, по-интересно и по-надеждно.',
      },
      olexandr: 'Александър Груев',
    },
    AboutHistory: {
      title: 'История на създаването на Glendeal',
      subtitle: 'Glendeal – украински маркетплейс, който е резултат от натрупания 15-годишен опит в работата на физическия пазар на трейдинг, логистика, фермерство и брокерство',
    },
    StartNow: {
      title: 'Присъединете се към нашата платформа',
      subtitle:
        'Открийте нови възможности за търговия, където купуването и продаването стават по-лесни, по-интересни и по-надеждни.',
      buttons: {
        start: 'Започнете да търгувате',
      },
    },
  },
  Solving: {
    title: 'Нашите решения',
    subtitle: 'Стремим се да подобрим аграрния пазар чрез ново поколение дигитални и финансови решения, специално разработени за всеки участник на пазара.',
    buttons: {
      details: 'Детайли',
    },
    farmers: {
      subtitle: 'За фермери и производители',
      text: 'Продавайте аграрни продукти на купувачи от цял свят',
    },
    brokers: {
      subtitle: 'За посредници и брокери',
      text: 'Комуникирайте с най-добрите фермери в Украйна и купувачи от цял свят',
    },
    customers: {
      subtitle: 'За посредниците и брокерите',
      text: 'Купете аграрни продукти от най-добрите фермери в Украйна',
    },
  },
  Services: {
    title: 'Наши допълнителни услуги',
    subtitle: 'Glendeal - вашият партньор в света на аграрния бизнес.',
    buttons: {
      details: 'Детайли',
    },
    quality: {
      title: 'Проверка на качеството на стоките',
      text: 'Проверка на качеството на стоките Помагаме на нашите фермери да преминат проверка на качеството на стоките в лаборатория и да я контролират при изпращане.',
    },
    logistic: {
      title: 'Логистика',
      text: 'Нашият логистичен отдел изчислява безплатно стойността на логистиката до всяка точка на света. При необходимост можем да осигурим изпълнението на целия логистичен процес "под ключ".',
    },
    finance: {
      title: 'Финансиране',
      text: 'Можете да подадете заявка за нашия услуга "Финансиране" и да получите финансово осигуряване на вашето споразумение.',
    },
    contracts: {
      title: 'Съпровождане на договора',
      text: 'Афилирана компания ще бъде участник в договора и ще осигури сключването на споразумението. Гарантира на фермера плащането, а на купувача – доставката в установените срокове.',
    },
    denied_items: {
      title: 'Товари, от които са се отказали',
      text: 'Ако се окажете в ситуация, когато вашият товар е отказан, ние можем да помогнем да намерите нов купувач',
    },
  },
  Support: {
    support: {
      title: 'Поддръжка',
      text: 'Ако имате въпроси или се нуждаете от консултация, свържете се с нашата служба за поддръжка',
    },
    contract: {
      title: 'Изпълнение на договори "Под ключ"',
      text: 'Изпълнение на договори "Под ключ" в съответствие с всички норми на международното право с пълна отговорност.',
    },
  },
  Pricing: {
    title: 'Цена на услугите',
    valid_until: 'до 2024 година',
    subtitle: 'Предлагаме да се възползвате от всички услуги на платформата Glendeal напълно безплатно.',
    free: 'Безплатно',
    columns: {
      registration: 'Регистрация',
      create_lot: 'Публикуване на заявка/лот ',
      trade: 'Достъп до търговия',
      analitics: 'Достъп до анализи',
    },
    buttons: {
      registration: 'Регистрирайте се',
    },
    farmers: {
      title: 'За фермери',
    },
    brokers: {
      title: 'За посредници',
    },
    customers: {
      title: 'За купувачи',
    },
  },
  Propositions: {
    title: 'Какво предлагаме',
    subtitle:
      'Glendeal ви предоставя ефективни инструменти за търговия, достъп до оферти от цял свят и подкрепа на всеки етап.',

    export: {
      title: 'Лесен експорт / импорт',
      text: 'Получавате достъп до световния пазар на актуални оферти. Намерете купувачи и продавайте реколтата си с няколко клика.',
    },
    identification: {
      title: 'Идентификация на участниците в търговията',
      text: 'Можете да бъдете уверени, че на платформата има само проверени потребители. Отзивите и рейтингите на потребителите повишават качеството на сделките.',
    },
    logistic: {
      title: 'Изчисляване на логистиката',
      text: 'Бързо изчисляване на разходите за доставка с всякакъв вид транспорт. Подробната калкулация на доставката ще ви даде оптимална цена за вашата сделка.',
    },
    security: {
      title: 'Надеждност и безопасност',
      text: 'Проверените участници и добре настроената услуга за изпълнение на договори гарантират успешността на сделката. В случай на непредвидени обстоятелства платформата ще помогне с тяхното решение.',
    },

    services: {
      title: 'Допълнителни услуги',
      text: 'Предлагаме всички необходими услуги, които може да са ви необходими за сключване и изпълнение на споразумения. Винаги сме готови да ви подкрепим на всеки етап от търговията.',
    },
    support: {
      title: 'Поддръжка',
      text: 'Стремим се да предоставим на всеки потребител най-добрата услуга. Ако имате въпроси или се нуждаете от помощ, винаги сме на разположение.',
    },
  },
  Footer: {
    columns: {
      services: 'Услуги',
      insites: 'Инсайти',
      support: 'Поддръжка',
      company: 'Компания',
    },
    links: {
      trade: 'Търговия',
      farmers: 'За фермери',
      brokers: 'За брокери',
      customers: 'За купувачи',
      blog: 'АгроБлог',
      partners: 'Партньори',
      faq: 'Често задавани въпроси',
      listing_rules: 'Правила за листинг',
      videos: 'Видео настройки',
      contacts: 'Контакти',
      about: 'За нас',
    },
  },
  AfterFooter: {
    cookie_policy: 'Политика за Cookies',
    public_agreement: 'Публично споразумение',
    privacy_policy: 'Политика за поверителност',
  },
  PagesFaq: {
    title: 'Често задавани въпроси:',
    cost: {
      question: 'Каква е цената за използване на пазарния платформа?',
      answer:
        'За всички потребители на Glendeal важи специално предложение - до края на 2023 г. можете да намирате покупатели и да подписвате сделки напълно безплатно.',
    },
    start: {
      question: 'Какво е необходимо за стартиране на търговията?',
      answer:
        'За начало трябва да се регистрирате и да преминете през верификация. Регистрацията отнема само няколко минути и изисква имейл адрес и информация за вашия фермерски двор или компания. Процесът на верификация може да отнеме до 24 часа.',
    },
    howLong: {
      question: 'Колко време отнема публикуването на заявка?',
      answer:
        'Използвайки нашия лесен за използване уебсайт от компютър или телефон, можете да създадете заявка само за няколко минути.',
    },
    verification: {
      question: 'За какво се изисква верификация на всеки участник?',
      answer:
        'Искаме да сме сигурни, че всички покупатели и продавачи са реални и надеждни. Затова проверяваме всеки профил, преди да позволим търговия на платформата. Верификацията помага да се защитят всички споразумения на платформата Глендил. В случай на спор извън платформата, ние няма да можем да ви помогнем.',
    },
    whoOtherSide: {
      question: 'Аз купувам или продавам директно от Glendeal?',
      answer:
        'Не. Glendeal не участва в търговските процеси и не купува или продава стоки. Glendeal е онлайн платформа, където покупатели и продавачи могат да водят преговори и да сключват сделки директно помежду си.',
    },
    why: {
      question: 'Защо да публикувам предложение на Glendeal?',
      answer:
        'Агромаркетплейс Glendeal предоставя всички необходими инструменти за лесен и безопасен експорт на селскостопански продукти. Можете да продавате стоки на вашите условия в удобно за вас време. Когато вашето предложение се появи на платформата, покупателите могат да предлагат преговори и да изпращат контрапредложения, които можете да приемете, отхвърлите или да водите преговори по тях.',
    },
    how: {
      question: 'Как да сключите сделка?',
      answer: `Получавате бизнес потвърждение за сделката съгласно международното право, когато всички условия са обсъдени и приети. То потвърждава, че и двете страни, участващи в преговорите, са съгласни с условията на окончателното контрапредложение.

        Например:

        Ако сте продавач и получите контрапредложение от купувач, можете да разгледате и да приемете условията, което значи, че веднага сключвате сделка. Ако не сте доволни от условията и искате да продължите преговорите, можете да изпратите контрапредложение с нови условия. Ако купувачът ги приеме, сделката се счита за сключена. И така до пълното съгласие на двете страни.`,
    },
    profit: {
      [`question_${LandingPageEnum.farmers.value}`]: 'Как да получа плащане?',
      [`question_${LandingPageEnum.brokers.value}`]: 'Как да получа плащане?',
      [`question_${LandingPageEnum.buyers.value}`]: 'Как да получа стоки?',
      answer:
        'Когато продавачът приеме предложението, той получава бизнес потвърждение и сключва задължителен договор с купувача. Купувачът плаща според условията за плащане, утвърдени преди сключването на договора, а продавачът изпраща стоката в уговорените срокове.',
    },
  },
  PagesStart: {
    buttons: {
      trade: 'Започнете търговия',
      help: 'Помощ на консултант',
    },
    [LandingPageEnum.farmers.value]: {
      title: 'Покорете световните пазари заедно с Glendeal',
      subtitle: 'С нас продажбата на стоки в чужбина е по-лесна, отколкото на местния пазар!',
    },
    [LandingPageEnum.brokers.value]: {
      title: 'Намирайте най-изгодните сделки по целия свят',
      subtitle: 'Най-добрите условия за трейдъри, брокери и посредници.',
    },
    [LandingPageEnum.buyers.value]: {
      title: 'Достъп до всички оферти от фермерите в Украйна ',
      subtitle: 'Ще получите качествени стоки на конкурентни цени.',
    },
  },
  PagesHowItWorks: {
    [LandingPageEnum.farmers.value]: {
      title: 'Как да започнете да печелите от износ на агропродукция? ',
      subtitle: {
        start: '',
        free: 'Безплатна',
        end: 'регистрация и публикуване на заявки за фермери',
      },
    },
    [LandingPageEnum.brokers.value]: {
      title: 'Пряк достъп до всички оферти от фермери и купувачи',
      subtitle: {
        start: 'Необходимо е да направите само',
        free: '3 стъпки',
        end: ':',
      },
    },
    [LandingPageEnum.buyers.value]: {
      title:
        'Какво е необходимо, за да закупите селскостопанска продукция на най-добра цена?',
      subtitle: {
        start: 'Необходимо е да направите само',
        free: '3 стъпки',
        end: ':',
      },
    },
    publicLot: {
      title: 'Публикувайте лот ',
      text: 'Разкажете ни какво искате да продадете, къде и кога искате стоката да бъде доставена. ',
    },
    getRequests: {
      title: 'Получавайте заявки',
      text: 'Прегледайте заявки от множество купувачи на едно място.',
    },
    signContract: {
      title: 'Подпишете споразумение ',
      text: 'Сравнете заявките и приемете най-добрата оферта.',
    },
  },
  PagesExport: {
    [LandingPageEnum.farmers.value]: {
      title: 'Никога износът на агропродукция не е бил толкова лесен!',
      subtitle: 'Регистрирате се на платформата Glendeal и публикувате лотове, а платформата съпровожда вашето споразумение и гарантира неговото изпълнение.',
      resume: '',
      lot: {
        title: 'Публикуване на стока',
        registration: 'Безплатна регистрация',
        landingLot: 'Публикуване на заявка (лот)',
        verification: 'Верификация на вашето фермерско стопанство',
      },
      trades: {
        title: 'Преговори',
        getDeal: 'Получавате предложения от купувачи',
        communicate: 'Водите онлайн преговори и договаряте споразумение',
        logistic: 'Glendeal изчислява логистиката',
        confirmation: 'Фиксирате бизнес потвърждение на Glendeal',
      },
      sale: {
        title: 'Продажба',
        control: 'Изпращате 1,5 кг от вашата продукция за потвърждение на контрола на качеството',
        contract: 'Подписвате договор с верифициран купувач',
        send: 'Изпращате стоката на купувача',
        money: 'Получавате парите съгласно сделката',
      },
    },
    [LandingPageEnum.brokers.value]: {
      title: 'Най-добрият инструмент за посредници и брокери!',
      subtitle: 'Регистрирате се на платформата Glendeal и публикувате заявки или лотове, а платформата съпровожда вашето споразумение и гарантира неговото изпълнение. Всички стъпки са лесни и прозрачни:',
      resume: 'Всички стъпки са лесни и прозрачни:',
      lot: {
        title: 'Публикуване на стока ',
        registration: 'Безплатна регистрация',
        landingLot: 'Публикуване на заявка (лот)',
        verification: 'Верификация',
      },
      trades: {
        title: 'Преговори',
        getDeal: 'Получавате предложения от купувачи и продавачи',
        communicate: 'Водите онлайн преговори и договаряте споразумение',
        logistic: 'Glendeal изчислява логистиката',
        confirmation: 'Фиксирате бизнес потвърждение на Glendeal',
      },
      sale: {
        title: 'Продажба',
        control: '',
        contract: 'Подписвате договор с купувача или продавача',
        send: 'Изпращате стока или получавате стока',
        money: 'Получавате пари или стока съгласно сделката',
      },
    },
    [LandingPageEnum.buyers.value]: {
      title:
        'Никога експорт на селскостопански продукти не е бил толкова лесен!',
      subtitle:
        'Регистрирате се на платформа Glendeal и публикувате лотове, а платформата съпровожда вашето споразумение и гарантира неговото изпълнение.',
      resume: 'Необходимо пройти следните прости стъпки:',
      lot: {
        title: 'Размещение на продукт',
        registration: 'Безплатна регистрация',
        landingLot: 'Размещение на оферта (лот)',
        verification: 'Верификация',
      },
      trades: {
        title: 'Преговори',
        getDeal: 'Получавате предложения от фермери или производители',
        communicate: 'Водите преговори онлайн и сключвате споразумение',
        logistic: 'Glendeal изчислява логистиката',
        confirmation: 'Фиксирате бизнес потвърждение в Glendeal',
      },
      sale: {
        title: 'Продажба',
        control: '',
        contract: 'Подписвате договор с Купувача или Продавача',
        send: 'Плащате стоката съгласно споразумението',
        money: 'Получавате стоката съгласно споразумението',
      },
    },
  },
  PagesProcess: {
    title: 'Платформата Glendeal осигурява пълния процес на изпълнение на сделката',
    subtitle: 'Нашият богат опит в износа на агропродукция ни позволи да изградим целия процес на подписване така, че всеки участник в споразумението да се чувства защитен.',
    contract: { title: 'Точност при сключването на договора',
      text: 'Нашите юристи ще осигурят вашето споразумение с надеждно бизнес потвърждение, което ще ви предпази от неочакваности.',
    },
    legals: {
      title: 'Юридическо съпровождане',
      text: 'В случай на възникване на неочаквани ситуации, можете да получите необходимата подкрепа и препоръки за по-нататъшни действия.',
    },
    logistics: {
      title: 'Оптимизация на логистиката',
      text: 'Подробното и бързо калкулиране на разходите за логистика ще ви даде оптимална цена за вашата сделка.',
    },
    buttons: {
      trade: 'Започнете да търгувате',
    },
  },
  PagesHelp: {
    title: 'Останаха въпроси?',
    subtitle:
      'Свържете се с нашия екип за поддръжка за необходимата информация.',
  },
  HelpContact: {
    Messengers: {
      title: 'Общуване в месинджър:',
    },
    HelpPlacehodler: {
      title: 'Останаха въпроси?',
      subtitle:
        'Свържете се с нашия екип за поддръжка за необходимата информация.',
    },
  },
  HelpAnna: {
    name: 'Анна',
    details: 'Помощ за фермери и производители линия',
    line: 'линия',
  },
  HelpDariya: {
    name: 'Даря',
    details: 'Помощ за купувачи и търговски компании',
    line: 'линия',
  },
  HelpAya: {
    name: 'Ая',
    details: 'Помощ по направление Тиквени семена и ядки',
  },
  Contact: {
    ContactsSupport: {
      title: 'Бъдете с нас на връзка!',
      subtitle:
        'Нашият екип винаги е готов да ви помогне и да отговори на всички въпроси, свързани с платформата и услугите.',
    },
    ContactsHelp: {
      title: 'Останаха въпроси?',
      subtitle:
        'Свържете се с нашия екип за поддръжка за необходимата информация.',
    },
    FormFeedback: {
      title: 'Изпратете своя въпрос и ние ще ви помогнем',
      name: {
        label: 'Име',
        placeholder: 'Име',
      },
      email: {
        label: 'Електронна поща',
        placeholder: 'Електронна поща',
      },
      phone: {
        label: 'Телефонен номер',
        placeholder: '+359 (ХХХ) ХХХ-ХХ-ХХ',
      },
      text: {
        label: 'Как можем да ви помогнем?',
        placeholder: 'Опишете подробности',
      },
      submit: 'Изпрати',
    },
  },
};
