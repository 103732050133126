import TagManager from 'react-gtm-module';
import { Nullable } from 'core/interfaces';

const events = {
  reigstration: 'form_registration_send',
  registration_regular: 'registration_regular',
  registration_fast: 'registration_fast',
  login: 'form_login',
  subscriptionForm: 'form_subscription_pay_send',
  subscriptionFree: 'form_subscription_try_free',
  request: 'form_market_make_request',
  offer: 'form_market_make_offer',
} as const;

const USER_ID_KEY_NAME = 'User_ID';
export class TagManagerService {
  private static readonly proxy = buildProxy();
  private static gtmId = process.env.REACT_APP_GTM_ID;

  static init(): void {
    if (!this.gtmId) return;
    TagManager.initialize({ gtmId: this.gtmId });
  }

  static get events(): Readonly<typeof events> {
    return this.proxy;
  }

  static send(event: string): void {
    if (!this.gtmId) return;
    TagManager.dataLayer({ dataLayer: { event } });
  }

  static updateAuth(userId: Nullable<number>): void {
    window.dataLayer = window.dataLayer || [];
    if (userId) {
      const isUserExist = window.dataLayer.some(item => (USER_ID_KEY_NAME in item) && item[USER_ID_KEY_NAME] === userId);
      if (!isUserExist) {
        window.dataLayer.push({ [USER_ID_KEY_NAME]: userId });
      }
    } else {
      window.dataLayer = window.dataLayer.filter(item => !(USER_ID_KEY_NAME in item));
    }
  }
}

interface IEvents {
  [key: string]: string | IEvents;
}

function buildProxy(): Readonly<typeof events> {
  const handler = {
    get(target: IEvents, p: string): IEvents | void {
      if (p in target) {
        const value = target[p];
        if (typeof value === 'object') return new Proxy(value, handler);
        TagManagerService.send(value);
      }
    },
  };

  return new Proxy(events, handler) as Readonly<typeof events>;
}
