import { SubscriptionPlanEnum } from 'reducers/profile/subscription.slice';

import { SvgCustomSubscription } from './CustomSubscription';
import { SvgEcoSubscription } from './EcoSubscription';
import { SvgOptiSubscription } from './OptiSubscription';
import { SvgStandardSubscription } from './StandardSubscription';
import { SvgZeroSubscription } from './ZeroSubscription';


export interface IconProps {
  className?: string;
  width?: number;
  height?: number;
}

export const IconManager: Record<SubscriptionPlanEnum, React.FC<IconProps>>= {
  [SubscriptionPlanEnum.zero]: SvgZeroSubscription,
  [SubscriptionPlanEnum.free]: SvgZeroSubscription,
  [SubscriptionPlanEnum.custom]: SvgCustomSubscription,
  [SubscriptionPlanEnum.eco]: SvgEcoSubscription,
  [SubscriptionPlanEnum.opti]: SvgOptiSubscription,
  [SubscriptionPlanEnum.standard]: SvgStandardSubscription,
};
