export const uk = {
  PublicField: {
    label: 'Публічно',
    popup: {
      title: 'Вибір публічності зустрічної пропозиції',
      buttons: {
        public: 'Зробити Публічним',
        private: 'Зробити Приватним',
      },
      text: {
        public: 'Заявка буде доступна для перегляду, що дасть можливість створювати для неї зустрічні пропозиції',
        private: 'Заявка буде недоступною для інших зустрічних пропозицій',
        info: 'Ця видимість також налаштовується з картки створення зустрічної заявки',
        remember: 'Не показувати більше',
      },
    },
  },
};

export const en: typeof uk = {
  PublicField: {
    label: 'Public',
    popup: {
      title: 'Choosing the Public availability of the application',
      buttons: {
        public: 'Make Public',
        private: 'Make Private',
      },
      text: {
        public: 'The application will be visible to everyone, which will make it possible to create counter offers for it',
        private: 'The application will not be available for other counter offers',
        info: 'This visibility is also configured from the counter offer creation card',
        remember: 'Do not show again',
      },
    },
  },
};

export const ru: typeof uk = {
  PublicField: {
    label: 'Общедоступно',
    popup: {
      title: 'Выбор общедоступности встречного предложения',
      buttons: {
        public: 'Сделать Публичным',
        private: 'Сделать Приватным',
      },
      text: {
        public: 'Заявка будет всем доступна к просмотру, что даст возможность создавать для неё встречные предложения',
        private: 'Заявка будет недоступная для других встречных предложений',
        info: 'Данная видимость также настраивается из карточки создания встречной заявки',
        remember: 'Не показывать больше',
      },
    },
  },
};

export const bg: typeof uk = {
  PublicField: {
    label: 'Обществено достъпно',
    popup: {
      title: 'Избор на обществена достъпност на насрещното предложение',
      buttons: {
        public: 'Направете публично',
        private: 'Направете частно',
      },
      text: {
        public:
          'Заявката ще бъде достъпна за всички за преглед, което ще даде възможност за създаване на насрещни предложения за нея',
        private: 'Заявката ще бъде недостъпна за други насрещни предложения',
        info: 'Тази видимост също се настройва от картата за създаване на насрещна заявка',
        remember: 'Не показвай повече',
      },
    },
  },
};
