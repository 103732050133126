import { LotType } from 'constants/general';
import { AnyTODO } from 'core/interfaces/AnyTODO.type';
import {
  CLEAR_ONE_REQUEST,
  GET_ONE_REQUEST_START,
  GET_ONE_REQUEST_SUCCESS,
  GET_ONE_REQUEST_FAILURE,
} from '../actions/oneRequestActions';
import { ILot } from './oneOfferReducer';
import { IAdditionalApiStatuses } from './slices/interfaces';

export interface IRequest extends ILot {
  type: LotType.request;

  unsuitable_shipping_lines: number[];

  shipping_date_from: string;
  shipping_date_to: string;
  free_days_at_port: string;
  shipping_company_name: string;
  transit_time: string;
  label_inscription: string;
}

const initialState: { item?: IRequest } & IAdditionalApiStatuses = {
  loading: false,
  loaded: false,
  error: null,
};

interface ActionSuccses {
  type: typeof GET_ONE_REQUEST_SUCCESS;
  payload: IRequest;
}
interface ActionLoading {
  type: typeof GET_ONE_REQUEST_START;
}
interface ActionFailing {
  type: typeof GET_ONE_REQUEST_FAILURE;
  payload: { error: AnyTODO };
}
interface ActionClear {
  type: typeof CLEAR_ONE_REQUEST;
  appending: boolean;
}

type Action = ActionSuccses | ActionLoading | ActionFailing | ActionClear;

export default function oneRequestReducer(state = initialState, action: Action): typeof initialState {
  switch (action.type) {
    case GET_ONE_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case GET_ONE_REQUEST_SUCCESS:
      return {
        ...state,
        item: action.payload,
        loading: false,
        loaded: true,
        error: null,
      };
    case GET_ONE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.error,
      };
    case CLEAR_ONE_REQUEST:
      return initialState;
    default:
      return state;
  }
}
