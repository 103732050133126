import React from 'react';
import SvgIconContainer from '../SvgIconContainer';
import { IconProps } from '.';

export const SvgZeroSubscription: React.FC<IconProps> = ({ className, width = 22, height = 26 }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width={width} height={height} viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0C6.07513 0 11 4.92487 11 11V22.75C4.92487 22.75 0 17.8251 0 11.75V0Z" fill="#FFD600" />
        <path d="M0 10.8333C6.07513 10.8333 11 15.7582 11 21.8333V26C4.92487 26 0 21.0751 0 15V10.8333Z" fill="#FF7325" />
        <path d="M11 11C11 4.92487 15.9249 0 22 0V15C22 21.0751 17.0751 26 11 26V11Z" fill="#0AAF60" />
      </svg>
    </SvgIconContainer>
  );
};
