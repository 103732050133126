import { API } from '../modules/Api';
import Logger from '../modules/Logger';
import { generateQueryString } from '../utils/generateQueryString';
import {
  getNotificationsStarted,
  getNotificationsSuccess,
  getNotificationsFailure,
  loadMoreNotifications,
  getUnreadCountNotificationSuccess,
} from '../actions/notificationsActions';
import { API_ROUTES } from '../constants/general';
import { setInfoPopup } from '../actions/infoPopupAction';

export const getNotifications = ({ limit, offset, unread } = {}) => {
  const queryParams = { limit, offset, unread };
  const queryString = generateQueryString(queryParams);
  const apiUrl = `/notifications/${queryString}`;

  return (dispatch, getState) => {
    dispatch(getNotificationsStarted());

    API.get(apiUrl).then(
      (response) => {
        const page = getState().notifications.page + 1;
        dispatch(getNotificationsSuccess({ page, ...response.data }));
      },
      (error) => {
        Logger.info(error);
        dispatch(getNotificationsFailure(error.message));
      },
    );
  };
};

export const getMoreNotifications = () => {
  return (dispatch, getState) => {
    if (getState().notifications.next !== null) {
      // TODO del .replace after fix backend
      let linkToGetNextData = getState().notifications.next.toString();//.replace("http", "https");

      API.get(linkToGetNextData).then(
        (response) => {
          const page = getState().notifications.page + 1;
          dispatch(loadMoreNotifications({ page, ...response.data }));
        },
        (error) => {
          Logger.info(error);
          dispatch(getNotificationsFailure(error.message));
        },
      );
    }
  };
};

export const readAllNotificationsApi = ({ limit, offset = null, unread }) => {
  const queryParams = { limit, offset, unread };
  const queryString = generateQueryString(queryParams);
  const apiUrl = `${API_ROUTES.notifications}${queryString}`;

  return (dispatch) => {
    API.get(apiUrl)
      .then((responseGetNotifications) => {
        const ids = responseGetNotifications?.data?.results.reduce((acc, { id }) => {
          return [...acc, id];
        }, []);

        dispatch(readNotificationsApi(ids, true));
      })
      .catch((error) => {
        Logger.info('GET_READ_NOTIFICATIONS', error.response);
      });
  };
};

export const readNotificationsApi = (ids, showPopup) => {
  return (dispatch) => {
    API.post(API_ROUTES.readNotifications, { ids })
      .then((responseReadNotifications) => {
        showPopup && dispatch(setInfoPopup('success', responseReadNotifications.data.message));
        dispatch(getNotifications({}));
        dispatch(getUnreadCountNotificationsApi());
      })
      .catch((errorPost) => {
        Logger.info('POST_READ_NOTIFICATIONS', errorPost.response);
      });
  };
};

export const getUnreadCountNotificationsApi = () => {
  return (dispatch) => {
    API.get('/notifications/unread_count/')
      .then((response) => {
        dispatch(getUnreadCountNotificationSuccess(response.data));
      })
      .catch((error) => {
        Logger.error(error.response);
      });
  };
};
