import { AnyTODO } from 'core/interfaces';
import { IRequest } from 'reducers/oneRequestReducer';

export const GET_ONE_REQUEST_START = 'GET_ONE_REQUEST_START';
export const GET_ONE_REQUEST_SUCCESS = 'GET_ONE_REQUEST_SUCCESS';
export const GET_ONE_REQUEST_FAILURE = 'GET_ONE_REQUEST_FAILURE';
export const CLEAR_ONE_REQUEST = 'CLEAR_ONE_REQUEST';

const getOneRequestSuccess = (payload: IRequest) => ({
  type: GET_ONE_REQUEST_SUCCESS,
  payload,
});

const getOneRequestStarted = () => ({
  type: GET_ONE_REQUEST_START,
});

const getOneRequestFailure = (error: AnyTODO) => ({
  type: GET_ONE_REQUEST_FAILURE,
  payload: {
    error,
  },
});

const clearOneRequest = () => ({
  type: CLEAR_ONE_REQUEST,
});

export { getOneRequestSuccess, getOneRequestStarted, getOneRequestFailure, clearOneRequest };
