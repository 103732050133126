import {
  TOGGLE_FILTERS,
  INCREMENT_FILTER_COUNTER,
  CLOSE_FILTERS,
  RESET_FILTER,
} from 'actions/filtersActions';

const initialState = {
  opened: false,
  counter: 0,
};

export default function filtersReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FILTERS:
      return { ...state, opened: action.value === null ? !state.opened : action.value };
    case CLOSE_FILTERS:
      return { ...state, opened: false };
    case INCREMENT_FILTER_COUNTER:
      return { ...state, counter: Math.abs(state.counter) + 1 };
    case RESET_FILTER:
      return { ...state, counter: -Math.abs(state.counter) - 1 };
    default:
      return state;
  }
}
