import { useEffect, useRef } from 'react';

export function useInitNextGeneral(
  fnInit?: () => unknown,
  fnGeneral: null | (() => unknown) = null,
  arrChanges: Parameters<typeof useEffect>[1] = [],
){
  const isInit = useRef(true);

  useEffect(
    () => {
      (isInit.current ? fnInit : fnGeneral)?.();
      isInit.current = false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    arrChanges,
  );
}
