export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';
export const SET_LOADING = 'SET_FAVORITES_LOADING';
export const SET_APPENDING = 'SET_FAVORITES_APPENDING';
export const DELETE_FROM_FAVORITES = 'DELETE_FROM_FAVORITES';

const getSuccess = (payload) => {
  return {
    type: GET_FAVORITES_SUCCESS,
    payload,
  };
};

const setLoading = (loading) => ({
  type: SET_LOADING,
  loading,
});

const setAppending = appending => ({
  type: SET_APPENDING,
  appending,
});


const deleteFromFavorites = (id) => ({
  type: DELETE_FROM_FAVORITES,
  payload: id,
});

export {
  getSuccess,
  setLoading,
  setAppending,
  deleteFromFavorites,
};
