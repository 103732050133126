export enum ShareTypes {
  lot = 'lot',
  lots = 'lots',
  companyLots = 'companyLots',
  filteredCompanyLots = 'filteredCompanyLots',
  similar = 'similar',
  opposite = 'opposite',
}
export type DropdownStatus = 'standard' | 'closed' | 'opened' | 'disabled' | 'hovered';

export { SCOPE_SHARED_BUTTONS } from 'i18n/structure/components/ShareButton';
