import { RefObject, useEffect, useRef, useState } from 'react';

export const useContainerDimensions = (): [RefObject<HTMLHeadingElement>, { width: number; height: number }] => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const getDimensions = () => ({
      width: containerRef.current?.offsetWidth || 0,
      height: containerRef.current?.offsetHeight || 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (containerRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef]);

  return [containerRef, dimensions];
};
