import { SCOPE_SETTINGS } from '.';

export const SCOPE_USER = `${SCOPE_SETTINGS}$User`;

export const uk = {
  Fields: {
    contact_name: {
      label: 'Контактна особа',
      placeholder: 'Ім\'я',
    },
    last_name: {
      label: 'Прізвище',
      placeholder: 'Прізвище',
    },
    phone_number: {
      label: 'Телефон',
      placeholder: '+380 _______',
      tooltips: {
        verified: 'Цей номер веріфіцировано',
        notVerified: 'Натисніть для початку процесу верифікації',
      },
      messages: {
        sms_send_success: 'Повідомлення успішно відправлено',
        sms_send_409: 'Для подальшої верифікації, зверніться, будь ласка, до служби підтримки',
        validate_success: 'Номер успішно підтверджено',
      },
      errors: {
        sms_send_409: 'Перевіщен ліміт спроб',
      },
      confirmation: {
        enter_pincode: 'Введіть пінкод',
        your_phone_number: 'Ваш номер',
        get_pincode: 'Отримати код',
      },
    },
    language: {
      label: 'Мова',
    },
  },
};


export const en: typeof uk = {
  Fields: {
    contact_name: {
      label: 'Contact person',
      placeholder: 'Name',
    },
    last_name: {
      label: 'Last name',
      placeholder: 'Last name',
    },
    phone_number: {
      label: 'Phone',
      placeholder: '+380 _______',
      tooltips: {
        verified: 'This number has been verified',
        notVerified: 'Click to start the verification process',
      },
      messages: {
        sms_send_success: 'The message was sent successfully',
        sms_send_409: 'For further verification, please contact the support team',
        validate_success: 'The number has been successfully verified',
      },
      errors: {
        sms_send_409: 'Attempt limit exceeded',
      },
      confirmation: {
        enter_pincode: 'Enter the pin code',
        your_phone_number: 'Your phone number',
        get_pincode: 'Get the code',
      },
    },
    language: {
      label: 'Language',
    },
  },
};


export const ru: typeof uk = {
  Fields: {
    contact_name: {
      label: 'Контактное лицо',
      placeholder: 'Имя',
    },
    last_name: {
      label: 'Фамилия',
      placeholder: 'Фамилия',
    },
    phone_number: {
      label: 'Телефон',
      placeholder: '+380 _______',
      tooltips: {
        verified: 'Этот номер верифицирован',
        notVerified: 'Нажмите для начала процесса верификации',
      },
      messages: {
        sms_send_success: 'Сообщение успешно отправлено',
        sms_send_409: 'Для дальнейшей верификации, обратитесь, пожалуйста, в службу поддержки',
        validate_success: 'Номер успешно подтвержден',
      },
      errors: {
        sms_send_409: 'Истек лимит попыток',
      },
      confirmation: {
        enter_pincode: 'Введите пинкод',
        your_phone_number: 'Ваш номер',
        get_pincode: 'Получить код',
      },
    },
    language: {
      label: 'Язык',
    },
  },
};

export const bg: typeof uk = {
  Fields: {
    contact_name: {
      label: 'Лице за контакт',
      placeholder: 'Име',
    },
    last_name: {
      label: 'Фамилия',
      placeholder: 'Фамилия',
    },
    phone_number: {
      label: 'Телефон',
      placeholder: '+359 _______',
      tooltips: {
        verified: 'Този номер е верифициран',
        notVerified: 'Натиснете за започване на процеса на верификация',
      },
      messages: {
        sms_send_success: 'Съобщението беше успешно изпратено',
        sms_send_409:
          'За по-нататъшна верификация, моля, свържете се с поддръжката',
        validate_success: 'Номерът беше успешно потвърден',
      },
      errors: {
        sms_send_409: 'Изтече лимитът на опитите',
      },
      confirmation: {
        enter_pincode: 'Въведете пинкод',
        your_phone_number: 'Вашият номер',
        get_pincode: 'Получете код',
      },
    },
    language: {
      label: 'Език',
    },
  },
};
