// import { combineReducers } from 'redux';
import { combineReducers } from '@reduxjs/toolkit';
import registrationDialog from './RegistrationDialog';
import activePageDataReducer from './activePageDataReducer';
import addresses from './slices/addresses.slice';
import myCompany from './slices/myCompany.slice';
import allReducer from './allReducer';
import companyContacts from './slices/companyContacts.slice';
import companyPositions from './slices/companyPositions.slice';
import culturesReducer from './culturesReducer';
import favorites from './favoritesReducer';
import filtersReducer from './filtersReducer';
import globalAlert from './slices/globalAlert.slice';
import infoPopupReducer from './infoPopup';
import inviteReducer from './inviteReducer';
import invitesReducer from './invitesReducer';
import goods from './slices/goods.slice';
import lots from './lotsReducer';
import markets from './slices/markets.slice';
import myLots from './myLotsReducer';
import notificationsReducer from './notificationsReducer';
import oneOfferReducer from './oneOfferReducer';
import oneRequestReducer from './oneRequestReducer';
import oneTradeReducer from './oneTradeReducer';
import reviewCategories from './slices/reviewCategories.slice';
import settings from './slices/settings.slice';
import systemSettings from './slices/systemSettings.slice';
import tradesInfoBadges from './slices/tradesInfoBadges.slice';
import dashboard from './dashboardReducer';
import filtersData from './slices/filtersData.slice';
import userDashboard from './slices/userDashboard.slice';
import profile from './profile';

export default combineReducers({
  registrationDialog,
  activePage: activePageDataReducer,
  addresses,
  all: allReducer,
  companyContacts,
  companyPositions,
  cultures: culturesReducer,
  dashboard,
  favorites,
  filters: filtersReducer,
  filtersData,
  globalAlert,
  infoPopup: infoPopupReducer,
  invite: inviteReducer,
  invites: invitesReducer,
  goods,
  lots,
  markets,
  myCompany,
  myLots,
  notifications: notificationsReducer,
  oneOffer: oneOfferReducer,
  oneRequest: oneRequestReducer,
  oneTrade: oneTradeReducer,
  reviewCategories,
  settings,
  systemSettings,
  tradesInfoBadges,
  userDashboard,
  profile,
});
