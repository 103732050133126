import {
  GET_LOTS_SUCCESS,
  SET_LOADING,
  SET_APPENDING,
} from 'actions/lotsAction';
import { ILotBase } from './oneOfferReducer';
import Factory, { IListState } from './utils/FactoryLots';


interface ActionSuccses {
  type: typeof GET_LOTS_SUCCESS;
  payload: IListState<ILotBase>;
}
interface ActionLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}
interface ActionAppending {
  type: typeof SET_APPENDING;
  appending: boolean;
}

type Action = ActionSuccses | ActionLoading | ActionAppending;

const initialState = Factory.initialState<ILotBase>();

export default function lots(state = initialState, action: Action): IListState<ILotBase> {
  switch (action.type) {
    case GET_LOTS_SUCCESS: return Factory.getSuccess(state, action);
    case SET_LOADING: return Factory.setLoading(state, action);
    case SET_APPENDING: return Factory.setAppending(state, action);
    default:
      return state;
  }
}
