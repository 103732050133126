import store from 'store/store';
import Nav, { ROUTES } from 'nav';
import { ICulture } from 'reducers/allReducer';
import { generatePath } from 'react-router';
import { formatWithThousands } from 'utils/formatThousands';
import { Category } from 'models/Category';
import { Churl } from 'utils/clients/Churl';

export type { ICulture };
export { Nav };

export class Culture {
  protected _store!: ReturnType<typeof store['getState']>;
  protected _category!: Category;
  protected _churl?: string;

  static find(id: number): Culture {
    return new this(this.store.all.cultures.list[id]);
  }

  private static get store() {
    return store.getState();
  }

  constructor (
    public $: Partial<ICulture>,
  ) {}

  get id() { return this.$.id; }
  get name() { return this.$.name; }
  get image() { return this.$.image; }
  get icon() { return this.$.icon || this.$.image; }
  get category_id() { return this.$.category_id; }
  get totalRequestsCount(): number { return this.$.total_requests_count || 0; }
  get totalRequestsSum(): string { return formatWithThousands(this.$.total_requests_sum || 0); }
  get totalOffersSum(): string { return formatWithThousands(this.$.total_offers_sum || 0); }
  get totalOffersCount(): number { return this.$.total_offers_count || 0; }

  get category(): Category {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._category = this._category || Category.find(this.category_id!);
  }
  get churl(): string {
    return this._churl || (this._churl = new Churl(this.$).calculate());
  }

  get path(): string {
    const cultureChurl = this.churl;
    if (cultureChurl) {
      return generatePath(ROUTES.cultures, { cultureChurl });
    }
    const id = this.$.id;
    if (id) {
      return `${ROUTES.proposals}?${new URLSearchParams({ 'cultures[]': `${id}` })}`;
    }
    return ROUTES.market;
  }
  go(): void {
    Nav.go(this.path, { vibrate: 'standard' });
  }

  private get store() {
    return this._store ? this._store : this._store = store.getState();
  }
}
