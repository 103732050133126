import { SCOPE_LANDING } from 'i18n/Landing';

export const SCOPE_REGISTRATIONLAYOUT = 'RegistrationLayout';

export const uk = {
  titles: {
    root: 'Реєстрація',
    company: 'Інформація про компанію',
  },
  infos: {
    root: 'Для створення заявок необхідно заповнити контактні дані',
    company: 'Для отримання тільки актуальних пропозицій зареєструйте вашу Компанію і заповніть форму',
  },
  links: {
    later: 'Заповнити пізніше',
    howItWorks: 'Як це працює',
    cookiePolicy: `$t(${SCOPE_LANDING}$AfterFooter$cookie_policy)`,
    publicAgreement: `$t(${SCOPE_LANDING}$AfterFooter$public_agreement)`,
    privacyPolicy: `$t(${SCOPE_LANDING}$AfterFooter$privacy_policy)`,
  },
  confirmations: {
    later: `
      Вам необхідно заповнити дані про компанію для проходження верифікації на платформі Glendeal. Як тільки
      компанія буде верифікована, ви отримаєте повідомлення з підтвердженням.
      <br /> <br />
      Після верифікації компанії, ви зможете безкоштовно створювати заявки на купівлю і продаж ваших лотів та оформити
      передплату для торгівлі лотами на платформі інших учасників ринку
    `,
  },
  alert: {
    text1: 'Нам потрібно до 24 годин часу для верифікації вашої компанії. Після цього, ви зможете робити ставки та торгувати на платформі Glendeal.',
    text2: 'Але вже зараз ви можете ознайомитись з можливостями агромаркетплейсу та знайти цікаві пропозиції.',
  },
};

export const en: typeof uk = {
  titles: {
    root: 'Registration',
    company: 'About company',
  },
  infos: {
    root: 'For creating requests, please, provide contact details',
    company: 'Register your company and fill in the form to get relevant offers',
  },
  links: {
    later: 'Fill in later',
    howItWorks: 'How it works',
    cookiePolicy: `$t(${SCOPE_LANDING}$AfterFooter$cookie_policy)`,
    publicAgreement: `$t(${SCOPE_LANDING}$AfterFooter$public_agreement)`,
    privacyPolicy: `$t(${SCOPE_LANDING}$AfterFooter$privacy_policy)`,
  },
  confirmations: {
    later: `
      You need to fill out company information to undergo verification on the Glendeal platform.
      Once the company is verified, you will receive a confirmation message.
      <br />
      <br />
      After verifying the company, you can place free bids on the purchase and sale of lots and
      subscribe to trade lots on the platform of other market participants.
    `,
  },
  alert: {
    text1: 'We need 24 hours to verify your company. You will then be able to place bids and trade on the Glendeal platform.',
    text2: 'But now you can get acquainted with the capabilities of the agricultural marketplace and find interesting offers.',
  },
};

export const ru: typeof uk = {
  titles: {
    root: 'Регистрация',
    company: 'Информация о Компании',
  },
  infos: {
    root: 'Для создания заявок необходимо заполнить контактные данные',
    company: 'Для получения только актуальных предложений зарегистрируйте вашу Компанию и заполните форму',
  },
  links: {
    later: 'Заполнить позже',
    howItWorks: 'Как это работает',
    cookiePolicy: `$t(${SCOPE_LANDING}$AfterFooter$cookie_policy)`,
    publicAgreement: `$t(${SCOPE_LANDING}$AfterFooter$public_agreement)`,
    privacyPolicy: `$t(${SCOPE_LANDING}$AfterFooter$privacy_policy)`,
  },
  confirmations: {
    later: `
      Вам необходимо заполнить данные о компании для прохождения верификации на платформе Glendeal.
      Как только компания будет верифицирована, вы получите сообщение с подтверждением.
      <br />
      <br />
      После верификации компании, вы можете бесплатно делать ставки на покупку и продажу лотов и
      оформлять подписку для торговли лотами на платформе других участников рынка.
    `,
  },
  alert: {
    text1: 'Нам нужно 24 часа для верификации вашей компании. После этого, вы сможете делать ставки и торговать на платформе Glendeal.',
    text2: 'Но уже сейчас можно ознакомиться с возможностями агромаркетплейса и найти интересные предложения.',
  },
};

export const bg: typeof uk = {
  titles: {
    root: 'Регистрация',
    company: 'Информация за Компанията',
  },
  infos: {
    root: 'За създаване на заявки е необходимо да попълните контактните данни',
    company:
      'За да получавате само актуални оферти, регистрирайте вашата Компания и попълнете формуляра',
  },
  links: {
    later: 'Попълнете по-късно',
    howItWorks: 'Как работи',
    cookiePolicy: `$t(${SCOPE_LANDING}$AfterFooter$cookie_policy)`,
    publicAgreement: `$t(${SCOPE_LANDING}$AfterFooter$public_agreement)`,
    privacyPolicy: `$t(${SCOPE_LANDING}$AfterFooter$privacy_policy)`,
  },
  confirmations: {
    later: `
      Необходимо е да попълните данните за компанията за преминаване на верификация на платформата Glendeal.
      След като компанията бъде верифицирана, ще получите съобщение с потвърждение.
      <br />
      <br />
      След верификацията на компанията, можете безплатно да правите залози за покупка и продажба на лотове и
      да оформите абонамент за търговия с лотове на платформата на други участници на пазара.
    `,
  },
  alert: {
    text1:
      'Нужни са ни 24 часа за верификация на вашата компания. След това, ще можете да правите залози и да търгувате на платформата Glendeal.',
    text2:
      'Но вече сега можете да се запознаете с възможностите на агромаркетплейса и да намерите интересни оферти.',
  },
};
