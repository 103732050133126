import store from 'store/store';
import Nav from 'nav';


export abstract class ModelBase<T> {
  protected _store!: ReturnType<typeof store['getState']>;

  private static get store() {
    return store.getState();
  }

  constructor (
    public $: T,
  ) {}


  abstract get path(): string;

  goTo(e?: React.SyntheticEvent): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    Nav.go(this.path);
  }

  private get store() {
    return this._store ? this._store : this._store = store.getState();
  }
}
