export const SCOPE_PAGINAGION = 'Pagination';

export const uk = {
  show: 'Показати',
  counts: '{{firstCurrentNumber}}-{{lastCurrentNumber}} із {{count}}',
};

export const en: typeof uk = {
  show: 'Show',
  counts: '{{firstCurrentNumber}}-{{lastCurrentNumber}} of {{count}}',
};

export const ru: typeof uk = {
  show: 'Показать',
  counts: '{{firstCurrentNumber}}-{{lastCurrentNumber}} из {{count}}',
};

export const bg: typeof uk = {
  show: 'Покажи',
  counts: '{{firstCurrentNumber}}-{{lastCurrentNumber}} от {{count}}',
};
