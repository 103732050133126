import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgHelpIcon: React.FC<{ className?: string; }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="7" stroke="var(--gray800)" strokeWidth="2" />
        <path d="M9 8L9 11" stroke="var(--gray800)" strokeWidth="2" />
        <line x1="9" y1="12" x2="9" y2="14" stroke="var(--gray800)" strokeWidth="2" />
        <path
          d="M9 9C10.1046 9 11 8.10457 11 7C11 5.89543 10.1046 5 9 5C7.89543 5 7 5.89543 7 7"
          stroke="var(--gray800)"
          strokeWidth="2"
        />
      </svg>
    </SvgIconContainer>
  );
};
