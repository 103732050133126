import { T } from 'hooks';

export const SCOPE_MY_COMPANY_TABS = 'MyCompanyTabs';
export const ScopeMyCompanyTabs = (_t: T, tab: keyof typeof uk['tabs']): string => {
  return _t.scope(SCOPE_MY_COMPANY_TABS)(['tabs', tab]);
};

export const uk = {
  title: 'Моя компанія',
  tabs: {
    root: 'Інформація',
    contacts: 'Контакти',
    reviews: 'Відгуки',
    history: 'Історія',
  },
};

export const en: typeof uk = {
  title: 'My company',
  tabs: {
    root: 'Information',
    contacts: 'Contacts',
    reviews: 'Reviews',
    history: 'History',
  },
};

export const ru: typeof uk = {
  title: 'Моя компания',
  tabs: {
    root: 'Информация',
    contacts: 'Контакты',
    reviews: 'Отзывы',
    history: 'История',
  },
};

export const bg: typeof uk = {
  title: 'Моята компания',
  tabs: {
    root: 'Информация',
    contacts: 'Контакти',
    reviews: 'Рецензии',
    history: 'История',
  },
};
