import { SCOPE_REQUEST_LABEL } from './RequestLabel';

export const SCOPE_LOT = 'Lot';

export const uk = {
  title: 'Лот {{culture, capitalize}} №{{id}}',
  messages: {
    alerts: {
      archive: 'Заявка №{{id}} буде відправлена до Архіву і після цього не буде доступна до перегляду. Продовжити?',
    },
  },
  infoPopups: {
    archive: 'Успішно заархівовано',
  },
  zeroPrice: 'Договірна',
  labels: {
    overalls: '$t(lot, {"count": {{count}} }), об\'ємом {{volume}} т',
  },
  text: {
    overalls: `$t(${SCOPE_REQUEST_LABEL}\${{type}}) {{culture}}, $t(lot, {"count": {{count}} }), об\`ємом {{volume}} т`,
  },
  tooltips: {
    hasConfirmations: 'Заявка має тверду пропозицію у торгах',
  },
};

export const en: typeof uk = {
  title: 'Lot {{culture, capitalize}} №{{id}}',
  messages: {
    alerts: {
      archive: 'Lot №{{id}} will be sent to the Archive and will not be available for viewing after. Continue ?',
    },
  },
  infoPopups: {
    archive: 'Successfully archived',
  },
  zeroPrice: 'Negotiable',
  labels: {
    overalls: '$t(lot, {"count": {{count}} }), volume {{volume}} t',
  },
  text: {
    overalls: `$t(${SCOPE_REQUEST_LABEL}\${{type}}) {{culture}}, $t(lot, {"count": {{count}} }), volume {{volume}} t`,
  },
  tooltips: {
    hasConfirmations: 'The request has a hard offer in the trade',
  },
};

export const ru: typeof uk = {
  title: 'Лот {{culture, capitalize}} №{{id}}',
  messages: {
    alerts: {
      archive: 'Заявка №{{id}} будет отправлена в Архив и после не будет доступна к просмотру. Продолжить ?',
    },
  },
  infoPopups: {
    archive: 'Успешно заархивировано',
  },
  zeroPrice: 'Договорная',
  labels: {
    overalls: 'Подобные на рынке ($t(lot, {"count": {{count}} }), объёмом {{volume}} т.)',
  },
  text: {
    overalls: `$t(${SCOPE_REQUEST_LABEL}\${{type}}) {{culture}}, $t(lot, {"count": {{count}} }), объёмом {{volume}} т`,
  },
  tooltips: {
    hasConfirmations: 'Заявка имеет твердое предложение в торгах',
  },
};

export const bg: typeof uk = {
  title: 'Лот {{culture, capitalize}} №{{id}}',
  messages: {
    alerts: {
      archive: 'Заявка №{{id}} ще бъде архивирана и след това няма да бъде достъпна за преглед. Продължавате ли?',
    },
  },
  infoPopups: {
    archive: 'Успешно архивирано',
  },
  zeroPrice: 'Договаряне',
  labels: {
    overalls:
      'Подобни на пазара ($t(lot, {"count": {{count}} }), обем {{volume}} т.)',
  },
  text: {
    overalls: `$t(${SCOPE_REQUEST_LABEL}\${{type}}) {{culture}}, $t(lot, {"count": {{count}} }), обем {{volume}} т`,
  },
  tooltips: {
    hasConfirmations: 'Приложението има твърда оферта в търга',
  },
};
