import { TYPES } from 'actions/dashboardActions';

const initialOne = {
  basis: [],
  categories: [],
  companies: null,
  countries: [],
  cultures: [],
  port_options: [],
  packaging_options: [],
  grouping: '',
  overall: {},
  loading: true,
  loaded: false,
  error: null,
};

const initialState = {
  ...initialOne,
  baseKey: null,
  base: { ...initialOne },
};

export default function culturesReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.LOADING: return updateState(state, action.isBase, {
      loading: action.payload,
      error: null,
    });
    case TYPES.GET: return updateState(state, action.isBase, {
      loaded: true,
      loading: false,
      error: null,
      ...action.payload,
    });
    case TYPES.FAILED: return updateState(state, action.isBase, {
      loading: false,
      error: action.payload,
    });
    case TYPES.BASE_KEY: return {
      ...state,
      baseKey: action.payload,
    };
    default: return state;
  }
}

function updateState(state, isBase, payload) {
  return {
    ...state,
    ...(isBase ?
      {
        base: {
          ...state.base,
          ...payload,
        },
      }
      :
      payload
    ),
  };
}
