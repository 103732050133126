import { T } from 'hooks';

export const SCOPE_MY_COMPANY_CONTACTS = 'MyCompanyContacts';
const showContactValues = 'showContactValues';


export class ScopeMyCompanyContacts {
  public t: T;
  static scope(useT: (scope?: string | string[]) => T, scope?: keyof typeof uk): ScopeMyCompanyContacts {
    return new this(useT, scope);
  }
  constructor(private useT: (scope?: string | string[]) => T, scope?: keyof typeof uk) {
    this.t = this.useT([SCOPE_MY_COMPANY_CONTACTS, scope || '']);
  }

  showCompanyContacts(key: keyof typeof uk[typeof showContactValues]): string {
    return this.t.scope([SCOPE_MY_COMPANY_CONTACTS, showContactValues])(key);
  }
}


export const uk = {
  [showContactValues]: {
    label: 'Дані контакти відображатимуться:',
    for_all: 'кожній верифікованій компанії',
    for_my_hands: 'тільки компаніям, яким ви надіслали запит на взаємний зв\'язок',
    for_two_hands: 'тільки компаніям з якими встановлено взаємний зв\'язок',
    never: 'нікому',
  },
  MyCompanyContactsList: {
    title: 'Контактні дані',
    deleteConfirm: 'Ви впевнені, що хочете видалити {{name}} Контакт?',
    deleteSuccess: 'Контакт {{name}} видалено',
  },
  MyCompanyContactEdit: {
    title: 'Редагування Контакту',
    saveSuccess: 'Контакт успішно оновлено',
  },
  MyCompanyContactCreate: {
    title: 'Створення Контакту',
    createSuccess: 'Контакт успішно створено',
  },
  Fields: {
    company_position_id: {
      label: 'Посада',
      placeholder: 'Посада',
    },
  },
};

export const en: typeof uk = {
  [showContactValues]: {
    label: 'These contacts will be displayed:',
    for_all: 'to every verified company',
    for_my_hands: 'only companies that you have sent a mutual communication request',
    for_two_hands: 'only companies with which a mutual connection has been established',
    never: 'to anyone',
  },
  MyCompanyContactsList: {
    title: 'Contact details',
    deleteConfirm: 'Are you sure you want to delete {{name}} Contact?',
    deleteSuccess: 'Contact {{name}} has been deleted',
  },
  MyCompanyContactEdit: {
    title: 'Edit Contact',
    saveSuccess: 'Contact saved successfully',
  },
  MyCompanyContactCreate: {
    title: 'Creating a Contact',
    createSuccess: 'Contact created successfully',
  },
  Fields: {
    company_position_id: {
      label: 'Job title',
      placeholder: 'Job title',
    },
  },
};

export const ru: typeof uk = {
  [showContactValues]: {
    label: 'Данные контакты будут отображаться:',
    for_all: 'каждой верифицированной компании',
    for_my_hands: 'только компаниям, которым вы послали запрос на взаимную связь',
    for_two_hands: 'только компаниям с которыми установлена ​​взаимная связь',
    never: 'никому',
  },
  MyCompanyContactsList: {
    title: 'Контактные данные',
    deleteConfirm: 'Вы уверены что хотите удалить {{name}} Контакт?',
    deleteSuccess: 'Контакт {{name}} удалён',
  },
  MyCompanyContactEdit: {
    title: 'Редактирование Контакта',
    saveSuccess: 'Контакт успешно обновлён',
  },
  MyCompanyContactCreate: {
    title: 'Создание Контакта',
    createSuccess: 'Контакт успешно создан',
  },
  Fields: {
    company_position_id: {
      label: 'Должность',
      placeholder: 'Должность',
    },
  },
};

export const bg: typeof uk = {
  [showContactValues]: {
    label: 'Тези контакти ще бъдат показани:',
    for_all: 'до всяка проверена компания',
    for_my_hands: 'само компании, на които сте изпратили заявка за взаимна комуникация',
    for_two_hands: 'само компании, с които е установена взаимна връзка',
    never: 'на никого',
  },
  MyCompanyContactsList: {
    title: 'Данни за контакт',
    deleteConfirm: 'Сигурни ли сте, че искате да изтриете {{name}} контакт?',
    deleteSuccess: 'Контактът {{name}} е изтрит',
  },
  MyCompanyContactEdit: {
    title: 'Редактиране на контакт',
    saveSuccess: 'Контактът е актуализиран успешно',
  },
  MyCompanyContactCreate: {
    title: 'Създаване на контакт',
    createSuccess: 'Контактът е създаден успешно',
  },
  Fields: {
    company_position_id: {
      label: 'Длъжност',
      placeholder: 'Длъжност',
    },
  },
};
