import { toUrlQuery } from 'api/utils';
import { LotType } from 'constants/general';
import { BuilderLinkTo, ROUTES } from 'constants/routes';
import { createBrowserHistory } from 'history';
import { Language } from 'i18n';
import Logger from 'modules/Logger';
import { generatePath } from 'react-router';
import { IOffer } from 'reducers/oneOfferReducer';
import { IRequest } from 'reducers/oneRequestReducer';
import Notificate, { NotificateType } from 'services/notificate.service';

export let history: ReturnType<typeof createBrowserHistory>;
export const initHistory = (language: Language) => {
  history = history
    ? history
    : createBrowserHistory({ basename: language.path });
  return history;
};
export { ROUTES } from 'constants/routes';

export interface Options {
  state?: unknown;
  replace?: boolean;
  vibrate?: NotificateType;
}

let _proxyReplace = false;

export default class Nav {
  private static readonly proxy = buildProxy();

  static go(
    to: Parameters<typeof history.replace>[0],
    { state, replace = false, vibrate }: Options = {},
  ): void {
    replace ? history.replace(to, state) : history.push(to, state);
    if (vibrate) {
      Notificate.run(vibrate);
    }
  }
  static replace(location: Parameters<typeof history.replace>[0]): void {
    history.replace(location);
  }
  static changeLanguage(language?: Language) {
    if (!language) {
      return;
    }
    const breadsOfPath = window.location.pathname.split('/');
    const search = window.location.search;
    const currentLanguagePath = breadsOfPath[1];
    if (language.path === currentLanguagePath) {
      return;
    }
    // i18n.changeLanguage(language.key);
    if (Language.findByPath(currentLanguagePath)) {
      breadsOfPath[1] = language.path;
      window.history.pushState(null, 'hi', `/${breadsOfPath.slice(1, 999).join('/')}${search}`);
      window.location.reload();
    } else {
      window.history.replaceState(null, 'hi2', `/${language.path}${window.location.pathname}${search}`);
      window.location.reload();
    }
    return this;
  }
  static routes(replace = false): Readonly<typeof ROUTES> {
    _proxyReplace = replace;
    return this.proxy;
  }
  static search(params: Parameters<typeof toUrlQuery>[0], { replace = true }: { replace?: boolean } = {}): void {
    const search = toUrlQuery(params);
    replace ? history.replace({ search }) : history.push({ search });
  }
  static lot({ type, id }: { type: LotType, id: number }): void {
    const to: string = generatePath(ROUTES.lot, { type, id });
    this.go(to);
  }
  static goToTrade(
    instance: IOffer | IRequest,
    { replace = false }: { replace?: boolean } = {},
  ): void {
    const to: string = BuilderLinkTo.trade(instance);
    if (to) this.go(to, { replace });
  }
  static createLot({ type, replace }: { type: LotType, replace?: boolean } = { type: LotType.offer}): void {
    const to: string = generatePath(ROUTES.createLot, { type });
    this[replace ? 'replace' : 'go'](to);
  }
  static editLot({ type, id }: { type: LotType, id: number }): void {
    const to: string = generatePath(ROUTES.editLot, { type, id });
    this.go(to);
  }
  static tradeLot({ type, id }: { type: LotType, id: number }): void {
    const to: string = generatePath(ROUTES.tradeLot, { type, id });
    this.go(to);
  }
  static cloneLot({ type, id }: { type: LotType, id: number }): void {
    const to: string = generatePath(ROUTES.cloneLot, { type, id });
    this.go(to);
  }
  static editAddress({ id }: { id?: number }, options?: Options): void {
    if (!id) {
      Logger.debug('editAddress', 'wrongId');
      return;
    }
    const to: string = generatePath(ROUTES.settings.addresses.edit, { id });
    this.go(to, options);
  }
  static createAddress(options?: Options): void {
    const to: string = generatePath(ROUTES.settings.addresses.create);
    this.go(to, options);
  }
  static showAddress({ id }: { id?: number }, options?: Options): void {
    if (!id) {
      Logger.debug('showAddress', 'wrongId');
      return;
    }
    const to: string = generatePath(ROUTES.settings.addresses.show, { id });
    this.go(to, options);
  }
}

interface IRoutes {
  [key: string]: string | IRoutes;
}

function buildProxy(): Readonly<typeof ROUTES> {
  const handler = {
    get(target: IRoutes, p: string): IRoutes | void {
      if (p in target) {
        const value = target[p];
        if (typeof value === 'object') return new Proxy(value, handler);
        Nav.go(value, { replace: _proxyReplace });
      }
    },
  };

  return new Proxy(ROUTES, handler) as Readonly<typeof ROUTES>;
}
