import { SET_ACTIVE_PAGE_DATA } from '../actions/activePageActions';

const initialState = {
  title: '',
  backButton: false,
  type: null,
  numberRequest: '',
  date: '',
  hideSideBar: false,
  typeOveralls: null,
};

export default function activePageDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_PAGE_DATA:
      if (action.payload?.title) {
        const id = action.payload?.numberRequest;
        document.title = id ? `${action.payload.title} #${id}` : action.payload.title;
      }
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
