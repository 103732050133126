import { AnyTODO } from 'core/interfaces';
import { useState, useEffect } from 'react';

export const useDebounce = (value: AnyTODO, delay?: number) => {
  const [debouncedValue, setDebouncedValue] = useState<AnyTODO>(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

export const useFnDebounce = <T extends (...args: AnyTODO[]) => void>(fn: T, delay?: number) => {
  const [handler, setHandler] = useState<NodeJS.Timeout>();

  const debouncedFn = (...arg: AnyTODO[]) => {
    setHandler(setTimeout(() => fn(...arg), delay)); // CHECK working setTimeout parameters
  };
  useEffect(() =>
    () => clearTimeout(handler)
  , [handler],
  );

  return [debouncedFn, fn];
};
