import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgPersonIcon: React.FC<{ className?: string; }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="5" r="3" stroke="var(--gray800)" strokeWidth="2"/>
        <path d="M15 17C15 14.2386 12.3137 12 9 12C5.68629 12 3 14.2386 3 17" stroke="var(--gray800)" strokeWidth="2"/>
      </svg>
    </SvgIconContainer>
  );
};
