import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnyTODO } from 'core/interfaces';
import { LocalStorageHelper } from 'utils/LocalStorage.helper';

const VERSION = 1;
export const LOCALSTORAGE_KEY = 'settings';

export interface ISystemSettings {
  v: number;
  refferalData?: {
    referral_url: string;
    url: string;
    registered_at: string;
  },
  compactMode?: boolean;
}

type ActionKeyValue = {
  key: keyof ISystemSettings,
  value: ISystemSettings[keyof ISystemSettings],
}


export const NAME = 'systemSettins';
// const scope = `${NAME}`;
const defaultValue: ISystemSettings = {
  v: VERSION,
};

// Отримайте початковий стан з localStorage, якщо він існує
const initialState: ISystemSettings = new LocalStorageHelper<ISystemSettings>(LOCALSTORAGE_KEY).getValue(defaultValue);

export const {
  reducer,
  actions: {
    setSystemSettingsState,
    setSystemSettingsByKey,
    setReferralData,
  },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setSystemSettingsState: (_, action: PayloadAction<ISystemSettings>) => action.payload,
    setSystemSettingsByKey: (state, action: PayloadAction<ActionKeyValue>) => { state[action.payload.key] = action.payload.value as AnyTODO; },
    setReferralData: (state) => {
      if (state.refferalData) {
        return;
      }
      state.refferalData = {
        referral_url: window.document.referrer,
        url: window.location.pathname,
        registered_at: (new Date()).toJSON(),
      };
    },
  },
});

export default reducer;
