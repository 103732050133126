import { useEffect, useState } from 'react';

export interface IImage {
  id: number;
  image: string;
}

function preloadImage(src: string): Promise<unknown> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = img.onabort = () => reject(src);
    img.src = src;
  });
}

export const useImagePreloader = (images: IImage[], firstImages = -1): boolean => {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;

    const imagesPromiseList: Promise<unknown>[] = images.slice(0, firstImages).map(image => preloadImage(image.image));
    Promise.all(imagesPromiseList).then(() => {
      if (!isCancelled) {
        setImagesPreloaded(true);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, [images, firstImages]);

  return imagesPreloaded;
};
