import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgDeleteIcon: React.FC<{ className?: string; }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="4" x2="17" y2="4" stroke="#757575" strokeWidth="2"/>
        <line x1="7" y1="7" x2="7" y2="13" stroke="#757575" strokeWidth="2"/>
        <line x1="11" y1="7" x2="11" y2="13" stroke="#757575" strokeWidth="2"/>
        <path d="M6.83333 5L6.83333 3C6.83333 1.89543 7.72876 1 8.83333 1L9.5 1C10.6046 1 11.5 1.89543 11.5 3L11.5 5" stroke="#757575" strokeWidth="2"/>
        <path d="M3 4L4.59163 15.1414C4.66201 15.6341 5.08393 16 5.58158 16H12.4184C12.9161 16 13.338 15.6341 13.4084 15.1414L15 4" stroke="#757575" strokeWidth="2"/>
      </svg>
    </SvgIconContainer>
  );
};
