import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toApiQuery } from 'api/utils';
import { AnyTODO } from 'core/interfaces';
import { API, API_ROUTES } from 'modules/Api';
import { ILotCultureParameter } from 'reducers/allReducer';
import { factoryInitListState, FactoryListBuilder } from './helpers';
import { IApiListResponse, IInitListState } from './interfaces';

export { useTsSelector, useTsDispatch } from 'store/store.types';

export interface IGood {
  id: number;
  company_id: number;
  owner_id: number;
  culture_id: number;
  image: string | null;
  parameters: ILotCultureParameter[];
  annual_volume_tons_from?: number | null;
  annual_volume_tons_to?: number | null;
  origin_id: number;
  packagings: number[];
  shippings: number[];
  seasons: string;
  is_organic: boolean;
  // sort: number;
}

const initialState = factoryInitListState<IGood>();

export const NAME = 'goods';
const parent = 'global';
const scope = `${parent}/${NAME}`;
const apiUrlBase: string = API_ROUTES.goods;

export const getGoods = createAsyncThunk<IApiListResponse<IGood> | null, { params: Record<string, AnyTODO>, append?: boolean }>(
  `${scope}/getList`,
  (payload) => API.get<IApiListResponse<IGood>>(`${apiUrlBase}?${toApiQuery(payload.params)}`).then(res => res.data),
);


export const createGood = createAsyncThunk<IGood, Partial<IGood>>(
  `${scope}/create`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await API.post<IGood>(apiUrlBase, payload);
      return data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

export const updateGood = createAsyncThunk<IGood, Partial<IGood>>(
  `${scope}/update`,
  async (payload, { rejectWithValue }) => {
    try {
      if (!payload.id) {
        throw Error('id not defined');
      }
      const { data } = await API.patch<IGood>(`${apiUrlBase}${payload.id}/`, payload);
      return data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);


export const deleteGood = createAsyncThunk<void, number>(
  `${scope}/delete`,
  async (id, { rejectWithValue }) => {
    try {
      await API.delete<void>(`${apiUrlBase}${id}/`);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);


export const {
  reducer,
  // actions: {
  // },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
  },
  extraReducers: builder => new FactoryListBuilder<IGood, IInitListState<IGood>>(builder)
    .buildListAction(getGoods)
    .buildListSetAction(createGood)
    .buildListSetAction(updateGood)
    .buildListDeleteAction(deleteGood)
  ,
});

export default reducer;
