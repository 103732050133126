import React from 'react';
import { Button, Dialog, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { HOTKEYS, useHotkeys, useT } from 'hooks';
import { hideGlobalAlert, useTsDispatch, useTsSelector } from 'reducers/slices/globalAlert.slice';
import './style.sass';

export default function GlobalAlert() {
  const _t = useT();
  const dispatch = useTsDispatch();
  const globalAlert = useTsSelector(({ globalAlert }) => globalAlert);

  const hClose = () => {
    dispatch(hideGlobalAlert());
  };

  useHotkeys([{ call: hClose, keyCode: HOTKEYS.O }]);

  return (
    <Dialog
      className="GlobalAlert"
      onClose={hClose}
      open={globalAlert.show}
      transitionDuration={{ enter: 200, exit: 0 }}
    >
      <div className="GlobalAlert__body">
        <IconButton className="close" component="span" size="small" onClick={hClose}>
          <Close/>
        </IconButton>
        <div className="GlobalAlert__text">
          {globalAlert.text}
        </div>
      </div>
      <div className="GlobalAlert__buttons">
        <Button className="GlobalAlert__button" onClick={hClose}>
          {_t.old('ОК')}
        </Button>
      </div>
    </Dialog>
  );
}
