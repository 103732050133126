export const MAX_UPLOADED_FILES = 8;
export const MAX_CHAT_MESSAGE_LENGTH = 300;
export const FILTER_FIELDS = [
  'basis',
  'categories',
  'companies',
  'countries',
  'cultures',
  'date_range',
  'limit',
  'port_options',
  'offset',
  'origins',
  'packaging_options',
  'search',
  'trade_status',
  'company_trades',
  'quick_date',
  'public',
  'status',
];

export default {
  MAX_UPLOADED_FILES,
  MAX_CHAT_MESSAGE_LENGTH,
  FILTER_FIELDS,
};
