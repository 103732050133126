import React, { FC, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Redirects } from 'components/Links/Redirects';
import Preloader from 'components/_router/Preloader';
import { useTsSelector, profileUserSelector } from 'reducers/profile/user.slice';


export const NotAuthRoute: React.FC<{ component: FC<RouteComponentProps> } & Route['props']> = ({ component: Component, ...rest }) => {

  const user = useTsSelector(profileUserSelector);

  if (user.id) {
    return (user.contact_name) ? <Redirects.market /> : <Redirects.registration.root />;
  }

  return (
    <Route {...rest}
      render={props =>(
        <Suspense fallback={<Preloader />}>
          <Component {...props} />
        </Suspense>
      )}
    />
  );
};
