import { CompanyHandshakeSubStatus } from 'models/CompanyHandshake';
import { HandshakeFilterEnum } from 'modules/Cabinet/HandshakedCompanies/HandshakeFilter';

export const SCOPE_COMPANIES = 'Companies';
export const SCOPE_COMPANY_HANDSHAKES = 'CompanyHandshake';

export const uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    alerts: {
      [CompanyHandshakeSubStatus.none]: 'Ви зможете надіслати запит через {{nextAction}}.',
      [CompanyHandshakeSubStatus.my]: 'Час очікування підтвердження іншої сторони закінчиться через {{nextAction}}.',
      [CompanyHandshakeSubStatus.full]: 'Ви зможете відкликати запит через {{nextAction}}.',
    },
    confirmations: {
      [CompanyHandshakeSubStatus.none]: 'Запит буде дійсний протягом {{period}} годин для підтвердження іншою стороною.',
      [CompanyHandshakeSubStatus.my]: 'my',
      [CompanyHandshakeSubStatus.opposite]: 'Підтвердьте запит від іншої сторони. Скасувати можна буде лише через {{period}} годин.',
      [CompanyHandshakeSubStatus.full]: 'Відозвати зв\'язок.  Після цього ви зможете надіслати новий запит через {{period}} годин.',
    },
    tooltips: {
      waiting: {
        [CompanyHandshakeSubStatus.none]: 'Надіслати запит через {{nextAction}}',
        [CompanyHandshakeSubStatus.my]: 'Запит очікує підтвердження від іншої сторони. Він буде відкликаний через {{nextAction}}.',
        // [CompanyHandshakeSubStatus.opposite]: 'Can approve in {{nextAction}}',
        [CompanyHandshakeSubStatus.full]: 'Відозвати зв\'язок через {{nextAction}}.',
      },
      active: {
        [CompanyHandshakeSubStatus.none]: 'Надіслати запит',
        // [CompanyHandshakeSubStatus.my]: 'Waiting apporve, {{nextAction}}',
        [CompanyHandshakeSubStatus.opposite]: 'Підтвердити запит',
        [CompanyHandshakeSubStatus.full]: 'Розірвати зв\'язок',
      },
    },
    filter: {
      names: {
        [HandshakeFilterEnum.any]: 'Всі',
        [HandshakeFilterEnum.full]: 'Прийняті',
        [HandshakeFilterEnum.my]: 'Відправлені',
        [HandshakeFilterEnum.target]: 'Отримані',
      },
    },
    messages: {
      error: 'Рукостискання відкликане',
      success: 'Рукостискання встановлено',
      info: 'Рукостискання надіслано',
    },
  },
  CompanyReviewsCreate: {
    title: 'Відгук о {{name}}',
  },
  fields: {
    name: {
      title: 'Компанія',
    },
    description: {
      title: 'Опис',
    },
    country: {
      title: 'Країна',
    },
    rating: {
      title: 'Рейтинг',
    },
    handshake: {
      title: 'Зв\'язок',
    },
  },
  show: {
    info: {
      created_date: 'на сервісі {{date}}',
      amount_of_workers: '{{amount}} осіб',
      annual_turnover: '{{turnover}} млн/дол',
      is_organic: 'органіка',
    },
    about_us: 'Про нас',
    specialization: 'Спеціалізація',
    images: 'Фото',
    files: 'Документи',
    tabs: {
      goods: 'Товари',
      contacts: 'Контакти',
      reviews: 'Відгуки',
      history: 'Історія',
    },
  },
};

export const en: typeof uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    alerts: {
      [CompanyHandshakeSubStatus.none]: 'You can send the request in {{nextAction}}.',
      [CompanyHandshakeSubStatus.my]: 'The confirmation waiting time will expire in {{nextAction}}.',
      [CompanyHandshakeSubStatus.full]: 'You can cancel the request in {{nextAction}}.',
    },
    confirmations: {
      [CompanyHandshakeSubStatus.none]: 'The request will be valid for {{period}} hours for the other party to confirm.',
      [CompanyHandshakeSubStatus.my]: 'my',
      [CompanyHandshakeSubStatus.opposite]: 'Confirm the request from the other party. You can cancel it only after {{period}} hours.',
      [CompanyHandshakeSubStatus.full]: 'Revoke the connection. You will be able to send a new request in {{period}} hours.',
    },
    tooltips: {
      waiting: {
        [CompanyHandshakeSubStatus.none]: 'Send request in {{nextAction}}.',
        [CompanyHandshakeSubStatus.my]: 'Waiting for confirmation from the other party. It will be canceled in {{nextAction}}.',
        // [CompanyHandshakeSubStatus.opposite]: 'Can approve in {{nextAction}}',
        [CompanyHandshakeSubStatus.full]: 'Revoke the connection in {{nextAction}}.',
      },
      active: {
        [CompanyHandshakeSubStatus.none]: 'Send request',
        // [CompanyHandshakeSubStatus.my]: 'Waiting approve, {{nextAction}}',
        [CompanyHandshakeSubStatus.opposite]: 'Confirm request',
        [CompanyHandshakeSubStatus.full]: 'Break connection',
      },
    },
    filter: {
      names: {
        [HandshakeFilterEnum.any]: 'All',
        [HandshakeFilterEnum.full]: 'Accepted',
        [HandshakeFilterEnum.my]: 'Sent',
        [HandshakeFilterEnum.target]: 'Received',
      },
    },
    messages: {
      error: 'Handshake revoked',
      success: 'Handshake established',
      info: 'Handshake sent',
    },
  },
  CompanyReviewsCreate: {
    title: 'Review of {{name}}',
  },
  fields: {
    name: {
      title: 'Company',
    },
    description: {
      title: 'Description',
    },
    country: {
      title: 'Country',
    },
    rating: {
      title: 'Rating',
    },
    handshake: {
      title: 'Contact',
    },
  },
  show: {
    info: {
      created_date: 'on service {{date}}',
      amount_of_workers: '{{amount}} people',
      annual_turnover: '{{turnover}} mln/usd',
      is_organic: 'organic',
    },
    about_us: 'About us',
    specialization: 'Specialization',
    images: 'Photos',
    files: 'Documents',
    tabs: {
      goods: 'Goods',
      contacts: 'Contacts',
      reviews: 'Reviews',
      history: 'History',
    },
  },
};

export const ru: typeof uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    alerts: {
      [CompanyHandshakeSubStatus.none]: 'Вы сможете отправить запрос через {{nextAction}}.',
      [CompanyHandshakeSubStatus.my]: 'Время ожидания подтверждения другой стороны закончится через {{nextAction}}.',
      [CompanyHandshakeSubStatus.full]: 'Вы сможете отозвать запрос через {{nextAction}}',
    },
    confirmations: {
      [CompanyHandshakeSubStatus.none]: 'Запрос будет действителен в течение {{period}} часов для подтверждения другой стороной.',
      [CompanyHandshakeSubStatus.my]: 'my',
      [CompanyHandshakeSubStatus.opposite]: 'Подтвердите запрос от другой стороны. Вы сможете отменить его только через {{period}} часов.',
      [CompanyHandshakeSubStatus.full]: 'Отозвать связь. После этого вы сможете отправить новый запрос через {{period}} часов.',
    },
    tooltips: {
      waiting: {
        [CompanyHandshakeSubStatus.none]: 'Отправить запрос через {{nextAction}}.',
        [CompanyHandshakeSubStatus.my]: 'Запрос ожидает подтверждения другой стороной. Он будет отозван через {{nextAction}}.',
        // [CompanyHandshakeSubStatus.opposite]: 'Can approve in {{nextAction}}',
        [CompanyHandshakeSubStatus.full]: 'тозвать связь через {{nextAction}}.',
      },
      active: {
        [CompanyHandshakeSubStatus.none]: 'Отослать запрос',
        // [CompanyHandshakeSubStatus.my]: 'Waiting apporve, {{nextAction}}',
        [CompanyHandshakeSubStatus.opposite]: 'Подтвердить запрос',
        [CompanyHandshakeSubStatus.full]: 'Разорвать связь',
      },
    },
    filter: {
      names: {
        [HandshakeFilterEnum.any]: 'Все',
        [HandshakeFilterEnum.full]: 'Принятые',
        [HandshakeFilterEnum.my]: 'Отправленые',
        [HandshakeFilterEnum.target]: 'Полученые',
      },
    },
    messages: {
      error: 'Рукопожатие отозвано',
      success: 'Рукопожатие установлено',
      info: 'Рукопожатие отослано',
    },
  },
  CompanyReviewsCreate: {
    title: 'Review {{name}}',
  },
  fields: {
    name: {
      title: 'Компания',
    },
    description: {
      title: 'Описание',
    },
    country: {
      title: 'Страна',
    },
    rating: {
      title: 'Рейтинг',
    },
    handshake: {
      title: 'Связь',
    },
  },
  show: {
    info: {
      created_date: 'на сервисе {{date}}',
      amount_of_workers: '{{amount}} человек',
      annual_turnover: '{{turnover}} млн/дол',
      is_organic: 'органика',
    },
    about_us: 'О нас',
    specialization: 'Специализация',
    images: 'Фото',
    files: 'Документы',
    tabs: {
      goods: 'Товары',
      contacts: 'Контакты',
      reviews: 'Отзывы',
      history: 'История',
    },
  },
};

export const bg: typeof uk = {
  [SCOPE_COMPANY_HANDSHAKES]: {
    alerts: {
      [CompanyHandshakeSubStatus.none]: 'Можете да изпратите заявка след {{nextAction}}.',
      [CompanyHandshakeSubStatus.my]: 'Времето за потвърждение ще изтече след {{nextAction}}.',
      [CompanyHandshakeSubStatus.full]: 'Можете да отмените заявката след {{nextAction}}.',
    },
    confirmations: {
      [CompanyHandshakeSubStatus.none]: 'Заявката ще бъде валидна за {{period}} часа за потвърждение от другата страна.',
      [CompanyHandshakeSubStatus.my]: 'моят',
      [CompanyHandshakeSubStatus.opposite]: 'Потвърдете заявката от другата страна. Ще можете да я анулирате само след {{period}} часа.',
      [CompanyHandshakeSubStatus.full]: 'Отзовете връзката. След това ще можете да изпратите нова заявка след {{period}} часа.',
    },
    tooltips: {
      waiting: {
        [CompanyHandshakeSubStatus.none]: 'Изпратете заявка след {{nextAction}}.',
        [CompanyHandshakeSubStatus.my]: 'Заявката очаква потвърждение от другата страна. Тя ще бъде анулирана след {{nextAction}}.',
        // противоположно: 'Може да одобри в {{nextAction}}',
        [CompanyHandshakeSubStatus.full]: 'Отзовете връзката след {{nextAction}}.',
      },
      active: {
        [CompanyHandshakeSubStatus.none]: 'Изпращане на заявка',
        // [CompanyHandshakeSubStatus.my]: 'Изчакване на одобрение, {{nextAction}}',
        [CompanyHandshakeSubStatus.opposite]: 'Потвърдете заявката',
        [CompanyHandshakeSubStatus.full]: 'Прекъсване на връзката',
      },
    },
    filter: {
      names: {
        [HandshakeFilterEnum.any]: 'Всички',
        [HandshakeFilterEnum.full]: 'Прието',
        [HandshakeFilterEnum.my]: 'Изпратено',
        [HandshakeFilterEnum.target]: 'Получено',
      },
    },
    messages: {
      error: 'Ръкостискането е отменено',
      success: 'Ръкостискането е установено',
      info: 'Ръкостискането е изпратено',
    },
  },
  CompanyReviewsCreate: {
    title: 'Преглед на {{name}}',
  },
  fields: {
    name: {
      title: 'Компания',
    },
    description: {
      title: 'Описание',
    },
    country: {
      title: 'Държава',
    },
    rating: {
      title: 'Рейтинг',
    },
    handshake: {
      title: 'Връзка',
    },
  },
  show: {
    info: {
      created_date: 'в услугата {{date}}',
      amount_of_workers: '{{amount}} души',
      annual_turnover: '{{turnover}} млн/дол',
      is_organic: 'органика',
    },
    about_us: 'За нас',
    specialization: 'Специализация',
    images: 'Снимка',
    files: 'Документи',
    tabs: {
      goods: 'Стоки',
      contacts: 'Контакти',
      reviews: 'Рецензии',
      history: 'История',
    },
  },
};
