import React from 'react';
import SvgIconContainer from '../SvgIconContainer';

export const SvgColosIcon: React.FC<{ className?: string; }> = ({ className }) => {
  return (
    <SvgIconContainer className={className}>
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 17L15 14.6V9.8M9 17L3 14.6V9.8M9 17V12.2M15 9.8L9 12.2M15 9.8V5M15 5L9 7.4M15 5V2M3 5L9 7.4M3 5V9.8M3 5V2M3 9.8L9 12.2M9 12.2V7.4M9 7.4V0" stroke="#BABABA" strokeWidth="2"/>
      </svg>
    </SvgIconContainer>
  );
};
