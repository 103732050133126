import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API, API_ROUTES } from 'modules/Api';
import { RootState } from 'store/store.types';
import { factoryInitListState, FactoryListBuilder } from './helpers';
import { IApiListResponse, IInitListState } from './interfaces';

export { useTsSelector, useTsDispatch } from 'store/store.types';


export interface IReviewCategory {
  id: number;
  name: string;
}

const initialState = factoryInitListState<IReviewCategory>();

export const NAME = 'reviewCategories';
const parent = 'global';
const scope = `${parent}/${NAME}`;
const apiUrlBase: string = API_ROUTES.review_categories;

export const getReviewCategories = createAsyncThunk<IApiListResponse<IReviewCategory> | null, { append?: boolean, force: boolean }>(
  `${scope}/getList`,
  ({ force }, thunkApi) => {
    const { loaded } = (thunkApi.getState() as RootState).reviewCategories;
    if (!force && loaded) {
      return null;
    }
    return API.get<IApiListResponse<IReviewCategory>>(apiUrlBase).then(res => res.data);
  },
);


export const {
  reducer,
  // actions: {
  // },
} = createSlice({
  name: NAME,
  initialState,
  reducers: {
  },
  extraReducers: builder => new FactoryListBuilder<IReviewCategory, IInitListState<IReviewCategory>>(builder)
    .buildListAction(getReviewCategories)
  ,
});

export default reducer;
